import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import MultiSelect from 'common/components/Select/MultiSelect';

const ChangeGroupsForm = ({ groups, value, onSubmit, error, isSubmitting, button }) => {
  return (
    <Form onSubmit={onSubmit} errors={error} className="change-groups-form">
      <h4 className="mb-15">Manage groups</h4>
      <div className="change-groups-form-control">
        <p className="change-groups-form-label">Groups</p>
        <MultiSelect name="groups" options={groups} value={value} />
      </div>
      <div className="change-groups-form-submit">
        <p className="change-groups-form-info">Select or remove groups and click Set.</p>
        <Button {...button} type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </Form>
  );
};

ChangeGroupsForm.propTypes = {
  groups: PropTypes.array,
  value: PropTypes.array,
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
  button: PropTypes.object,
};

export default ChangeGroupsForm;
