import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import Loader from 'common/components/Loader';

const TwoFactorCode = ({ secretKey, qrLink, error, isLoading }) => {
  if (isLoading) {
    return <Loader />;
  } else if (error) {
    return <p className="vrs-error">{error.error}</p>;
  }

  return (
    <div className="two-factor-code">
      <p>To finish setup, open your 2FA app and scan the QR code.</p>
      {qrLink && <QRCode value={qrLink} size={180} renderAs="svg" className="mt-15 mb-15" />}
      <p className="mb-15">
        {`If your app doesn't recognize the QR code, enter the following key manually:`} <span>{secretKey}</span>
      </p>
    </div>
  );
};

TwoFactorCode.propTypes = {
  secretKey: PropTypes.string,
  qrLink: PropTypes.string,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default TwoFactorCode;
