import { REQUEST, SUCCESS, FAILURE, CLEAR } from 'base/redux/consts';

const initialState = {
  data: null,
  error: null,
  isFetching: false,
};

/**
 * Method creates reducer for API request.
 * @param {string} type - The type of action.
 * @param {function} [reduceSuccess] - The callback function to customize state after success.
 */
export const apiReducer = (type, reduceSuccess) => {
  const makeTypes = (type) => [type + REQUEST, type + SUCCESS, type + FAILURE, type + CLEAR];
  const [REQUEST_TYPE, SUCCESS_TYPE, FAILURE_TYPE, CLEAR_TYPE] = makeTypes(type);

  return (state = initialState, action) => {
    switch (action.type) {
      case REQUEST_TYPE:
        return {
          ...state,
          isFetching: true,
        };
      case SUCCESS_TYPE: {
        let nextState = {
          ...state,
          error: initialState.error,
          isFetching: initialState.isFetching,
        };
        if (reduceSuccess) {
          return reduceSuccess(nextState, action);
        }
        return {
          ...nextState,
          data: action.data,
        };
      }
      case FAILURE_TYPE:
        return {
          ...state,
          error: action.error,
          isFetching: initialState.isFetching,
        };
      case CLEAR_TYPE:
        return initialState;
      default:
        return state;
    }
  };
};
