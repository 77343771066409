import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TrackerModalContainer from './TrackerModalContainer';
import ChatbotChannelsView from 'modules/trackers/views/ChatbotChannelsView';
import TrackerBleDevicesView from 'modules/trackers/views/TrackerBleDevicesView';
import TrackerDetailsView from 'modules/trackers/views/TrackerDetailsView';
import TrackerExportView from 'modules/trackers/views/TrackerExportView';
import TrackerFlightInfoView from 'modules/trackers/views/TrackerFlightInfoView';
import TrackerSettingsView from 'modules/trackers/views/TrackerSettingsView';
import TrackerConfigurationView from 'modules/trackers/views/TrackerConfigurationView';

import { appUrls } from 'urls';

const TrackerViewContainer = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path={appUrls.TRACKERS.EXPORT_LOG}
        render={(routeProps) => <TrackerModalContainer view={TrackerExportView} header="Export log" {...routeProps} />}
      />
      <Route
        exact={true}
        path={appUrls.TRACKERS.DETAILS}
        render={(routeProps) => <TrackerModalContainer view={TrackerDetailsView} header="Details" {...routeProps} />}
      />
      <Route
        exact={true}
        path={appUrls.TRACKERS.BLE_DEVICES}
        render={(routeProps) => (
          <TrackerModalContainer view={TrackerBleDevicesView} header="BLE devices" {...routeProps} />
        )}
      />
      <Route
        exact={true}
        path={appUrls.TRACKERS.CONFIGURATION}
        render={(routeProps) => (
          <TrackerModalContainer view={TrackerConfigurationView} header="Configuration" {...routeProps} />
        )}
      />
      <Route
        exact={true}
        path={appUrls.TRACKERS.SETTINGS}
        render={(routeProps) => <TrackerModalContainer view={TrackerSettingsView} header="Settings" {...routeProps} />}
      />
      <Route
        exact={true}
        path={appUrls.TRACKERS.FLIGHT}
        render={(routeProps) => <TrackerModalContainer view={TrackerFlightInfoView} header="Flights" {...routeProps} />}
      />
      <Route
        exact={true}
        path={appUrls.TRACKERS.CHANNELS}
        render={(routeProps) => <TrackerModalContainer view={ChatbotChannelsView} header="Channels" {...routeProps} />}
      />
    </Switch>
  );
};

export default TrackerViewContainer;
