import { all, take, select, put } from 'redux-saga/effects';
import { apiSaga } from 'base/redux/utils';

import { SET_SESSION } from 'modules/account/redux/actions';
import { managementActions } from 'modules/menu/redux/actions';

import { GROUPS_GET, commonActions } from './actions';

function* headerDataSaga() {
  while (true) {
    yield take(SET_SESSION);
    const { isAuthenticated, isAdmin } = yield select(({ account }) => ({
      isAuthenticated: account.session.isAuthenticated,
      isAdmin: account.currentUser.isAdmin,
    }));

    if (isAuthenticated && isAdmin) {
      yield put(commonActions.groups.get());
      yield put(managementActions.firmwares.get());
      yield put(managementActions.firmwares.getUsed()); // firmwares to display in filters
      yield put(managementActions.partners.get());
    }
  }
}

export function* commonSaga() {
  yield all([apiSaga(GROUPS_GET)(), headerDataSaga()]);
}
