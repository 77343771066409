import { put } from 'redux-saga/effects';

import { apiAction } from 'base/redux/utils';
import { resolveUrl } from 'common/utils';
import { apiUrls } from 'urls';
import { CLEAR } from 'base/redux/consts';

export const MENU_MODAL = 'MENU_MODAL';
export const MENU_PATH_SET = 'MENU_PATH_SET';
export const MENU_PATH_BACK = 'MENU_PATH_BACK';

export const PARTNERS_GET = 'PARTNERS_GET';
export const PARTNERS_CREATE = 'PARTNERS_CREATE';
export const PARTNERS_DELETE = 'PARTNERS_DELETE';

export const SETTINGS_GET = 'SETTINGS_GET';
export const SETTINGS_SAVE = 'SETTINGS_SAVE';

export const FIRMWARES_GET = 'FIRMWARES_GET';
export const FIRMWARES_USED = 'FIRMWARES_USED';
export const FIRMWARES_CREATE = 'FIRMWARES_CREATE';
export const FIRMWARES_DELETE = 'FIRMWARES_DELETE';

export const DEVICES_IMPORT = 'DEVICES_IMPORT';

export const PARTNER_CUSTOMERS_GET = 'PARTNER_CUSTOMERS_GET';
export const PARTNER_CUSTOMERS_CREATE = 'PARTNER_CUSTOMERS_CREATE';
export const PARTNER_CUSTOMERS_DELETE = 'PARTNER_CUSTOMERS_DELETE';

export const PARTNER_TCC_ADMINS_GET = 'PARTNER_TCC_ADMINS_GET';
export const PARTNER_TCC_ADMINS_CREATE = 'PARTNER_TCC_ADMINS_CREATE';
export const PARTNER_TCC_ADMINS_DELETE = 'PARTNER_TCC_ADMINS_DELETE';

export const PARTNER_BLE_FILTERS_GET = 'PARTNER_BLE_FILTERS_GET';
export const PARTNER_BLE_FILTERS_CREATE = 'PARTNER_BLE_FILTERS_CREATE';
export const PARTNER_BLE_FILTERS_DELETE = 'PARTNER_BLE_FILTERS_DELETE';
export const PARTNER_BLE_FILTERS_EDIT = 'PARTNER_BLE_FILTERS_EDIT';

export const PARTNER_BLE_SCRIPTS_CREATE = 'PARTNER_BLE_SCRIPTS_CREATE';
export const PARTNER_BLE_SCRIPTS_EDIT = 'PARTNER_BLE_SCRIPTS_EDIT';
export const PARTNER_BLE_SCRIPTS_DELETE = 'PARTNER_BLE_SCRIPTS_DELETE';

export const PARTNER_USERS_GET = 'PARTNER_USERS_GET';
export const PARTNER_USERS_CREATE = 'PARTNER_USERS_CREATE';
export const PARTNER_USERS_DELETE = 'PARTNER_USERS_DELETE';

export const PARTNER_SETTINGS_GET = 'PARTNER_SETTINGS_GET';
export const PARTNER_SETTINGS_SAVE = 'PARTNER_SETTINGS_SAVE';
export const PARTNER_SETTINGS_DELETE = 'PARTNER_SETTINGS_DELETE';
export const PARTNER_SETTINGS_DELETE_ALL = 'PARTNER_SETTINGS_DELETE_ALL';
export const PARTNER_SENSORS_SETTINGS_SAVE = 'PARTNER_SENSORS_SETTINGS_SAVE';

export const PARTNER_GROUPS_GET = 'PARTNER_GROUPS_GET';
export const PARTNER_GROUP_CREATE = 'PARTNER_GROUP_CREATE';
export const PARTNER_GROUP_EDIT = 'PARTNER_GROUP_EDIT';
export const PARTNER_GROUP_DELETE = 'PARTNER_GROUP_DELETE';
export const PARTNER_GROUP_DEVICES_GET = 'PARTNER_GROUP_DEVICES_GET';
export const PARTNER_GROUP_DEVICE_CREATE = 'PARTNER_GROUP_DEVICE_CREATE';
export const PARTNER_GROUP_DEVICE_DELETE = 'PARTNER_GROUP_DEVICE_DELETE';

export const PARTNER_KEYS_GET = 'PARTNER_KEYS_GET';
export const PARTNER_KEYS_CREATE = 'PARTNER_KEYS_CREATE';
export const PARTNER_KEYS_DELETE = 'PARTNER_KEYS_DELETE';

export const menuActions = {
  openMenu: () => ({
    type: MENU_MODAL,
    isOpen: true,
  }),
  closeMenu: () => ({
    type: MENU_MODAL,
    isOpen: false,
  }),
  setPath: (path) => ({
    type: MENU_PATH_SET,
    path,
  }),
  goBack: () => ({
    type: MENU_PATH_BACK,
  }),
};

export const managementActions = {
  partners: {
    get: (payload) => apiAction(PARTNERS_GET, 'GET', apiUrls.PARTNERS.GET, payload),
    create: (payload) => apiAction(PARTNERS_CREATE, 'POST', apiUrls.PARTNERS.CREATE, payload),
    delete: (params, query, companyId) =>
      apiAction(PARTNERS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNERS.DELETE, params, query), null, {
        data: { companyId },
      }),
    cleanCreated: () => ({ type: PARTNERS_CREATE + CLEAR }),
    cleanDeleted: () => ({ type: PARTNERS_DELETE + CLEAR }),
  },
  settings: {
    get: (model) => apiAction(SETTINGS_GET, 'GET', resolveUrl(apiUrls.SETTINGS.GET, { model })),
    save: (model, payload) => apiAction(SETTINGS_SAVE, 'PATCH', resolveUrl(apiUrls.SETTINGS.SAVE, { model }), payload),
    cleanGet: () => ({ type: SETTINGS_GET + CLEAR }),
    cleanSaved: () => ({ type: SETTINGS_SAVE + CLEAR }),
  },
  firmwares: {
    get: (query) => apiAction(FIRMWARES_GET, 'GET', resolveUrl(apiUrls.FIRMWARES.GET, null, query)),
    getUsed: () => apiAction(FIRMWARES_USED, 'GET', resolveUrl(apiUrls.FIRMWARES.GET, null, { used: true })),
    create: (formData, onSuccess) =>
      apiAction(FIRMWARES_CREATE, 'POST', apiUrls.FIRMWARES.CREATE, formData, {
        afterSagaSuccess: function* () {
          yield put(managementActions.firmwares.get());
          yield onSuccess?.();
        },
      }),
    delete: (params) =>
      apiAction(FIRMWARES_DELETE, 'DELETE', resolveUrl(apiUrls.FIRMWARES.DELETE, params), null, {
        afterSagaSuccess: function* () {
          yield put(managementActions.firmwares.get());
        },
      }),
  },
  devices: {
    import: (formData, onSuccess) =>
      apiAction(DEVICES_IMPORT, 'POST', apiUrls.DEVICES.IMPORT, formData, {
        afterSagaSuccess: function* () {
          yield onSuccess?.();
        },
      }),
  },
  partner: {
    customers: {
      get: (params) => apiAction(PARTNER_CUSTOMERS_GET, 'GET', resolveUrl(apiUrls.PARTNER.CUSTOMERS.GET, params)),
      create: (params, payload) =>
        apiAction(PARTNER_CUSTOMERS_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.CUSTOMERS.CREATE, params), payload, {
          params,
        }),
      delete: (params) =>
        apiAction(PARTNER_CUSTOMERS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.CUSTOMERS.DELETE, params), null, {
          params,
        }),
      cleanGet: () => ({ type: PARTNER_CUSTOMERS_GET + CLEAR }),
      cleanCreated: () => ({ type: PARTNER_CUSTOMERS_CREATE + CLEAR }),
      cleanDeleted: () => ({ type: PARTNER_CUSTOMERS_DELETE + CLEAR }),
    },
    tccAdmins: {
      get: (params) => apiAction(PARTNER_TCC_ADMINS_GET, 'GET', resolveUrl(apiUrls.PARTNER.TCC_ADMINS.GET, params)),
      create: (params, payload) =>
        apiAction(PARTNER_TCC_ADMINS_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.TCC_ADMINS.CREATE, params), payload, {
          params,
        }),
      delete: (params) =>
        apiAction(PARTNER_TCC_ADMINS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.TCC_ADMINS.DELETE, params), null, {
          params,
        }),
      cleanGet: () => ({ type: PARTNER_TCC_ADMINS_GET + CLEAR }),
      cleanCreated: () => ({ type: PARTNER_TCC_ADMINS_CREATE + CLEAR }),
      cleanDeleted: () => ({ type: PARTNER_TCC_ADMINS_DELETE + CLEAR }),
    },
    bleFilters: {
      get: (params, query) =>
        apiAction(PARTNER_BLE_FILTERS_GET, 'GET', resolveUrl(apiUrls.PARTNER.BLE_FILTERS.GET, params, query)),
      create: (params, payload, onSuccess) =>
        apiAction(PARTNER_BLE_FILTERS_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.BLE_FILTERS.CREATE, params), payload, {
          params,
          afterSagaSuccess: function () {
            onSuccess?.();
          },
        }),
      delete: (params, onSuccess) =>
        apiAction(PARTNER_BLE_FILTERS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.BLE_FILTERS.DELETE, params), null, {
          params,
          afterSagaSuccess: function () {
            onSuccess?.();
          },
        }),
      edit: (params, payload, onSuccess) =>
        apiAction(PARTNER_BLE_FILTERS_EDIT, 'PATCH', resolveUrl(apiUrls.PARTNER.BLE_FILTERS.EDIT, params), payload, {
          params,
          afterSagaSuccess: function* () {
            onSuccess?.();
            yield put(managementActions.partner.bleFilters.get(params, onSuccess));
          },
        }),
      cleanGet: () => ({ type: PARTNER_BLE_FILTERS_GET + CLEAR }),
      cleanCreated: () => ({ type: PARTNER_BLE_FILTERS_CREATE + CLEAR }),
      cleanDeleted: () => ({ type: PARTNER_BLE_FILTERS_DELETE + CLEAR }),
      cleanEdit: () => ({ type: PARTNER_BLE_FILTERS_EDIT + CLEAR }),
    },
    bleScripts: {
      create: (params, payload, onSuccess) =>
        apiAction(PARTNER_BLE_SCRIPTS_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.BLE_SCRIPTS.CREATE, params), payload, {
          params,
          afterSagaSuccess: function* () {
            onSuccess?.();
            yield put(managementActions.partner.bleFilters.get(params, onSuccess));
          },
        }),
      edit: (params, payload, onSuccess) =>
        apiAction(PARTNER_BLE_SCRIPTS_EDIT, 'PATCH', resolveUrl(apiUrls.PARTNER.BLE_SCRIPTS.EDIT, params), payload, {
          params,
          afterSagaSuccess: function* () {
            onSuccess?.();
            yield put(managementActions.partner.bleFilters.get(params, onSuccess));
          },
        }),
      delete: (params, onSuccess) =>
        apiAction(PARTNER_BLE_SCRIPTS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.BLE_SCRIPTS.DELETE, params), null, {
          params,
          afterSagaSuccess: function* () {
            yield put(managementActions.partner.bleFilters.get(params, onSuccess));
            onSuccess?.();
          },
        }),
    },
    users: {
      get: (params) => apiAction(PARTNER_USERS_GET, 'GET', resolveUrl(apiUrls.PARTNER.USERS.GET, params)),
      create: (params, payload) =>
        apiAction(PARTNER_USERS_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.USERS.CREATE, params), payload, {
          params,
        }),
      delete: (params) =>
        apiAction(PARTNER_USERS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.USERS.DELETE, params), null, {
          params,
        }),
      cleanGet: () => ({ type: PARTNER_USERS_GET + CLEAR }),
      cleanCreated: () => ({ type: PARTNER_USERS_CREATE + CLEAR }),
      cleanDeleted: () => ({ type: PARTNER_USERS_DELETE + CLEAR }),
    },
    settings: {
      get: (params) =>
        apiAction(PARTNER_SETTINGS_GET, 'GET', resolveUrl(apiUrls.PARTNER.SETTINGS.GET, params), null, {
          afterSaga: function* () {
            // Get global settings to display as placeholders
            yield put(managementActions.settings.get(params.model));
          },
        }),
      save: (params, payload) =>
        apiAction(PARTNER_SETTINGS_SAVE, 'PATCH', resolveUrl(apiUrls.PARTNER.SETTINGS.SAVE, params), payload),
      delete: (params, payload, changedField) =>
        apiAction(PARTNER_SETTINGS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.SETTINGS.DELETE, params), payload, {
          data: { changedField },
        }),
      deleteAll: (params, onSuccess) =>
        apiAction(PARTNER_SETTINGS_DELETE_ALL, 'DELETE', resolveUrl(apiUrls.PARTNER.SETTINGS.DELETE, params), null, {
          afterSagaSuccess: function* () {
            yield onSuccess?.();
          },
        }),
      saveSensors: (params, payload) =>
        apiAction(PARTNER_SENSORS_SETTINGS_SAVE, 'PATCH', resolveUrl(apiUrls.PARTNER.SETTINGS.SAVE, params), payload),
      cleanGet: () => ({ type: PARTNER_SETTINGS_GET + CLEAR }),
      cleanSaved: () => ({ type: PARTNER_SETTINGS_SAVE + CLEAR }),
    },
    groups: {
      get: (params, onSuccess) =>
        apiAction(PARTNER_GROUPS_GET, 'GET', resolveUrl(apiUrls.PARTNER.GROUPS.GET, params), null, {
          afterSagaSuccess: function* () {
            yield onSuccess?.();
          },
        }),
      create: (params, payload) =>
        apiAction(PARTNER_GROUP_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.GROUPS.CREATE, params), payload, { params }),
      edit: (params, payload, onSuccess) =>
        apiAction(PARTNER_GROUP_EDIT, 'PATCH', resolveUrl(apiUrls.PARTNER.GROUPS.EDIT, params), payload, {
          params,
          afterSagaSuccess: function* () {
            yield put(managementActions.partner.groups.get(params, onSuccess));
          },
        }),
      delete: (params) =>
        apiAction(PARTNER_GROUP_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.GROUPS.DELETE, params), null, { params }),
      cleanGet: () => ({ type: PARTNER_GROUPS_GET + CLEAR }),
      cleanCreated: () => ({ type: PARTNER_GROUP_CREATE + CLEAR }),
      cleanDeleted: () => ({ type: PARTNER_GROUP_DELETE + CLEAR }),
      devices: {
        get: (params) =>
          apiAction(PARTNER_GROUP_DEVICES_GET, 'GET', resolveUrl(apiUrls.PARTNER.GROUPS.GET_DEVICES, params)),
        create: (params, payload) =>
          apiAction(
            PARTNER_GROUP_DEVICE_CREATE,
            'POST',
            resolveUrl(apiUrls.PARTNER.GROUPS.ADD_DEVICE, params),
            payload,
            { params }
          ),
        delete: (params) =>
          apiAction(
            PARTNER_GROUP_DEVICE_DELETE,
            'DELETE',
            resolveUrl(apiUrls.PARTNER.GROUPS.DELETE_DEVICE, params),
            null,
            { params }
          ),
        cleanGet: () => ({ type: PARTNER_GROUP_DEVICES_GET + CLEAR }),
        cleanCreated: () => ({ type: PARTNER_GROUP_DEVICE_CREATE + CLEAR }),
        cleanDeleted: () => ({ type: PARTNER_GROUP_DEVICE_DELETE + CLEAR }),
      },
    },
    keys: {
      get: (params) => apiAction(PARTNER_KEYS_GET, 'GET', resolveUrl(apiUrls.PARTNER.KEYS.GET, params)),
      create: (params, payload) =>
        apiAction(PARTNER_KEYS_CREATE, 'POST', resolveUrl(apiUrls.PARTNER.KEYS.CREATE, params), payload, params),
      delete: (params) =>
        apiAction(PARTNER_KEYS_DELETE, 'DELETE', resolveUrl(apiUrls.PARTNER.KEYS.DELETE, params), null, params),
      cleanCreated: () => ({ type: PARTNER_KEYS_CREATE + CLEAR }),
    },
  },
};
