import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import { uniqueId } from 'lodash';
import cx from 'classnames';

const Checkbox = ({ title, label, onChange, className, name, value, setValue }) => {
  const id = useRef(uniqueId(`vrs-input-${name.toLowerCase().replace(/ /g, '-')}-`));

  const changeValue = (event) => {
    const {
      target: { checked },
    } = event;

    onChange && onChange(checked);
    setValue(checked);
  };

  const inputClassName = cx('vrs-input-checkbox', className);

  return (
    <div data-testid="checkbox-wrapper" className={inputClassName} title={title}>
      <input id={id.current} type="checkbox" name={name} checked={value || false} onChange={changeValue} />
      <label title={label} htmlFor={id.current}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  // Formsy props
  name: PropTypes.string,
  value: PropTypes.bool,
  setValue: PropTypes.func,
};

export default withFormsy(Checkbox);
