import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Select from 'common/components/Select';
import Input from 'common/components/Input';

const ENVIRONMENTS = [
  {
    label: 'TEST',
    value: 'test',
  },
  {
    label: 'DEV',
    value: 'dev',
  },
  {
    label: 'PROD',
    value: 'prod',
  },
].filter((env) => env.value !== process.env.REACT_APP_CURRENT_ENV);

const MigrateTrackerForm = ({ onSubmit, error, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={error} className="migrate-tracker-form">
      <h4 className="mb-15">Migrate tracker</h4>
      <div className="migrate-tracker-form-control">
        <div className="migrate-tracker-form-label">API Key</div>
        <Input name="api_key" type="text" placeholder="Enter api key" required />
      </div>
      <div className="migrate-tracker-form-control">
        <div className="migrate-tracker-form-label">Secret Key</div>
        <Input name="secret_key" type="text" placeholder="Enter secret key" required />
      </div>
      <div className="migrate-tracker-form-control">
        <div className="migrate-tracker-form-label">Environment</div>
        <Select name="env" options={ENVIRONMENTS} required />
      </div>
      <div className="migrate-tracker-form-submit">
        <p>Make sure that the tracker belongs to the same partner on the new environment.</p>
        <Button type="submit" disabled={isSubmitting}>
          Migrate
        </Button>
      </div>
    </Form>
  );
};

MigrateTrackerForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default MigrateTrackerForm;
