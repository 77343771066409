import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';
import { FormSwitch } from 'common/components/Switch';

const UpdateBLEFilterForm = ({ initialValues = {}, onSubmit, errors, isSubmitting, onUnlinkScript }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="manage-content-control">
        <div className="row mb-15">
          <div className="settings-item-label">
            <p>Filter alias</p>
          </div>
          <div className="settings-item-controls">
            <Input
              type="text"
              name="alias"
              maxlength={5}
              value={initialValues.alias}
              placeholder="BLE filter alias e.g. rlb"
              required
            />
          </div>
        </div>
        <div className="row mb-15">
          <div className="settings-item-label">
            <p>Scan duration [s]</p>
          </div>
          <div className="settings-item-controls">
            <Input
              type="number"
              name="scan_duration"
              min={0}
              max={900}
              value={initialValues.scan_duration}
              placeholder="Scan duration e.g. 300s"
              required
            />
          </div>
        </div>
        <div className="row mb-15">
          <div className="gr-grow">
            <p className="mb-15">Filter data</p>
            <Input
              type="text"
              name="data"
              value={initialValues.data}
              placeholder="Data to filter e.g. A0B100200304"
              required
            />
          </div>
        </div>
        {!initialValues.script && (
          <div className="row mb-15">
            <div className="gr-grow">
              <p className="mb-15">Script</p>
              <Input type="file" name="new_script" placeholder="(Optional) .lua file" accept="*.lua" />
            </div>
          </div>
        )}
        {initialValues.script && (
          <div className="vrs-ble-script-details">
            <div className="row mb-15">
              <div className="gr-grow">
                <div className="row">
                  <p className="mb-15 mr-15">Script</p>
                  <FormSwitch
                    name="script.is_active"
                    label={initialValues.script.is_active ? 'Active' : 'Inactive'}
                    defaultChecked={initialValues.script.is_active}
                    invert
                  />
                </div>
              </div>
              <Button onClick={() => onUnlinkScript(initialValues.script.id)}>Delete</Button>
            </div>
            <div className="row mb-15">
              <div className="gr-grow">
                <Input
                  type="text"
                  name="script.name"
                  readOnly
                  value={initialValues.script.name}
                  placeholder="BLE filter script name"
                />
              </div>
            </div>
          </div>
        )}
        <div className="row gr-grow">
          <div className="gr-grow" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

UpdateBLEFilterForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  initialValues: PropTypes.object,
  onUnlinkScript: PropTypes.func,
};

export default UpdateBLEFilterForm;
