import React from 'react';
import PropTypes from 'prop-types';

import Header from 'common/components/Header';

// Initialize react-dates library
import 'react-dates/initialize';

// Import custom validations for Formsy
import 'common/utils/validations';

// Import styles from third party libraries
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datetime/css/react-datetime.css';
import 'react-dates/lib/css/_datepicker.css';

// Import project styles
import 'assets/styles/index.scss';

const App = ({ children }) => (
  <>
    <Header />
    <div className="content">{children}</div>
  </>
);

App.propTypes = {
  children: PropTypes.node,
};

export default App;
