export const TRACKERS_PAGE_SIZE = 20;

export const BATTERY_LEVEL = {
  LOW: 10,
  HIGH: 50,
};

export const TRACKER_MODE = {
  POWERED_ON: 'Powered on',
  IN_TRANSIT: 'In transit',
  IN_FLIGHT: 'Flight mode',
  DEEP_SLEEP: 'Deep sleep',
};

export const TRACKER_MODE_FILTERS = [
  {
    name: TRACKER_MODE.POWERED_ON,
    value: TRACKER_MODE.POWERED_ON,
  },
  {
    name: TRACKER_MODE.IN_TRANSIT,
    value: TRACKER_MODE.IN_TRANSIT,
  },
  {
    name: TRACKER_MODE.IN_FLIGHT,
    value: TRACKER_MODE.IN_FLIGHT,
  },
  {
    name: TRACKER_MODE.DEEP_SLEEP,
    value: TRACKER_MODE.DEEP_SLEEP,
  },
];

export const TRACKERS_DATA_TABLE = {
  tracker_id: {
    header: 'ID',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    data_key: 'id',
    sortable: true,
  },
  iccid: {
    header: 'ICCID',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
  },
  guid: {
    header: 'GUID',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
    size: 'long',
  },
  produced_at: {
    header: 'Production date',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
  },
  updated_at: {
    header: 'Last update',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
  },
  serial_number: {
    header: 'TTDG SN',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
    size: 'short',
  },
  produced_by: {
    header: 'Produced by',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
  },
  model: {
    header: 'Model',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
    size: 'short',
  },
  partner: {
    header: 'Partner',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: true,
    size: 'short',
    data_key: 'partner_name',
  },
  groups: {
    header: 'Groups',
    direction: 'ascending',
    isCurrentSort: 'false',
    filterable: true,
    sortable: false,
    size: 'short',
  },
  actions: {
    header: 'Actions',
    size: 'short',
    filterable: false,
    sortable: false,
  },
};
