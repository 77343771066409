import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Badge = ({ text, status, className }) => {
  const statusClass = status && `badge-${status}`;
  const arrowClassName = cx('badge', statusClass, className);

  return <span className={arrowClassName}>{text}</span>;
};

Badge.propTypes = {
  text: PropTypes.string,
  status: PropTypes.oneOf(['success', 'warning', 'error']),
  className: PropTypes.string,
};

export default Badge;
