import { all } from 'redux-saga/effects';

import { apiSaga } from 'base/redux/utils';
import {
  SIGN_OUT,
  RESET_PASSWORD,
  VERIFY_CODE,
  SET_PASSWORD,
  SIGN_IN,
  SETUP_TWO_FACTOR,
  CONFIRM_NEW_EMAIL,
} from './actions';

export function* authSaga() {
  yield all([
    apiSaga(SIGN_IN)(),
    apiSaga(SIGN_OUT)(),
    apiSaga(RESET_PASSWORD)(),
    apiSaga(SET_PASSWORD)(),
    apiSaga(VERIFY_CODE)(),
    apiSaga(SETUP_TWO_FACTOR)(),
    apiSaga(CONFIRM_NEW_EMAIL)(),
  ]);
}
