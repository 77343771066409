import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toNumber } from 'lodash';

import Loader from 'common/components/Loader';
import CreateDeviceForm from 'modules/menu/components/CreateDeviceForm';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';

const selector = ({ management }) => {
  const { devicesList, deviceCreated, deviceDeleted } = management.partner.groups;
  const isFetching = devicesList.isFetching || deviceCreated.isFetching || deviceDeleted.isFetching;
  return {
    isFetching,
    isCreating: deviceCreated.isFetching,
    devicesList: devicesList.data,
    errors: {
      created: deviceCreated.error,
      deleted: deviceDeleted.error,
    },
  };
};

const ManageGroupDevicesView = ({ partner, group }) => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, devicesList, errors } = useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(managementActions.partner.groups.devices.cleanGet());
      dispatch(managementActions.partner.groups.devices.cleanCreated());
      dispatch(managementActions.partner.groups.devices.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addDevice = (values) => {
    dispatch(
      managementActions.partner.groups.devices.create(
        { companyId: partner.id, groupId: group.id },
        { tracker_id: toNumber(values.tracker_id) }
      )
    );
  };

  const deleteDevice = (id) => {
    displayConfirmDialog().then(() => {
      dispatch(
        managementActions.partner.groups.devices.delete({
          companyId: partner.id,
          groupId: group.id,
          groupDeviceId: id,
        })
      );
    });
  };

  const renderDevices = (devices) => {
    if (devices?.length) {
      return devices.map(({ id, device_id }, index) => (
        <div key={index} className="manage-list-item">
          <span className="list-item-text">{device_id}</span>
          <button className="pure-button list-item-remove" onClick={() => deleteDevice(id)} />
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no trackers</div>;
    }
  };

  return (
    <>
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">{`Trackers of ${group.name}`}</p>
      </div>
      <div className="manage-content-control">
        <CreateDeviceForm onSubmit={addDevice} errors={errors.created} isSubmitting={isCreating} />
      </div>
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderDevices(devicesList?.trackers)}
        <Loader loaded={!isFetching} />
      </div>
    </>
  );
};

ManageGroupDevicesView.propTypes = {
  partner: PropTypes.object,
  group: PropTypes.object,
};

export default ManageGroupDevicesView;
