import React from 'react';
import PropTypes from 'prop-types';

import { getTrackerModeIcon, getBatteryIcon } from 'modules/trackers/utils';
import { fromNow } from 'common/utils';

const TrackerListItem = ({ tracker, onTrackerClick }) => {
  return (
    <div className="tracker-list-item">
      <div className="row">
        <div className="gr-12@xs gr-6@sm">
          <button className="pure-button tracker-name" onClick={() => onTrackerClick(tracker)}>
            {tracker.id}
          </button>
          <div className="tracker-partner">{tracker.company_name}</div>
        </div>
        <div className="gr-12@xs gr-6@sm">
          <div className="tracker-info">
            <div className="tracker-status">
              <span>{tracker.firmware}</span>
              <span>{tracker.battery}%</span>
              {getBatteryIcon(tracker.battery, 25, true)}
              {getTrackerModeIcon(tracker.system_state, 18)}
            </div>
            <div className="tracker-last-update">{fromNow(tracker.updated_at)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

TrackerListItem.propTypes = {
  tracker: PropTypes.object,
  onTrackerClick: PropTypes.func,
};

export default TrackerListItem;
