import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Arrow = ({ direction, size, className }) => {
  const directionClass = `arrow-${direction}`;
  const arrowClassName = cx('arrow', directionClass, className);

  return <div className={arrowClassName} style={{ width: size, height: size }} />;
};

Arrow.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'right', 'left']),
  size: PropTypes.number,
  className: PropTypes.string,
};

export default Arrow;
