import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { managementActions } from 'modules/menu/redux/actions';
import ImportDevicesForm from 'modules/menu/components/ImportDevicesForm';

const selector = ({ management }) => {
  const { error, isFetching } = management.devices.import;

  return {
    isFetching,
    errors: error,
  };
};

const DevicesView = () => {
  const dispatch = useDispatch();
  const { isFetching, errors } = useSelector(selector);

  const importDevices = (values, resetForm) => {
    const formData = new FormData();
    const { file, partnerID, model, activateSim, simOperator } = values;

    formData.append('devices', file);
    formData.append('model', model.value);
    formData.append('partnerID', partnerID.value);
    formData.append('activateSim', Boolean(activateSim));

    if (simOperator) {
      formData.append('simOperator', simOperator.value);
    }

    dispatch(managementActions.devices.import(formData, resetForm));
  };

  return (
    <div className="manage-view account">
      <h2 className="manage-view-header">Import Devices from CSV</h2>
      <ImportDevicesForm onSubmit={importDevices} errors={errors} isSubmitting={isFetching} />
    </div>
  );
};

export default DevicesView;
