import Route from 'route-parser';
import queryString from 'query-string';

/**
 * Returns a path with the given parameters.
 * @param {string} pattern - The path pattern.
 * @param {Object} params - The parameters of the path.
 * @param {Object} [query] - The query string parameters of the path.
 */
export const resolveUrl = (pattern, params, query) => {
  const route = new Route(pattern);
  const url = route.reverse(params);
  const formattedQuery = queryString.stringify(query, { arrayFormat: 'comma', skipEmptyString: true });
  return query ? `${url}?${formattedQuery}` : url;
};

/**
 * Returns parameters from the given path.
 * @param {string} pattern - The path pattern.
 * @param {string} path - The resolved path.
 */
export const matchUrl = (pattern, path) => {
  const route = new Route(pattern);
  return route.match(path);
};
