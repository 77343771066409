import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SettingsForm } from 'common/components/SettingsForm';
import { managementActions } from 'modules/menu/redux/actions';

const selector = ({ management: { settings } }) => ({
  settings: settings.list.data,
  isFetching: settings.list.isFetching,
  error: settings.list.error,
  saved: settings.saved,
});

const SettingsView = () => {
  const dispatch = useDispatch();
  const { settings, isFetching, error, saved } = useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(managementActions.settings.cleanGet());
      dispatch(managementActions.settings.cleanSaved());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSettingsSubmit = ({ model, payload }) => {
    dispatch(managementActions.settings.save(model, payload));
  };

  return (
    <div className="manage-view">
      <h2 className="manage-view-header">Settings</h2>
      <SettingsForm
        settings={settings}
        onSubmit={onSettingsSubmit}
        onModelChange={(model) => dispatch(managementActions.settings.get(model))}
        isFetching={isFetching}
        generalSettingStatus={{
          isSubmitting: saved.fetching,
          isSuccess: saved.success,
          error: saved.error,
        }}
        isMainSettingsLevel={true}
      />
      {error && <p className="vrs-error">{error.error}</p>}
    </div>
  );
};

export default SettingsView;
