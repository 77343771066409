import moment from 'moment';
import { formatDatetime } from 'common/utils';

// Sort flights by STD and create fake id's for it
export const prepareFlights = (data) =>
  (data || [])
    .sort((a, b) => (moment(a.std) < moment(b.std) ? -1 : 1))
    .map((flight, i) => ({ ...flight, id: i.toString() }));

// Extract arrival flights based on departure flight
export const findArrivalFlights = (flights, std) => {
  const tail = flights.slice(flights.findIndex((flight) => flight.std === std));
  return tail.reduce(
    (acc, current) => (current.origin_airport_code === acc[acc.length - 1].dest_airport_code ? [...acc, current] : acc),
    [tail[0]]
  );
};

export const getDeparturesRadios = (flights) =>
  flights
    .filter((flight) => moment(flight.std).diff(moment(flights[0].std), 'days') < 1)
    .map((flight) => ({
      label: `${formatDatetime(flight.std, 'N/A', flight.origin_timezone)} from ${flight.origin_airport_name}`,
      value: flight.std,
    }));

export const getArrivalsRadios = (flights) =>
  flights.map((flight) => ({
    label: `${formatDatetime(flight.sta, 'N/A', flight.dest_timezone)} to ${flight.dest_airport_name}`,
    value: `${flight.dest_airport_code}_${flight.sta}`, //Adds flight sta to destCode to be unique
  }));
