import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { debounce, values } from 'lodash';

import TrackerViewContainer from 'modules/trackers/containers/TrackerViewContainer';
import TrackersListView from 'modules/trackers/views/TrackersListView';
import TrackersManagementView from 'modules/trackers/views/TrackersManagementView';
import TrackersMap from 'modules/trackers/components/TrackersMap';

import { trackersActions } from 'modules/trackers/redux/actions';
import { resolveUrl } from 'common/utils';

import { appUrls } from 'urls';

export const DEFAULT_TOP_LEFT_COORDS = '90,-180';
export const DEFAULT_BOTTOM_RIGHT_COORDS = '-90,180';
export const DEFAULT_PRECISION = '19876419m';

const TrackersMainView = ({ location, match, dispatch, searchParams, trackers }) => {
  const getTrackers = ({ topLeftCorner, bottomRightCorner, precision, trackerId }) => {
    dispatch(
      trackersActions.search.setSearchParams({
        precision,
        top_left: topLeftCorner && !trackerId ? values(topLeftCorner).join() : DEFAULT_TOP_LEFT_COORDS,
        bottom_right: bottomRightCorner && !trackerId ? values(bottomRightCorner).join() : DEFAULT_BOTTOM_RIGHT_COORDS,
        tracker_id: trackerId,
      })
    );
  };

  const getTrackersDebounced = debounce(getTrackers, 300);

  const getTrackersWrapper = (params, delay) => {
    delay ? getTrackersDebounced(params) : getTrackers(params);
  };

  const showTrackerDetails = (tracker, { isCenter, isSingle } = {}) => {
    dispatch(trackersActions.tracker.setSelectedTracker(tracker, { isCenter, isSingle }));
    dispatch(push(resolveUrl(appUrls.TRACKERS.BASE, { trackerId: tracker.id })));
  };

  return (
    <React.Fragment>
      {location.pathname === appUrls.TRACKERS.MANAGEMENT ? (
        <TrackersManagementView />
      ) : (
        <TrackersMap
          geohashes={trackers.data?.buckets ?? []}
          trackers={trackers.data?.devices ?? []}
          isPagination={trackers.isPagination}
          match={match}
          getTrackers={getTrackersWrapper}
          showTrackerDetails={showTrackerDetails}
        />
      )}
      <TrackersListView
        trackers={trackers.data}
        isPagination={trackers.isPagination}
        showTrackerDetails={showTrackerDetails}
      />
      <TrackerViewContainer />
    </React.Fragment>
  );
};

TrackersMainView.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  // Redux props
  dispatch: PropTypes.func,
  searchParams: PropTypes.object,
  trackers: PropTypes.object,
};

const mapStateToProps = ({ account, trackers }) => {
  return {
    searchParams: trackers.search.params,
    trackers: trackers.search.trackers,
  };
};

export default connect(mapStateToProps)(TrackersMainView);
