import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';

import { trackersActions } from 'modules/trackers/redux/actions';

const selector = ({
  trackers: {
    trackersTable: {
      export: { isFetching, error },
    },
  },
}) => ({
  isFetching,
  error,
});

const ExportCSV = ({ sortBy, filters }) => {
  const { isFetching, error } = useSelector(selector);
  const dispatch = useDispatch();

  const exportToCSV = (sortBy, filters) => {
    dispatch(trackersActions.list.exportCsv({ page: 0, sort_by: sortBy, ...filters }));
  };

  return (
    <>
      <Button onClick={() => exportToCSV(sortBy, filters)} disabled={isFetching}>
        Download .CSV
      </Button>
      {error && <p className="vrs-error">{error.error}</p>}
    </>
  );
};

ExportCSV.propTypes = {
  sortBy: PropTypes.string,
  filters: PropTypes.object,
};

export default ExportCSV;
