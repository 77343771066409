import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import Modal from 'common/components/Modal';
import MainMenu from 'modules/menu/components/MainMenu';
import PartnerMenu from 'modules/menu/components/PartnerMenu';
import PartnersListView from 'modules/menu/views/PartnersListView';
import PartnerCreateView from 'modules/menu/views/PartnerCreateView';
import ManageCustomersView from 'modules/menu/views/ManageCustomersView';
import ManageTCCAdminView from 'modules/menu/views/ManageTCCAdminsView';
import ManageBLEFiltersView from 'modules/menu/views/ManageBLEFiltersView';
import ManageBLEFilterView from 'modules/menu/views/ManageBLEFilterView';
import ManageUsersView from 'modules/menu/views/ManageUsersView';
import ManageSettingsView from 'modules/menu/views/ManageSettingsView';
import ManageGroupsView from 'modules/menu/views/ManageGroupsView';
import ManageGroupDevicesView from 'modules/menu/views/ManageGroupDevicesView';
import ManageKeysView from 'modules/menu/views/ManageKeysView';
import SettingsView from 'modules/menu/views/SettingsView';
import FirmwaresView from 'modules/menu/views/FirmwaresView';
import DevicesView from 'modules/menu/views/DevicesView';
import AccountView from 'modules/menu/views/AccountView';
import ChangePasswordView from 'modules/account/views/ChangePasswordView';
import ChangeEmailView from 'modules/account/views/ChangeEmailView';
import ChangeTwoFactorView from 'modules/account/views/ChangeTwoFactorView';

import { authActions } from 'modules/auth/redux/actions';
import { menuActions, managementActions } from 'modules/menu/redux/actions';
import { menuPaths, menuRegex } from 'modules/menu/consts';

const selector = ({ menu, management, account }) => ({
  isMenuOpen: menu.modal.isOpen,
  path: menu.path.path,
  partnersList: management.partners.list,
  isAdmin: account.currentUser.isAdmin,
  isPartner: account.currentUser.isPartner,
});

const MenuContainer = () => {
  const [currentPartner, setCurrentPartner] = useState(null);
  const [currentBleFilter, setCurrentBleFilter] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const dispatch = useDispatch();
  const { isMenuOpen, path, partnersList, isAdmin, isPartner } = useSelector(selector);

  const signOut = () => {
    dispatch(authActions.signOut());
  };

  const closeMenu = () => {
    dispatch(menuActions.closeMenu());
  };

  const goMenuBack = () => {
    dispatch(menuActions.goBack());
  };

  const setMenuPath = (path) => {
    dispatch(menuActions.setPath(decodeURIComponent(path)));
  };

  const fetchPartnersList = () => {
    if (!partnersList.data) {
      dispatch(managementActions.partners.get());
    }
  };

  const renderModalContent = () => {
    switch (true) {
      case menuRegex.HOME.test(path):
        return (
          <MainMenu
            setMenuPath={setMenuPath}
            fetchPartnersList={fetchPartnersList}
            onSignOut={signOut}
            isAdmin={isAdmin}
          />
        );
      case menuRegex.PARTNERS_LIST.test(path):
        return <PartnersListView setMenuPath={setMenuPath} setCurrentPartner={setCurrentPartner} />;
      case menuRegex.PARTNERS_CREATE.test(path):
        return <PartnerCreateView />;
      case menuRegex.PARTNER_CUSTOMERS.test(path):
        return (
          <ManageCustomersView
            partner={currentPartner}
            setMenuPath={setMenuPath}
            setCurrentCustomer={setCurrentCustomer}
          />
        );
      case menuRegex.PARTNER_CUSTOMERS_KEYS.test(path):
        return <ManageKeysView partner={currentCustomer} />;
      case menuRegex.PARTNER_TCC_ADMINS.test(path):
        return <ManageTCCAdminView partner={currentPartner} />;
      case menuRegex.PARTNER_BLE_FILTERS.test(path):
        return (
          <ManageBLEFiltersView
            currentBleFilter={currentBleFilter}
            setMenuPath={setMenuPath}
            setCurrentBleFilter={setCurrentBleFilter}
            partner={currentPartner}
          />
        );
      case menuRegex.PARTNER_BLE_FILTERS_DETAILS.test(path):
        return (
          <ManageBLEFilterView
            currentBleFilter={currentBleFilter}
            setMenuPath={setMenuPath}
            setCurrentBleFilter={setCurrentBleFilter}
            partner={currentPartner}
          />
        );
      case menuRegex.PARTNER_USERS.test(path):
        return <ManageUsersView partner={currentPartner} />;
      case menuRegex.PARTNER_SETTINGS.test(path):
        return <ManageSettingsView partner={currentPartner} />;
      case menuRegex.PARTNER_GROUPS.test(path):
        return (
          <ManageGroupsView partner={currentPartner} setMenuPath={setMenuPath} setCurrentGroup={setCurrentGroup} />
        );
      case menuRegex.PARTNER_GROUP_DEVICES.test(path):
        return <ManageGroupDevicesView partner={currentPartner} group={currentGroup} />;
      case menuRegex.PARTNER_KEYS.test(path):
        return <ManageKeysView partner={currentPartner} />;
      case menuRegex.SETTINGS.test(path):
        return <SettingsView />;
      case menuRegex.FIRMWARES.test(path):
        return <FirmwaresView />;
      case menuRegex.DEVICES.test(path):
        return <DevicesView />;
      case menuRegex.ACCOUNT.test(path):
        return <AccountView setMenuPath={setMenuPath} />;
      case menuRegex.ACCOUNT_PASSWORD.test(path):
        return <ChangePasswordView isPartner={isPartner} />;
      case menuRegex.ACCOUNT_EMAIL.test(path):
        return <ChangeEmailView isPartner={isPartner} />;
      case menuRegex.ACCOUNT_2FA.test(path):
        return <ChangeTwoFactorView isPartner={isPartner} />;
      // The following case has to be the last one due to the incorrect regex matching
      case menuRegex.PARTNER_MENU.test(path):
        return <PartnerMenu partner={currentPartner} setMenuPath={setMenuPath} />;
      default:
        return null;
    }
  };

  const isHomePath = path === menuPaths.HOME;

  return (
    <Modal
      isOpen={isMenuOpen}
      modalContainerClass={cx({ 'partner-menu-modal-container': isPartner })}
      isAutoSize={isHomePath}
      onClose={closeMenu}
      onBack={!isHomePath ? goMenuBack : null}
      onAnimateExited={() => setMenuPath(menuPaths.HOME)}
      headerContent={isHomePath && 'Menu'}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default MenuContainer;
