import React from 'react';
import PropTypes from 'prop-types';

import Portal from 'base/containers/Portal';

const ModalAlert = ({ children, headerText, isOpen, onCloseClick }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <div className="modal-alert-overlay" data-testid="modal-alert-overlay">
        <div className="modal-alert-container">
          <div className="modal-alert-header">
            {headerText && <span className="modal-alert-title">{headerText}</span>}
            <button className="modal-alert-close pure-button" data-testid="modal-alert-close" onClick={onCloseClick} />
          </div>
          <div className="modal-alert-content">{children}</div>
        </div>
      </div>
    </Portal>
  );
};

ModalAlert.propTypes = {
  children: PropTypes.node,
  headerText: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

export default ModalAlert;
