// @ts-nocheck

import React, { ForwardedRef, forwardRef } from 'react';

import { SettingsControl } from './SettingsControl';
import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';
import Loader from 'common/components/Loader';

import { getValidationErrors, parseSettingsValue, minNumber, maxNumber } from './utils';
import { ConfigurationInputSchema } from 'api/models/ConfigurationInputSchema';
import { SettingsFormProps } from '.';

type FixedLocationFormProps = Omit<SettingsFormProps, 'onRestore' | 'fixedLocationStatus'>;

export const FixedLocationForm = forwardRef(
  (
    { settings, onSubmit, isFetching, fixedLocationStatus }: FixedLocationFormProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const { isSubmitting, error } = fixedLocationStatus;

    const handleSubmit = (values: Pick<ConfigurationInputSchema, 'lat', 'lon'>) => {
      const lat = parseSettingsValue(values.lat),
        lon = parseSettingsValue(values.lon);
      if (lat !== null && lon !== null) {
        onSubmit({ payload: { lat, lon } });
      }

      if (lat === null && lon === null) {
        onSubmit({ payload: { lat, lon } });
      }
    };

    return (
      <Form onSubmit={handleSubmit} ref={ref}>
        <Loader loaded={!isFetching} />
        <SettingsControl key="lat" label="Fixed location">
          <div>
            <p>Latitude</p>
            <Input
              type="number"
              name={`lat`}
              value={settings?.lat}
              validations={`isLocation:lon,minNumber:-90,maxNumber:90`}
              validationErrors={{
                isLocation: 'Set Longitude',
                ...getValidationErrors([minNumber(-90), maxNumber(90)]),
              }}
              disabled={isFetching}
            />
          </div>
          <div>
            <p>Longitude</p>
            <Input
              type="number"
              name={`lon`}
              value={settings?.lon}
              validationErrors={{
                isLocation: 'Set Latitude',
                ...getValidationErrors([minNumber(-180), maxNumber(180)]),
              }}
              validations={`isLocation:lat,minNumber:-180,maxNumber:180`}
              disabled={isFetching}
            />
          </div>
        </SettingsControl>
        <div className="settings-save-btn">
          <Button type="submit" disabled={isSubmitting}>
            Set location
          </Button>
        </div>

        {error.error && <p className="vrs-error">{`Something went wrong: ${error.error}`}</p>}
      </Form>
    );
  }
);
