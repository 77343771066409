import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { withFormsy } from 'formsy-react';
import moment from 'moment';
import cx from 'classnames';

const DateTimeInput = ({
  dateFormat,
  timeFormat,
  className,
  value,
  setValue,
  errorMessage,
  showRequired,
  isPristine,
}) => {
  const changeValue = (date) => setValue(date);
  const error = errorMessage || (!isPristine && showRequired && 'This field is required');
  const momentDate = moment(value, dateFormat, true);
  const inputClassName = cx('vrs-input', className);

  return (
    <div className="vrs-input-box">
      <Datetime
        value={momentDate.isValid() && value}
        onChange={changeValue}
        className="vrs-date-time-picker"
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        utc={true}
        inputProps={{ className: inputClassName }}
      />
      {error && <p className="vrs-error">{error}</p>}
    </div>
  );
};

DateTimeInput.defaultProps = {
  dateFormat: 'YYYY-MM-DD',
  timeFormat: 'HH:mm',
};

DateTimeInput.propTypes = {
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  // Formsy props
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool,
  isPristine: PropTypes.bool,
};

export default withFormsy(DateTimeInput);
