import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const CreateKeysForm = ({ onSubmit, errors, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="create-keys-form">
        <div className="create-keys-input">
          <Input name="name" type="text" value="" placeholder="Enter key name" required />
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Create API key
          </Button>
        </div>
      </div>
    </Form>
  );
};

CreateKeysForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default CreateKeysForm;
