import { takeEvery, put, delay } from 'redux-saga/effects';
import { REQUEST, SUCCESS, FAILURE, CLEAR } from 'base/redux/consts';

const RESET = '_RESET';

const requestReducer = (state, key) => ({
  ...state,
  success: { ...state.success, [key]: false },
  error: { ...state.error, [key]: null },
  fetching: { ...state.fetching, [key]: true },
});

const successReducer = (state, key, value = true) => ({
  ...state,
  fetching: { ...state.fetching, [key]: false },
  success: { ...state.success, [key]: value },
});

const failureReducer = (state, key, error) => ({
  ...state,
  fetching: { ...state.fetching, [key]: false },
  error: { ...state.error, ...error.fields },
});

export const createSettingsStateReducer = (savedType, deletedType) => {
  const initialState = {
    fetching: {},
    success: {},
    error: {},
  };

  const makeTypes = (type) => [type + REQUEST, type + SUCCESS, type + FAILURE, type + CLEAR, type + RESET];
  const [SAVED_REQUEST_TYPE, SAVED_SUCCESS_TYPE, SAVED_FAILURE_TYPE, SAVED_CLEAR_TYPE, SAVED_RESET_TYPE] =
    makeTypes(savedType);
  const [DELETED_REQUEST_TYPE, DELETED_SUCCESS_TYPE, DELETED_FAILURE_TYPE, DELETED_CLEAR_TYPE, DELETED_RESET_TYPE] =
    makeTypes(deletedType);

  return (state = initialState, action) => {
    switch (action.type) {
      case SAVED_REQUEST_TYPE: {
        const key = Object.keys(action.payload)[0];
        return requestReducer(state, key);
      }
      case DELETED_REQUEST_TYPE: {
        const key = action.options.data.changedField;
        return requestReducer(state, key);
      }
      case SAVED_SUCCESS_TYPE: {
        const key = Object.keys(action.requestAction.payload)[0];
        return successReducer(state, key);
      }
      case DELETED_SUCCESS_TYPE: {
        const key = action.requestAction.options.data.changedField;
        return successReducer(state, key);
      }
      case SAVED_FAILURE_TYPE: {
        const key = Object.keys(action.requestAction.payload)[0];
        return failureReducer(state, key, action.error);
      }
      case DELETED_FAILURE_TYPE: {
        const key = action.requestAction.options.data.changedField;
        return failureReducer(state, key, action.error);
      }
      case SAVED_RESET_TYPE: {
        const key = Object.keys(action.requestAction.payload)[0];
        return successReducer(state, key, false);
      }
      case DELETED_RESET_TYPE: {
        const key = action.requestAction.options.data.changedField;
        return successReducer(state, key, false);
      }
      case SAVED_CLEAR_TYPE:
      case DELETED_CLEAR_TYPE: {
        return initialState;
      }
      default: {
        return state;
      }
    }
  };
};

export const createSettingsResetSaga = (type) => {
  function* successIndicatorSaga(action) {
    yield delay(1500);
    yield put({
      ...action,
      type: type + RESET,
    });
  }
  return function* () {
    yield takeEvery(type + SUCCESS, successIndicatorSaga);
  };
};
