import { combineReducers } from 'redux';

import { apiReducer } from 'base/redux/utils';
import { REQUEST, SUCCESS, FAILURE } from 'base/redux/consts';
import {
  SET_SESSION,
  CLEAR_SESSION,
  SET_USER,
  GET_USER,
  CHANGE_PASSWORD,
  CHANGE_EMAIL,
  CHANGE_2FA,
} from 'modules/account/redux/actions';

const sessionInitialState = {
  isInitial: true, // first request on start the app
  isLogged: false, // after sign in (1st step)
  isAuthenticated: false, // after verify 2fa (2nd step)
  missing2FASetup: false,
  missing2FACode: false,
};

const session = (state = sessionInitialState, action) => {
  switch (action.type) {
    case SET_SESSION: {
      const { options } = action;
      return {
        isInitial: false,
        isLogged: !options?.missingActivity,
        isAuthenticated: options?.isAuthenticated || false,
        missing2FASetup: options?.missing2FASetup || false,
        missing2FACode: options?.missing2FACode || false,
      };
    }
    case CLEAR_SESSION:
      return {
        ...sessionInitialState,
        isInitial: false,
      };
    default:
      return state;
  }
};

const userInitialState = {
  data: null,
  isAdmin: null,
  isPartner: null,
  isFetching: false,
  error: null,
};

const currentUser = (state = userInitialState, action) => {
  switch (action.type) {
    case GET_USER + REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USER + SUCCESS:
    case SET_USER:
      return {
        ...state,
        data: action.data,
        isAdmin: action.data?.is_admin,
        isPartner: !action.data?.is_admin,
        isFetching: userInitialState.isFetching,
        error: userInitialState.error,
      };
    case GET_USER + FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: userInitialState.isFetching,
      };
    default:
      return state;
  }
};

export const account = combineReducers({
  session,
  currentUser,
  changedPassword: apiReducer(CHANGE_PASSWORD),
  changedEmail: apiReducer(CHANGE_EMAIL),
  changedTwoFactor: apiReducer(CHANGE_2FA),
});
