// @ts-nocheck

import React, { useEffect, useRef, useState } from 'react';

import { GeneralSettingsForm } from './GeneralSettingsForm';
import { SensorsSettingsForm } from './SensorsSettingsForm';
import { FixedLocationForm } from './FixedLocationForm';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Select from 'common/components/Select';

import { deviceModelOptions, formatFirmwareOption } from './utils';
import { ConfigurationSchema, SensorsConfigurationSchema } from 'api/models/ConfigurationSchema';
import { ConfigurationInputSchema } from 'api/models/ConfigurationInputSchema';

export type ChangedFieldType = {
  [K in keyof ConfigurationInputSchema]: string | number | SensorsConfigurationSchema[] | null;
};

export type SettingsFormProps = {
  settings: ConfigurationSchema | null;
  defaultSettings?: ConfigurationSchema | null;
  onSubmit: (data: { model: string; payload: ChangedFieldType; allValues?: ConfigurationInputSchema }) => void;
  onRestore?: (onSuccess: () => void, model: string) => void;
  onModelChange?: (model: string) => void;
  isFetching: boolean;
  generalSettingStatus: {
    isSubmitting: { [K in keyof ConfigurationInputSchema]: boolean } | {};
    isSuccess: { [K in keyof ConfigurationInputSchema]: boolean } | {};
    error: { [K in keyof ConfigurationInputSchema]: string | null } | {};
  };
  sensorsSettingsStatus?: {
    isSubmitting: boolean;
    error: string | null;
  };
  fixedLocationStatus?: {
    isSubmitting: boolean;
    error:
      | {
          lat: string | null;
          lon: string | null;
        }
      | {};
  };
  isMainSettingsLevel?: boolean;
  trackerModel?: string;
};

/**
 * Due to difficulties on the frontend side and required backend changes, we decided to split settings into two
 * independent parts and handle submitting in two different ways. It will be unified in the future.
 */
export const SettingsForm = ({
  settings,
  defaultSettings,
  onSubmit,
  onRestore,
  onModelChange,
  isFetching,
  generalSettingStatus,
  sensorsSettingsStatus,
  fixedLocationStatus,
  isMainSettingsLevel,
  trackerModel,
}: SettingsFormProps) => {
  const [currentModel, setCurrentModel] = useState<string | null>(null);

  // TODO Check available types from formsy-react
  const generalSettingsRef = useRef<HTMLFormElement>();
  const sensorsSettingsRef = useRef<HTMLFormElement>();
  const locationSettingsRef = useRef<HTMLFormElement>();

  useEffect(() => {
    currentModel && onModelChange(currentModel);
  }, [currentModel]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleRestore = () => {
    const restoreGeneralSettings = () => {
      const form = generalSettingsRef.current;
      const formValues = form?.getModel();
      const restoredValues = { ...formValues };

      Object.keys(restoredValues).forEach((key) => (restoredValues[key] = null));
      restoredValues.firmware_id = formatFirmwareOption(defaultSettings?.firmware);
      form?.reset(restoredValues);
    };

    const restoreSensorsSettings = () => {
      const form = sensorsSettingsRef.current;
      const formValues = form?.getModel();
      const restoredValues = formValues?.sensors_configurations.map((sensor) => ({ ...sensor, min: null, max: null }));

      form?.reset({ sensors_configurations: restoredValues });
    };

    onRestore?.(() => {
      // Reset form inputs to empty strings and selectors to default settings
      restoreGeneralSettings();
      restoreSensorsSettings();
    }, currentModel);
  };

  return (
    <>
      {!trackerModel && (
        <Form className="mb-15">
          <Select
            name="model"
            options={deviceModelOptions}
            placeholder="Device model"
            onChange={(option) => setCurrentModel(option.model)}
            required
          />
        </Form>
      )}
      {settings || defaultSettings ? (
        <>
          <div className="settings-block">
            <p className="settings-header">General</p>
            {!trackerModel && <p className="settings-warning">Warning: changing these settings affects all trackers</p>}
            <GeneralSettingsForm
              settings={settings}
              defaultSettings={defaultSettings}
              onSubmit={(args) => onSubmit({ ...args, model: currentModel })}
              isFetching={isFetching}
              generalSettingStatus={generalSettingStatus}
              isMainSettingsLevel={isMainSettingsLevel}
              trackerModel={trackerModel ?? currentModel}
              ref={generalSettingsRef}
            />
          </div>
          {fixedLocationStatus && trackerModel?.match('GATEWAY') && (
            <div className="settings-block">
              <p className="settings-header">Fixed location</p>
              <FixedLocationForm
                settings={settings}
                defaultSettings={defaultSettings}
                onSubmit={(args) => onSubmit({ ...args, model: currentModel })}
                isFetching={isFetching}
                fixedLocationStatus={fixedLocationStatus}
                trackerModel={trackerModel}
                ref={locationSettingsRef}
              />
            </div>
          )}
          {!isMainSettingsLevel && (
            <>
              <div className="settings-block">
                <p className="settings-header">Sensors</p>
                {!trackerModel && (
                  <p className="settings-warning">Warning: changing these settings affects all trackers</p>
                )}
                <SensorsSettingsForm
                  settings={settings}
                  defaultSettings={defaultSettings}
                  onSubmit={(args) => onSubmit({ ...args, model: currentModel })}
                  isFetching={isFetching}
                  sensorsSettingsStatus={sensorsSettingsStatus}
                  trackerModel={trackerModel}
                  ref={sensorsSettingsRef}
                />
              </div>
              <Button type="button" onClick={handleRestore} data-testid="restore-settings">
                Restore default settings
              </Button>
            </>
          )}
        </>
      ) : (
        <p>Please choose the device model</p>
      )}
    </>
  );
};
