import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';

const Form = forwardRef(({ onSubmit, onChange, errors, className, children }, ref) => {
  const formRef = ref || React.createRef();

  useEffect(() => {
    errors?.fields && formRef?.current.updateInputsWithError(errors.fields);
  }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formsy onValidSubmit={onSubmit} onChange={onChange} className={className} ref={formRef}>
      {children}
      {errors && !errors.fields && <p className="vrs-error">{errors.error}</p>}
    </Formsy>
  );
});

Form.displayName = 'Form';

Form.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  errors: PropTypes.shape({
    error: PropTypes.string,
    fields: PropTypes.object,
  }),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Form;
