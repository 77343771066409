import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import ChatbotChannels from 'modules/trackers/components/ChatbotChannels';

import { trackersActions } from 'modules/trackers/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';

const selector = ({ trackers }) => ({
  channels: trackers.tracker.channels.list,
});

const ChatbotChannelsView = ({ match }) => {
  const dispatch = useDispatch();
  const { channels } = useSelector(selector);

  useEffect(() => {
    dispatch(trackersActions.tracker.channels.get(match.params.trackerId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteChannel = (userId, channel) => {
    displayConfirmDialog().then(() => {
      dispatch(trackersActions.tracker.channels.delete({ trackerId: match.params.trackerId, userId, channel }));
    });
  };

  return (
    <React.Fragment>
      <ChatbotChannels channels={channels.data && channels.data.channels} onChannelDelete={deleteChannel} />
      <Loader loaded={!channels.isFetching} />
    </React.Fragment>
  );
};

ChatbotChannelsView.propTypes = {
  match: PropTypes.object,
};

export default ChatbotChannelsView;
