import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import { delay } from 'lodash';

const ANIMATION_DELAY = 300;
const CONTENT_MAX_HEIGHT = 204;

const Accordion = ({ header, headerClassName, children, onToggle, className, disabled, title }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const contentRef = useRef();
  const contentHeight = contentRef.current?.scrollHeight;

  const onClick = () => {
    setCollapsed(!isCollapsed);
    if (onToggle) {
      delay(onToggle, ANIMATION_DELAY, 'later');
    }
  };
  const containerClass = cx('accordion', className, {
    active: !isCollapsed,
  });
  const headerClass = cx('accordion-header', headerClassName);
  const contentStyle = {
    maxHeight: isCollapsed ? 0 : contentHeight < CONTENT_MAX_HEIGHT ? `${contentHeight}px` : `${CONTENT_MAX_HEIGHT}px`,
  };

  return (
    <div className={containerClass}>
      <button className={headerClass} onClick={onClick} disabled={disabled} title={title}>
        {header}
      </button>
      <div className="accordion-content" style={contentStyle} ref={contentRef}>
        <PerfectScrollbar>{children}</PerfectScrollbar>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  headerClassName: PropTypes.string,
  header: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onToggle: PropTypes.func,
};

export default Accordion;
