import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import TrackerDetails from 'modules/trackers/components/TrackerDetails';

import { trackersActions } from 'modules/trackers/redux/actions';

const selector = ({ trackers }) => ({
  details: trackers.tracker.details,
});

const TrackerDetailsView = ({ match }) => {
  const dispatch = useDispatch();
  const { details } = useSelector(selector);

  useEffect(() => {
    dispatch(trackersActions.tracker.getDetails(match.params));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="tracker-details-outer">
      <TrackerDetails details={details.data} />
      <Loader loaded={!details.isFetching} />
    </div>
  );
};

TrackerDetailsView.propTypes = {
  match: PropTypes.object,
};

export default TrackerDetailsView;
