import { combineReducers } from 'redux';
import { apiReducer, createSettingsStateReducer } from 'base/redux/utils';
import { SUCCESS } from 'base/redux/consts';
import { apiUrls } from 'urls';
import { matchUrl } from 'common/utils';

import {
  TRACKER_MODAL_OPEN,
  SEARCH_PARAMS,
  SEARCH_FILTERS,
  TRACKERS_LIST,
  TRACKER_SUGGESTIONS,
  TRACKER_SUGGESTION,
  TRACKER_SELECTED,
  TRACKER_DETAILS,
  TRACKER_EXPORT,
  TRACKER_PARTNER,
  TRACKER_GROUPS_UPDATE,
  TRACKER_SETTINGS_GET,
  TRACKER_SETTINGS_SAVE,
  TRACKER_SETTINGS_DELETE,
  TRACKER_SENSORS_SETTINGS_SAVE,
  TRACKER_FLIGHTS_INFO_GET,
  TRACKER_FLIGHTS_INFO_SET,
  TRACKER_FLIGHTS_INFO_DELETE,
  TRACKER_BLE_DEVICES_GET,
  TRACKER_CHATBOT_CHANNELS_GET,
  TRACKER_CHATBOT_CHANNELS_DELETE,
  FLIGHTS_SEARCH,
  TRACKERS_TABLE_GET,
  TRACKERS_TABLE_EXPORT,
  TRACKERS_MIGRATE,
  TRACKER_SETTINGS_LOCATION_SAVE,
  TRACKER_SETTINGS_LOCATION_DELETE,
} from './actions';
import { PARTNER_CUSTOMERS_GET } from 'modules/menu/redux/actions';

import { initialFilters } from 'modules/trackers/components/SearchFilters/useFilters';
import {
  DEFAULT_TOP_LEFT_COORDS,
  DEFAULT_BOTTOM_RIGHT_COORDS,
  DEFAULT_PRECISION,
} from 'modules/trackers/views/TrackersMainView';

const modal = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case TRACKER_MODAL_OPEN:
      return {
        ...state,
        isOpen: action.isOpen,
      };
    default:
      return state;
  }
};

const initialParams = {
  precision: DEFAULT_PRECISION,
  top_left: DEFAULT_TOP_LEFT_COORDS,
  bottom_right: DEFAULT_BOTTOM_RIGHT_COORDS,
  tracker_id: undefined,
};

const initialSearchParams = (() => {
  const paramsStr = window.localStorage.getItem('searchParams');
  return paramsStr ? JSON.parse(paramsStr) : initialParams;
})();

const searchParams = (state = initialSearchParams, action) => {
  switch (action.type) {
    case SEARCH_PARAMS:
      return action.params;
    default:
      return state;
  }
};

const initialSearchFilters = (() => {
  const filtersStr = window.localStorage.getItem('searchFilters');
  const parsed = JSON.parse(filtersStr) || {};
  window.localStorage.setItem(
    'searchFilters',
    JSON.stringify({
      ...initialFilters,
      ...parsed,
    })
  );
  return {
    ...initialFilters,
    ...parsed,
  };
})();

const searchFilters = (state = initialSearchFilters, action) => {
  switch (action.type) {
    case SEARCH_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

const selectedTracker = (state = null, action) => {
  switch (action.type) {
    case TRACKER_SELECTED:
      return action.tracker ? { ...action.tracker, uiOptions: action.options || {} } : null;
    default:
      return state;
  }
};

const searchCustomers = (state = {}, action) => {
  switch (action.type) {
    case PARTNER_CUSTOMERS_GET + SUCCESS: {
      const params = matchUrl(apiUrls.PARTNER.CUSTOMERS.GET, action.requestAction.endpoint);
      if (params.companyId) {
        return {
          ...state,
          [params.companyId]: action.data?.customers,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export const trackers = combineReducers({
  modal,
  search: combineReducers({
    params: searchParams,
    filters: searchFilters,
    customers: searchCustomers,
    trackers: apiReducer(TRACKERS_LIST, (state, action) => {
      return { ...state, data: action.data, isPagination: action.isPagination };
    }),
    trackerSuggestions: apiReducer(TRACKER_SUGGESTIONS, (state, action) => {
      const data = action.data?.suggestions ?? [];
      let suggestions = [];

      data.forEach((suggestion) => {
        const trackers = suggestion.trackers.map((tracker) => ({ ...tracker, found_by: suggestion.field }));
        suggestions = suggestions.concat(trackers);
      });

      return { ...state, data: suggestions };
    }),
    trackerSuggestion: apiReducer(TRACKER_SUGGESTION, (state, action) => {
      const suggestion = action.data?.suggestions[0].trackers[0];
      return { ...state, data: suggestion };
    }),
  }),
  tracker: combineReducers({
    selected: selectedTracker,
    details: apiReducer(TRACKER_DETAILS),
    exported: apiReducer(TRACKER_EXPORT),
    partner: apiReducer(TRACKER_PARTNER),
    groups: apiReducer(TRACKER_GROUPS_UPDATE),
    bleDevices: combineReducers({
      list: apiReducer(TRACKER_BLE_DEVICES_GET),
    }),
    settings: combineReducers({
      list: apiReducer(TRACKER_SETTINGS_GET),
      saved: createSettingsStateReducer(TRACKER_SETTINGS_SAVE, TRACKER_SETTINGS_DELETE),
      savedLocation: createSettingsStateReducer(TRACKER_SETTINGS_LOCATION_SAVE, TRACKER_SETTINGS_LOCATION_DELETE),
      savedSensors: apiReducer(TRACKER_SENSORS_SETTINGS_SAVE),
    }),
    flights: combineReducers({
      list: apiReducer(TRACKER_FLIGHTS_INFO_GET),
      set: apiReducer(TRACKER_FLIGHTS_INFO_SET),
      deleted: apiReducer(TRACKER_FLIGHTS_INFO_DELETE),
    }),
    channels: combineReducers({
      list: apiReducer(TRACKER_CHATBOT_CHANNELS_GET),
      deleted: apiReducer(TRACKER_CHATBOT_CHANNELS_DELETE),
    }),
    migrate: apiReducer(TRACKERS_MIGRATE),
  }),
  flights: combineReducers({
    search: apiReducer(FLIGHTS_SEARCH),
  }),
  trackersTable: combineReducers({
    export: apiReducer(TRACKERS_TABLE_EXPORT),
    list: apiReducer(TRACKERS_TABLE_GET),
  }),
});
