import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import CreateUserForm from 'modules/menu/components/CreateUserForm';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';

const selector = ({ management }) => {
  const { list, created, deleted } = management.partner.users;
  const isFetching = list.isFetching || created.isFetching || deleted.isFetching;
  return {
    isFetching,
    isCreating: created.isFetching,
    usersList: list.data,
    errors: {
      created: created.error,
      deleted: deleted.error,
    },
  };
};

const ManageUsersView = ({ partner }) => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, usersList, errors } = useSelector(selector);

  useEffect(() => {
    dispatch(managementActions.partner.users.get({ companyId: partner.id }));
    return () => {
      dispatch(managementActions.partner.users.cleanGet());
      dispatch(managementActions.partner.users.cleanCreated());
      dispatch(managementActions.partner.users.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createUser = (values) => {
    dispatch(managementActions.partner.users.create({ companyId: partner.id }, { ...values, is_admin: true }));
  };

  const deleteUser = (userId) => {
    displayConfirmDialog().then(() => {
      dispatch(managementActions.partner.users.delete({ companyId: partner.id, userId }));
    });
  };

  const renderUsersList = (users) => {
    if (users && users.length) {
      return users.map(({ id, email }) => (
        <div key={id} className="manage-list-item">
          <span className="list-item-text">{email}</span>
          <button className="pure-button list-item-remove" onClick={() => deleteUser(id)} />
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no users</div>;
    }
  };

  return (
    <>
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">Users</p>
      </div>
      <CreateUserForm onSubmit={createUser} errors={errors.created} isSubmitting={isCreating} />
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderUsersList(usersList?.users)}
        <Loader loaded={!isFetching} />
      </div>
    </>
  );
};

ManageUsersView.propTypes = {
  partner: PropTypes.object,
};

export default ManageUsersView;
