import React from 'react';
import { useSelector } from 'react-redux';

import CopyInput from 'common/components/CopyInput';
import Loader from 'common/components/Loader';

import { LOADER_COLOR_BLUE } from 'common/components/Loader/consts';

const selector = ({ partners: { apiKeys } }) => ({
  createdKey: {
    data: apiKeys.created.data,
    isFetching: apiKeys.created.isFetching,
  },
});

const CreatedAPIKeys = () => {
  const {
    createdKey: { data, isFetching },
  } = useSelector(selector);

  return (
    <div className="partner-api-keys">
      <p className="partner-api-keys-info">
        Make sure to copy your new Secret Key now. You won’t be able to see it again!
      </p>
      <div className="api-keys-list-item">
        <span className="api-keys-list-item-label">API Key</span>
        <CopyInput value={data?.api_key || ''} />
      </div>
      <div className="api-keys-list-item">
        <span className="api-keys-list-item-label">Secret Key</span>
        <CopyInput value={data?.secret_key || ''} />
      </div>
      <Loader loaded={!isFetching} color={LOADER_COLOR_BLUE} />
    </div>
  );
};

export default CreatedAPIKeys;
