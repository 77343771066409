import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  IoAirplaneOutline,
  IoChatbubbleEllipsesOutline,
  IoDownloadOutline,
  IoListOutline,
  IoSettingsOutline,
  IoOptionsOutline,
  IoBluetoothOutline,
} from 'react-icons/io5';

import { getTrackerModeIcon, getBatteryIcon } from 'modules/trackers/utils';
import { resolveUrl, formatDatetime, fromNow } from 'common/utils';
import { appUrls } from 'urls';

const TrackerDetailsPopup = forwardRef(({ trackerInfo, isAdmin, flightInfo }, ref) => {
  const urlParams = trackerInfo ? { trackerId: trackerInfo.id } : null;
  const trackerData = trackerInfo || {};
  const flightData = flightInfo || {};
  const trackerGroups = (trackerData.groups && trackerData.groups.join(', ')) || '';
  const originalTimezone = flightData.origin_timezone;
  const destinationTimezone = flightData.dest_timezone;

  return (
    <div className="tracker-popup-wrapper">
      <div className="tracker-popup-container" ref={ref}>
        <div className="tracker-popup-main">
          <div className="tracker-popup-header">{trackerData.id}</div>
          <div className="tracker-popup-subheader">
            {`${trackerData.company_name} ${trackerData.vendor_name && `(${trackerData.vendor_name})`} ${
              trackerGroups && '| ' + trackerGroups
            }`}
          </div>
          <div className="tracker-popup-details">
            <div className="tracker-popup-row">
              <div className="tracker-popup-col">ICCID</div>
              <div className="tracker-popup-col">{trackerData.iccid || 'N/A'}</div>
            </div>
            <div className="tracker-popup-row">
              <div className="tracker-popup-col">Battery level</div>
              <div className="tracker-popup-col">
                <span>{trackerData.battery}%</span>
                {getBatteryIcon(trackerData.battery, 23)}
              </div>
            </div>
            <div className="tracker-popup-row">
              <div className="tracker-popup-col">Location update</div>
              <div className="tracker-popup-col">{fromNow(trackerData.located_at)}</div>
            </div>
            <div className="tracker-popup-row">
              <div className="tracker-popup-col">Device update</div>
              <div className="tracker-popup-col">{fromNow(trackerData.updated_at)}</div>
            </div>
            <div className="tracker-popup-row">
              <div className="tracker-popup-col">Status</div>
              <div className="tracker-popup-col">
                <span>{trackerData.system_state}</span>
                {getTrackerModeIcon(trackerData.system_state, 18)}
              </div>
            </div>
          </div>
          <div className="tracker-popup-flight">
            {!flightData.id && (
              <div className="tracker-popup-row">
                <strong>No connected flight</strong>
              </div>
            )}
            {flightData.id && (
              <React.Fragment>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">Flight</div>
                  <div className="tracker-popup-col">
                    {flightData.iata}
                    {flightData.operated_by && ` | ${flightData.operated_by}`}
                  </div>
                </div>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">Status</div>
                  <div className="tracker-popup-col">{flightData.status}</div>
                </div>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">STD</div>
                  <div className="tracker-popup-col">{formatDatetime(flightData.std, 'N/A', originalTimezone)}</div>
                </div>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">ETD</div>
                  <div className="tracker-popup-col">{formatDatetime(flightData.etd, 'N/A', originalTimezone)}</div>
                </div>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">ATD</div>
                  <div className="tracker-popup-col">{formatDatetime(flightData.atd, 'N/A', originalTimezone)}</div>
                </div>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">STA</div>
                  <div className="tracker-popup-col">{formatDatetime(flightData.sta, 'N/A', destinationTimezone)}</div>
                </div>
                <div className="tracker-popup-row">
                  <div className="tracker-popup-col">ETA</div>
                  <div className="tracker-popup-col">{formatDatetime(flightData.eta, 'N/A', destinationTimezone)}</div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="tracker-popup-options">
          <Link
            to={urlParams ? resolveUrl(appUrls.TRACKERS.EXPORT_LOG, urlParams) : '#'}
            className="tracker-popup-options-export"
          >
            <IoDownloadOutline size={22} className="inline-icon" />
          </Link>
          <div className="tracker-popup-options-right">
            <Link to={urlParams ? resolveUrl(appUrls.TRACKERS.DETAILS, urlParams) : '#'}>
              <IoListOutline size={27} className="inline-icon" title="Tracker details" />
            </Link>
            {isAdmin && (
              <React.Fragment>
                <Link to={urlParams ? resolveUrl(appUrls.TRACKERS.CONFIGURATION, urlParams) : '#'}>
                  <IoOptionsOutline size={22} className="inline-icon" title="Tracker configuration" />
                </Link>
                <Link to={urlParams ? resolveUrl(appUrls.TRACKERS.SETTINGS, urlParams) : '#'}>
                  <IoSettingsOutline size={22} className="inline-icon" title="Tracker settings" />
                </Link>
                <Link to={urlParams ? resolveUrl(appUrls.TRACKERS.FLIGHT, urlParams) : '#'}>
                  <IoAirplaneOutline size={22} className="inline-icon" title="Tracker flights" />
                </Link>
                <Link to={urlParams ? resolveUrl(appUrls.TRACKERS.CHANNELS, urlParams) : '#'}>
                  <IoChatbubbleEllipsesOutline size={22} className="inline-icon" title="Tracker channels" />
                </Link>
                <Link to={urlParams ? resolveUrl(appUrls.TRACKERS.BLE_DEVICES, urlParams) : '#'}>
                  <IoBluetoothOutline size={22} className="inline-icon" title="Tracker BLE devices" />
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

TrackerDetailsPopup.propTypes = {
  trackerInfo: PropTypes.object,
  isAdmin: PropTypes.bool,
  flightInfo: PropTypes.object,
};

export default TrackerDetailsPopup;
