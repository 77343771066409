import { MIN_LATITUDE, MAX_LATITUDE } from './consts';

export const limitLatitude = (latitude) => {
  let lat = latitude;

  if (lat < MIN_LATITUDE) lat = MIN_LATITUDE;
  else if (lat > MAX_LATITUDE) lat = MAX_LATITUDE;

  return lat;
};
