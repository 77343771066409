import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ChangePartnerForm from 'modules/trackers/components/ChangePartnerForm';
import ChangeGroupsForm from 'modules/trackers/components/ChangeGroupsForm';
import MigrateTrackerForm from 'modules/trackers/components/MigrateTrackerForm';

import { trackersActions } from 'modules/trackers/redux/actions';
import { managementActions } from 'modules/menu/redux/actions';

const selector = ({ management, trackers }) => {
  const { groups: trackerGroups } = trackers.tracker;
  const groups = management.partner.groups.list;
  const customers = management.partner.customers.list;

  return {
    partners: management.partners.list.data?.partners?.map((item) => ({ label: item.name, value: item.id })) ?? [],
    customers: customers.data?.customers?.map((item) => ({ label: item.name, value: item.id })) ?? [],
    selectedTracker: trackers.tracker.selected,
    search: trackers.search.params,
    partnerError: trackers.tracker.partner.error,
    partnerFetching: trackers.tracker.partner.isFetching,
    migrate: trackers.tracker.migrate,
    groups: groups.data?.groups,
    trackerGroups,
  };
};

const TrackerConfigurationView = ({ match }) => {
  const dispatch = useDispatch();
  const {
    groups,
    customers,
    trackerGroups,
    search,
    selectedTracker,
    partners,
    partnerError,
    partnerFetching,
    migrate,
  } = useSelector(selector);

  useEffect(() => {
    if (selectedTracker) {
      dispatch(managementActions.partner.groups.get({ companyId: selectedTracker.company_id }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGroupsChange = (values) => {
    const payload = { groups: values.groups.map((g) => g.value) };
    dispatch(
      trackersActions.tracker.groups.update({ trackerId: selectedTracker.id }, payload, () => {
        dispatch(
          trackersActions.tracker.setSelectedTracker({ ...selectedTracker, groups: values.groups.map((g) => g.label) })
        );
      })
    );
  };

  const handlePartnerSubmit = ({ partner: { value: partner_id, label: partner_name } }) => {
    const payload = {
      partner_id,
      ids: [parseInt(match.params.trackerId)],
    };

    dispatch(
      trackersActions.tracker.partner.set(payload, () => {
        dispatch(managementActions.partner.groups.get({ companyId: partner_id }));
        //Elastic needs to update data, it takes a while, that is why refetching is postponed
        setTimeout(() => dispatch(trackersActions.search.setSearchParams({ ...search })), 2000);
      })
    );
  };

  const handleTrackerMigrate = (values) => {
    const payload = { ...values };
    payload.env = values.env.value;
    payload.tracker_ids = [selectedTracker.id];
    dispatch(trackersActions.tracker.migrate(payload));
  };

  const trackerGroupsValue = useMemo(() => {
    return groups?.length && selectedTracker?.groups
      ? selectedTracker?.groups.map((groupName) => groups?.find(({ name }) => name === groupName))
      : [];
  }, [selectedTracker, groups]);

  return (
    <>
      <ChangePartnerForm
        partners={partners}
        tracker={selectedTracker}
        onSubmit={handlePartnerSubmit}
        customers={customers}
        error={partnerError}
        isSubmitting={partnerFetching}
      />
      <hr className="divider" />
      <ChangeGroupsForm
        groups={groups?.map(({ id: value, name: label }) => ({ label, value }))}
        tracker={selectedTracker}
        onSubmit={handleGroupsChange}
        error={trackerGroups?.error}
        value={trackerGroupsValue?.map((group) => ({ label: group.name, value: group.id }))}
        isSubmitting={trackerGroups?.isFetching}
      />
      <hr className="divider" />
      <MigrateTrackerForm onSubmit={handleTrackerMigrate} error={migrate.error} isSubmitting={migrate.isFetching} />
    </>
  );
};

TrackerConfigurationView.propTypes = {
  match: PropTypes.object,
};

export default TrackerConfigurationView;
