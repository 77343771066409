import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SetPasswordForm from 'modules/auth/components/SetPasswordForm';
import { authActions } from 'modules/auth/redux/actions';
import { appUrls } from 'urls';

const selector = ({ auth }) => ({
  error: auth.setPassword.error,
  isFetching: auth.setPassword.isFetching,
});

const ActivateAccountView = ({ location }) => {
  const { error, isFetching } = useSelector(selector);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(authActions.setPassword({ password: values.password, token: location.state.token }));
  };

  if (!location.state) {
    return <Redirect to={appUrls.ROOT} />;
  }

  return (
    <div className="center-text">
      <div className="auth-form">
        <h1>Activate account</h1>
        <p>Please set your password.</p>
        <SetPasswordForm onSubmit={handleSubmit} error={error} isSubmitting={isFetching} />
      </div>
    </div>
  );
};

ActivateAccountView.propTypes = {
  location: PropTypes.object,
};

export default ActivateAccountView;
