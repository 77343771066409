import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';

import Portal from 'base/containers/Portal';
import Arrow from 'common/components/Arrow';

const Modal = forwardRef(
  ({ isOpen, isAutoSize, onClose, onBack, onAnimateExited, headerContent, children, modalContainerClass }, ref) => {
    const modalContentRef = ref || React.createRef();
    const transitions = useTransition(isOpen, {
      from: { transform: 'translateX(-100%)' },
      enter: { transform: 'translateX(0%)' },
      leave: { transform: 'translateX(-100%)' },
      config: { tension: 300, friction: 32 },
      expires: onAnimateExited,
    });

    const modalClass = cx('modal', modalContainerClass, {
      'auto-size': isAutoSize,
    });

    return (
      <Portal>
        {transitions(
          (style, item, { key }) =>
            item && (
              <animated.div key={key} style={style} className={modalClass} data-testid="menu">
                <div className="modal-header">
                  {onBack && (
                    <button className="modal-back pure-button" onClick={onBack}>
                      <Arrow direction="left" size={16} />
                      <span className="modal-back-text">Back</span>
                    </button>
                  )}
                  <button className="modal-close pure-button" data-testid="modalClose" onClick={onClose} />
                  {headerContent}
                </div>
                <div className="modal-content">
                  <PerfectScrollbar className="scrollbar-white" containerRef={(ref) => (modalContentRef.current = ref)}>
                    {children}
                  </PerfectScrollbar>
                </div>
              </animated.div>
            )
        )}
      </Portal>
    );
  }
);

Modal.propTypes = {
  isOpen: PropTypes.bool,
  isAutoSize: PropTypes.bool,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  onAnimateExited: PropTypes.func,
  headerContent: PropTypes.node,
  children: PropTypes.node,
  modalContainerClass: PropTypes.string,
};

export default Modal;
