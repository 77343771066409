import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';
import Loader from 'common/components/Loader';

import { managementActions } from 'modules/menu/redux/actions';

const selector = ({ management }) => {
  const created = management.partners.created;
  return {
    isFetching: created.isFetching,
    error: created.error,
  };
};

const PartnerCreateView = () => {
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(managementActions.partners.cleanCreated());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createPartner = (values) => {
    dispatch(managementActions.partners.create(values));
  };

  return (
    <div className="create-partner-form-outer">
      <p className="create-partner-header">Create partner</p>
      <Form onSubmit={createPartner} errors={error}>
        <Input name="name" placeholder="Type partner name..." required />
        <Button type="submit" disabled={isFetching}>
          Create
        </Button>
      </Form>
      <Loader loaded={!isFetching} />
    </div>
  );
};

export default PartnerCreateView;
