// @ts-nocheck

import React, { ForwardedRef, forwardRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { SettingsControl } from './SettingsControl';
import Form from 'common/components/Form';
import Input from 'common/components/Input';
import Select from 'common/components/Select';

import { formatFirmwareOption, generalSettingsFields, getValidationErrors, parseSettingsValue } from './utils';
import { ChangedFieldType, SettingsFormProps } from './';

type GeneralSettingsFormProps = Omit<SettingsFormProps, 'onRestore' | 'onModelChange' | 'sensorsSettingsStatus'>;

const selector = ({ management: { firmwares } }) => ({
  firmwares: firmwares.list.data?.firmwares || [],
});

export const GeneralSettingsForm = forwardRef(
  (
    {
      settings,
      defaultSettings,
      onSubmit,
      isFetching,
      generalSettingStatus,
      isMainSettingsLevel,
      trackerModel,
    }: GeneralSettingsFormProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const { firmwares } = useSelector(selector);
    const { isSubmitting, isSuccess, error } = generalSettingStatus;
    const firmwareValue = settings?.firmware ?? defaultSettings?.firmware;
    const scanBleDevicesValue = settings?.scan_ble_devices; // === null ? "" : settings?.scan_ble_devices  ;

    useEffect(() => {
      ref.current?.updateInputsWithError(error);
    }, [JSON.stringify(error)]); // eslint-disable-line react-hooks/exhaustive-deps

    const firmwareOptions = useMemo(() => {
      const firmwareOptions = trackerModel ? firmwares.filter((fw) => trackerModel.startsWith(fw.model)) : firmwares;
      return firmwareOptions.map((f) => formatFirmwareOption(f));
    }, [trackerModel, firmwares]);

    const handleSubmit = (changedField: ChangedFieldType) => {
      const fieldName = Object.keys(changedField)[0];
      const formValues = ref.current?.getCurrentValues();
      const allValues = {};
      const payload = { [fieldName]: parseSettingsValue(changedField[fieldName]) };

      Object.keys(formValues).forEach((key) => (allValues[key] = parseSettingsValue(formValues[key])));
      allValues.firmware_id = formValues.firmware_id?.value;
      if (changedField.hasOwnProperty('scan_ble_devices')) {
        allValues.scan_ble_devices = changedField.scan_ble_devices === undefined ? null : changedField.scan_ble_devices;
      }

      onSubmit({ payload, allValues });
    };

    return (
      <Form ref={ref}>
        <SettingsControl label="Firmware">
          <Select
            name="firmware_id"
            options={firmwareOptions}
            value={firmwareValue && formatFirmwareOption(firmwareValue)}
            placeholder={firmwareValue && formatFirmwareOption(firmwareValue)?.label}
            required={isMainSettingsLevel}
            disabled={isFetching}
            isLoading={isSubmitting['firmware_id'] || isFetching}
            successIndicator={isSuccess['firmware_id']}
            onChange={handleSubmit}
          />
        </SettingsControl>
        <SettingsControl label="Scan BLE devices">
          <Select
            name="scan_ble_devices"
            isClearable={!isMainSettingsLevel}
            required={isMainSettingsLevel}
            options={[
              { value: true, label: 'Enabled' },
              { value: false, label: 'Disabled' },
            ]}
            value={[
              { value: true, label: 'Enabled' },
              { value: false, label: 'Disabled' },
            ].find((item) => item.value === scanBleDevicesValue)}
            placeholder="Scan BLE devices"
            disabled={isFetching}
            isLoading={isSubmitting['scan_ble_devices'] || isFetching}
            successIndicator={isSuccess['scan_ble_devices']}
            onChange={handleSubmit}
          />
        </SettingsControl>
        {generalSettingsFields.map(({ name, label, validators }) => (
          <SettingsControl key={name} label={label}>
            <Input
              type="number"
              name={name}
              placeholder={defaultSettings?.[name]}
              value={settings?.[name]}
              validations={validators.map((v) => v.rule).join()}
              validationErrors={getValidationErrors(validators)}
              required={isMainSettingsLevel}
              disabled={isFetching}
              isLoading={isSubmitting[name] || isFetching}
              successIndicator={isSuccess[name]}
              onBlur={handleSubmit}
            />
          </SettingsControl>
        ))}
      </Form>
    );
  }
);
