import { useRef } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cx from 'classnames';
import { IoCheckmarkSharp } from 'react-icons/io5';

import Loader from 'common/components/Loader';

const AbstractInput = (props) => <input {...props} />;

const FileInput = ({
  className,
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  disabled,
  hidden,
  autoFocus,
  readOnly,
}) => {
  const fileInputRef = useRef();

  const fileListToString = (fileList) => {
    if (!fileList || !(fileList instanceof FileList)) {
      return '';
    }

    return [...fileList].map((file) => file.name).join(', ');
  };

  return (
    <>
      <input
        className={[className, 'vrs-file-input'].join(' ')}
        type="text"
        value={fileListToString(value)}
        placeholder={placeholder}
        autoFocus={autoFocus}
        hidden={hidden}
        readOnly={readOnly}
        onClick={(event) => {
          event.target.blur();
          fileInputRef.current.click();
        }}
      />
      <input
        ref={fileInputRef}
        name={name}
        disabled={disabled}
        type="file"
        style={{ display: 'none' }}
        onChange={onChange}
        onBlur={onBlur}
      />
    </>
  );
};

const Input = ({
  type,
  placeholder,
  autofocus,
  disabled,
  hidden,
  isLoading,
  successIndicator,
  onChange,
  onBlur,
  className,
  wrapperClassName,
  name,
  value,
  setValue,
  errorMessage,
  showRequired,
  isPristine,
  isValid,
  min,
  max,
  maxLength,
  readOnly,
}) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    const inputFiles = event.target.files;

    let value = inputValue;

    if (event.target.type === 'file') {
      value = inputFiles;
    }

    onChange && onChange(value);
    setValue(value);
  };

  const handleBlur = (event) => {
    if (onBlur && isValid) {
      onBlur({ [name]: event.target.value });
    }
  };

  const error = errorMessage || (!isPristine && showRequired && 'This field is required');
  const inputWrapperClassName = cx('vrs-input-box', wrapperClassName);
  const inputClassName = cx('vrs-input', className);
  const showIcons = successIndicator || isLoading;

  const inputProps = {
    className: inputClassName,
    type: type,
    name: name,
    min: min,
    max: max,
    maxLength: maxLength,
    value: value ?? '',
    placeholder: placeholder,
    onChange: handleChange,
    onBlur: handleBlur,
    autoFocus: autofocus,
    disabled: disabled,
    hidden: hidden,
    readOnly: readOnly,
  };

  return (
    <div className={inputWrapperClassName}>
      {{
        file: <FileInput {...inputProps} />,
      }[type] || <AbstractInput {...inputProps} />}
      {error && <p className="vrs-error">{error}</p>}
      {showIcons && (
        <div className="vrs-input-icons">
          <Loader color="#123363" width={2} radius={3} length={3} lines={7} loaded={!isLoading} />
          {successIndicator && <IoCheckmarkSharp title="Success" className="inline-icon vrs-input-success" />}
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  successIndicator: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  // Formsy props
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool,
  isPristine: PropTypes.bool,
  isValid: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
};

export default withFormsy(Input);
