import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import Modal from 'common/components/Modal';

import { menuActions } from 'modules/menu/redux/actions';
import { trackersActions } from 'modules/trackers/redux/actions';
import { usePrevious } from 'common/effects/usePrevious';
import { resolveUrl } from 'common/utils';
import { appUrls } from 'urls';

const selector = ({ menu, trackers }) => ({
  isMenuModalOpen: menu.modal.isOpen,
  isTrackersListModalOpen: trackers.modal.isOpen,
});

const TrackerModalContainer = ({ view: View, header, match }) => {
  const [isOpen, setIsOpen] = useState(true);
  const dispatch = useDispatch();
  const { isMenuModalOpen, isTrackersListModalOpen } = useSelector(selector);
  const prevIsMenuModalOpen = usePrevious(isMenuModalOpen);
  const prevIsTrackersListModalOpen = usePrevious(isTrackersListModalOpen);

  useEffect(() => {
    dispatch(menuActions.closeMenu());
    dispatch(trackersActions.modal.close());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      (prevIsMenuModalOpen === false && isMenuModalOpen) ||
      (prevIsTrackersListModalOpen === false && isTrackersListModalOpen)
    ) {
      setIsOpen(false);
    }
  }, [isMenuModalOpen, isTrackersListModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeUrl = () => {
    dispatch(push(resolveUrl(appUrls.TRACKERS.BASE, match.params)));
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} onAnimateExited={changeUrl} headerContent={header}>
      <View match={match} />
    </Modal>
  );
};

TrackerModalContainer.propTypes = {
  view: PropTypes.func,
  header: PropTypes.string,
  match: PropTypes.object,
};

export default TrackerModalContainer;
