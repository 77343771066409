import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IoCopyOutline } from 'react-icons/io5';

const CopyInput = ({ value, className }) => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      let textField = document.createElement('textarea');

      document.body.appendChild(textField);
      textField.innerText = value;
      textField.select();
      document.execCommand('copy');
      textField.remove();

      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [isCopied]); // eslint-disable-line react-hooks/exhaustive-deps

  const containerClassName = cx('copy-input', {
    [className]: className,
  });
  const tooltipClassName = cx('copy-input-tooltip', {
    'input-tooltip-show': isCopied,
  });

  return (
    <div className={containerClassName} data-testid="copy-input-container">
      <input name="key" className="vrs-input" value={value} disabled />
      <IoCopyOutline
        title="copy to clipboard"
        size={22}
        onClick={() => setCopied(true)}
        className="inline-icon copy-icon"
        role="button"
      />
      <p className={tooltipClassName}>Copied to clipboard.</p>
    </div>
  );
};

CopyInput.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
};

export default CopyInput;
