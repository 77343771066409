import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';

import TableHeaders from './TableHeaders';
import TableRow from './TableRow';
import TableEmpty from './TableEmpty';

const TrackersTable = ({ requestSort, filters, setFilters, tableData, trackers, onEditTracker }) => {
  return (
    <div className="trackers-table">
      <PerfectScrollbar className="scrollbar-white">
        <table>
          <thead>
            <TableHeaders requestSort={requestSort} filters={filters} setFilters={setFilters} tableData={tableData} />
          </thead>
          <tbody>
            {trackers.length ? (
              trackers.map((item) => <TableRow key={item.id} onEdit={() => onEditTracker(item)} {...item} />)
            ) : (
              <TableEmpty colSpan={Object.keys(tableData)?.length}>Nothing was found, try again!</TableEmpty>
            )}
          </tbody>
        </table>
      </PerfectScrollbar>
    </div>
  );
};

TrackersTable.propTypes = {
  requestSort: PropTypes.func,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  onEditTracker: PropTypes.func,
  tableData: PropTypes.object,
  trackers: PropTypes.array,
};

export default TrackersTable;
