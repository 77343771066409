import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import ChangeEmailForm from 'modules/account/components/ChangeEmailForm';

import { accountActions } from 'modules/account/redux/actions';

const selector = ({ account: { changedEmail } }) => ({
  isFetching: changedEmail.isFetching,
  error: changedEmail.error,
  data: changedEmail.data,
});

const ChangeEmailView = ({ isPartner }) => {
  const { isFetching, error, data } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(accountActions.clearChangedEmail());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values) => {
    dispatch(accountActions.changeEmail(values));
  };

  return (
    <div className="change-email">
      <h1 className="change-email-header">Change e-mail</h1>
      {!data ? (
        <ChangeEmailForm
          onSubmit={handleSubmit}
          error={error}
          data={data}
          isSubmitting={isFetching}
          isPartner={isPartner}
        />
      ) : (
        <p className="mt-15">We have sent you an email where we provide a link to activate your new address.</p>
      )}
      <Loader loaded={!isFetching} />
    </div>
  );
};

ChangeEmailView.propTypes = {
  isPartner: PropTypes.bool,
};

export default ChangeEmailView;
