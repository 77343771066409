import React from 'react';
import PropTypes from 'prop-types';

const BaseLayout = ({ children }) => {
  return (
    <div className="layout">
      <div className="container">{children}</div>
    </div>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node,
};

export default BaseLayout;
