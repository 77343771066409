import React from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/Pagination';
import TrackerListItem from './TrackerListItem';

const TrackersList = ({ trackers, pageCount, currentPage, onPageChange, onItemClick }) => {
  const devices = (trackers && trackers.devices) || [];
  const areTrackers = !!devices.length;

  return (
    <div className="trackers-list-outer">
      <div className="trackers-list">
        {areTrackers ? (
          devices.map((tracker, index) => (
            <TrackerListItem key={index} index={index} tracker={tracker} onTrackerClick={onItemClick} />
          ))
        ) : (
          <span>There are no trackers</span>
        )}
      </div>
      {areTrackers && <Pagination pageCount={pageCount} forcePage={currentPage} onPageChange={onPageChange} />}
    </div>
  );
};

TrackersList.propTypes = {
  trackers: PropTypes.object,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onItemClick: PropTypes.func,
};

export default TrackersList;
