import React from 'react';
import PropTypes from 'prop-types';
import { Route as ReactRoute, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { appUrls } from 'urls';
import Loader from 'common/components/Loader';

const Route = ({ component: Component, layout: Layout, authenticationRequired, session, ...rest }) => {
  const { isInitial, isLogged, isAuthenticated, missing2FASetup, missing2FACode } = session;

  const renderRoute = (matchProps) => {
    if (isAuthenticated || !authenticationRequired) {
      return Layout ? (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      ) : (
        <Component {...matchProps} />
      );
    } else {
      if (!isLogged) return <Redirect to={appUrls.AUTH.SIGN_IN} />;
      else if (missing2FACode) return <Redirect to={appUrls.AUTH.VERIFY_CODE} />;
      else if (missing2FASetup) return <Redirect to={appUrls.AUTH.SETUP_2FA} />;
    }
  };

  if (isInitial) {
    return <Loader isLoading={isInitial} overlay />;
  }

  return <ReactRoute {...rest} render={(matchProps) => renderRoute(matchProps)} />;
};

Route.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  authenticationRequired: PropTypes.bool,
  // Redux props
  session: PropTypes.object,
};

const mapStateToProps = ({ account }) => {
  return {
    session: account.session,
  };
};

export default connect(mapStateToProps)(Route);
