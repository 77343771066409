import { put } from 'redux-saga/effects';

import { apiAction } from 'base/redux/utils';
import { resolveUrl } from 'common/utils';
import { apiUrls } from 'urls';
import { CLEAR } from 'base/redux/consts';

export const FETCH_API_KEYS_LIST = 'FETCH_API_KEYS_LIST';
export const CREATE_API_KEY = 'CREATE_API_KEY';
export const DELETE_API_KEY = 'DELETE_API_KEY';

export const partnersActions = {
  apiKeys: {
    getList: (companyId) => apiAction(FETCH_API_KEYS_LIST, 'GET', resolveUrl(apiUrls.PARTNER.KEYS.GET, { companyId })),
    create: (companyId, payload) =>
      apiAction(CREATE_API_KEY, 'POST', resolveUrl(apiUrls.PARTNER.KEYS.CREATE, { companyId }), payload, {
        afterSagaSuccess: function* () {
          yield put(partnersActions.apiKeys.getList(companyId));
        },
      }),
    delete: (companyId, keyId) =>
      apiAction(DELETE_API_KEY, 'DELETE', resolveUrl(apiUrls.PARTNER.KEYS.DELETE, { companyId, keyId }), null, {
        afterSagaSuccess: function* () {
          yield put(partnersActions.apiKeys.getList(companyId));
        },
      }),
    cleanCreated: () => ({ type: CREATE_API_KEY + CLEAR }),
  },
};
