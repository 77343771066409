import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const CreateBLEFilterForm = ({ onSubmit, errors, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="manage-content-control">
        <div className="row mb-15">
          <div className="settings-item-label">
            <p>Filter alias</p>
          </div>
          <div className="settings-item-controls">
            <Input type="text" name="alias" maxLength={5} placeholder="BLE filter alias e.g. rlb" required />
          </div>
        </div>
        <div className="row mb-15">
          <div className="settings-item-label">
            <p>Scan duration [s]</p>
          </div>
          <div className="settings-item-controls">
            <Input
              type="number"
              name="scan_duration"
              min={0}
              max={900}
              placeholder="Scan duration e.g. 300s"
              required
            />
          </div>
        </div>
        <div className="row mb-15">
          <div className="gr-grow">
            <p className="mb-15">Filter data</p>
            <Input type="text" name="data" placeholder="Data to filter e.g. A0B100200304" required />
          </div>
        </div>
        <div className="row mb-15">
          <div className="gr-grow">
            <p className="mb-15">Script</p>
            <Input type="file" name="script" placeholder="(Optional) .lua file" accept="*.lua" />
          </div>
        </div>
        <div className="row gr-grow">
          <div className="gr-grow" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="submit" disabled={isSubmitting}>
              Create
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

CreateBLEFilterForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  initialValues: PropTypes.object,
};

export default CreateBLEFilterForm;
