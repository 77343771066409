import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import VerifyTwoFactorForm from 'modules/auth/components/VerifyTwoFactorForm';
import TwoFactorCode from 'common/components/TwoFactorCode';

import { authActions } from 'modules/auth/redux/actions';
import { appUrls } from 'urls';

const selector = ({ account, auth: { codeVerification, setupTwoFactor } }) => ({
  missing2FASetup: account.session.missing2FASetup,
  setupTwoFactor,
  codeVerification,
});

const SetupTwoFactorView = () => {
  const { missing2FASetup, setupTwoFactor, codeVerification } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    missing2FASetup && dispatch(authActions.setUpTwoFactor());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCodeVerify = (values) => {
    dispatch(authActions.verifyCode(values));
  };

  if (missing2FASetup) {
    return (
      <div className="center-text">
        <div className="auth-form">
          <div>
            <h1>Set up 2FA</h1>
            <p>
              Two factor authentication adds an extra layer of security to your account. Once enabled, each time you
              login you will also be prompted to enter a code which is generated by two-step verification app (e.g.
              Google Authenticator).
            </p>
          </div>
          <TwoFactorCode
            secretKey={setupTwoFactor.data?.secret}
            qrLink={setupTwoFactor.data?.url}
            error={setupTwoFactor.error}
            isLoading={setupTwoFactor.isFetching}
          />
          <p className="mb-15">To confirm the secret, enter the 6-digit code from the app.</p>
          <VerifyTwoFactorForm
            onSubmit={handleCodeVerify}
            error={codeVerification.error}
            isSubmitting={codeVerification.isFetching}
          />
          <Loader loaded={!codeVerification.isFetching} />
        </div>
      </div>
    );
  } else {
    return <Redirect to={appUrls.ROOT} />;
  }
};

export default SetupTwoFactorView;
