import { call, put, takeEvery } from 'redux-saga/effects';

import { request, getError } from 'common/utils';
import { REQUEST, SUCCESS, FAILURE } from 'base/redux/consts';
import { accountActions } from 'modules/account/redux/actions';

/**
 * Method creates saga for API request.
 * @param {string} type - The type of action.
 * @param {boolean} signOutOnUnauthorized - The indicator to sign out when 401 error occurs.
 */
export const apiSaga = (type, signOutOnUnauthorized = true) => {
  function* callApi(action) {
    try {
      const { data } = yield call(request, action.method, action.endpoint, action.payload);
      yield put({
        type: type + SUCCESS,
        data: data || {},
        requestAction: action,
      });
      if (action.options && action.options.afterSagaSuccess) {
        yield call(action.options.afterSagaSuccess, data);
      }
    } catch (error) {
      const formattedError = getError(error);
      yield put({
        type: type + FAILURE,
        error: formattedError,
        requestAction: action,
      });
      if (signOutOnUnauthorized && error.response?.status === 401) {
        yield put(accountActions.clearSession());
      }
      if (action.options && action.options.afterSagaFailure) {
        yield call(action.options.afterSagaFailure, formattedError);
      }
    } finally {
      if (action.options && action.options.afterSaga) {
        yield call(action.options.afterSaga);
      }
    }
  }

  return function* () {
    yield takeEvery(type + REQUEST, callApi);
  };
};
