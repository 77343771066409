import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import TrackerBleDevices from 'modules/trackers/components/TrackerBleDevices';

import { trackersActions } from 'modules/trackers/redux/actions';

const selector = ({ trackers }) => ({
  bleDevices: trackers.tracker.bleDevices.list,
});

const TrackerBleDevicesView = ({ match }) => {
  const dispatch = useDispatch();
  const { bleDevices } = useSelector(selector);

  const handleGetBleDevices = (query) => {
    dispatch(trackersActions.tracker.bleDevices.get(match.params, query));
  };

  return (
    <div className="tracker-details-outer">
      <TrackerBleDevices bleDevices={bleDevices.data} getBleDevices={handleGetBleDevices} />
      <Loader loaded={!bleDevices.isFetching} />
    </div>
  );
};

TrackerBleDevicesView.propTypes = {
  match: PropTypes.object,
};

export default TrackerBleDevicesView;
