import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

const TableDateFilter = ({ onFilter, inputName }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDateChange = ({ startDate, endDate }) => {
    onFilter(
      startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate ? moment(endDate).format('YYYY-MM-DD') : null
    );
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <DateRangePicker
      startDate={startDate}
      startDateId={`${inputName}-start-date`}
      endDate={endDate}
      endDateId={`${inputName}-end-date`}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
      onDatesChange={handleDateChange}
      endDatePlaceholderText="End date"
      startDatePlaceholderText="Start date"
      displayFormat="DD-MM-YYYY"
      isOutsideRange={() => false}
      minimumNights={0}
      customArrowIcon={<span>-</span>}
      hideKeyboardShortcutsPanel={true}
      showClearDates={true}
    />
  );
};

TableDateFilter.propTypes = {
  onFilter: PropTypes.func,
  inputName: PropTypes.string,
};

export default TableDateFilter;
