import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import CreateTCCAdminForm from 'modules/menu/components/CreateTCCAdminForm';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';

const selector = ({ management }) => {
  const { list, created, deleted } = management.partner.tccAdmins;
  const isFetching = list.isFetching || created.isFetching || deleted.isFetching;
  return {
    isFetching,
    isCreating: created.isFetching,
    tccAdminsList: list.data,
    errors: {
      created: created.error,
      deleted: deleted.error,
    },
  };
};

const ManageTCCAdminView = ({ partner }) => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, tccAdminsList, errors } = useSelector(selector);

  useEffect(() => {
    dispatch(managementActions.partner.tccAdmins.get({ companyId: partner.id }));
    return () => {
      dispatch(managementActions.partner.tccAdmins.cleanGet());
      dispatch(managementActions.partner.tccAdmins.cleanCreated());
      dispatch(managementActions.partner.tccAdmins.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createTCCAdmin = (values) => {
    dispatch(managementActions.partner.tccAdmins.create({ companyId: partner.id }, values));
  };

  const deleteTCCAdmin = (username) => {
    displayConfirmDialog().then(() => {
      dispatch(managementActions.partner.tccAdmins.delete({ companyId: partner.id, username }));
    });
  };

  const renderTCCAdminsList = (admins) => {
    if (admins && admins.length) {
      return admins.map(({ Attributes, Username }) => (
        <div key={Attributes[0].Value} className="manage-list-item">
          <span className="list-item-text">{Username}</span>
          <button className="pure-button list-item-remove" onClick={() => deleteTCCAdmin(Username)} />
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no TCC admins</div>;
    }
  };

  return (
    <>
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">TCC admins</p>
      </div>
      <CreateTCCAdminForm onSubmit={createTCCAdmin} errors={errors.created} isSubmitting={isCreating} />
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderTCCAdminsList(tccAdminsList?.Users)}
        <Loader loaded={!isFetching} />
      </div>
    </>
  );
};

ManageTCCAdminView.propTypes = {
  partner: PropTypes.object,
};

export default ManageTCCAdminView;
