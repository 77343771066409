import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { menuActions } from 'modules/menu/redux/actions';
import { getUnauthorizedError } from 'common/utils/error';
import { apiAction } from 'base/redux/utils';
import { appUrls, apiUrls } from 'urls';
import { CLEAR } from 'base/redux/consts';
import { menuPaths } from 'modules/menu/consts';

export const SET_SESSION = 'SET_SESSION';
export const CLEAR_SESSION = 'CLEAR_SESSION';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_2FA = 'CHANGE_2FA';

export const accountActions = {
  setSession: (options) => ({ type: SET_SESSION, options }),
  clearSession: () => ({ type: CLEAR_SESSION }),
  setUser: (user) => ({ type: SET_USER, data: user }),
  getUser: (isInitial) =>
    apiAction(GET_USER, 'GET', apiUrls.ACCOUNT.USER, null, {
      afterSagaSuccess: function* ({ is_active, is_two_factor }) {
        if (isInitial) {
          const payload = is_active && is_two_factor && { isAuthenticated: true };
          yield put(accountActions.setSession(payload));
        }
      },
      afterSagaFailure: function* (error) {
        if (isInitial) {
          const unauthorizedError = getUnauthorizedError(error.error);
          const { missing2FASetup, missing2FACode } = unauthorizedError;
          yield put(accountActions.setSession({ ...unauthorizedError }));

          if (missing2FASetup || missing2FACode) {
            yield put(accountActions.getAuthUser());
          }
        }
      },
    }),
  getAuthUser: () => apiAction(GET_USER, 'GET', apiUrls.AUTH.USER),
  changePassword: (payload) => apiAction(CHANGE_PASSWORD, 'POST', apiUrls.ACCOUNT.CHANGE_PASSWORD, payload),
  clearChangedPassword: () => ({ type: CHANGE_PASSWORD + CLEAR }),
  changeEmail: (payload) => apiAction(CHANGE_EMAIL, 'POST', apiUrls.ACCOUNT.CHANGE_EMAIL, payload),
  clearChangedEmail: () => ({ type: CHANGE_EMAIL + CLEAR }),
  changeTwoFactor: (payload) =>
    apiAction(CHANGE_2FA, 'POST', apiUrls.ACCOUNT.CHANGE_2FA, payload, {
      afterSagaSuccess: function* () {
        yield put(accountActions.setSession({ missing2FASetup: true }));
        yield put(menuActions.closeMenu());
        yield put(menuActions.setPath(menuPaths.HOME));
        yield put(push(appUrls.AUTH.SETUP_2FA));
      },
    }),
  clearChangedTwoFactor: () => ({ type: CHANGE_2FA + CLEAR }),
};
