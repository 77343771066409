import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import Arrow from 'common/components/Arrow';
import TableDateFilter from './TableDateFilter';

import { TRACKERS_DATA_TABLE } from 'modules/trackers/consts';

const TableHeaders = ({ requestSort, filters, setFilters, tableData }) => {
  const handleFilters = debounce((value, key) => {
    const newFilters = { ...filters };
    newFilters[key] = value?.trim();
    setFilters(newFilters);
  }, 300);

  const handleDateFilters = debounce((startDate, endDate, key) => {
    const newFilters = { ...filters };
    newFilters[`${key}.gte`] = startDate;
    newFilters[`${key}.lte`] = endDate;
    setFilters(newFilters);
  }, 300);

  const renderFilter = (key, tracker) => {
    return tracker.filterable ? (
      [TRACKERS_DATA_TABLE.produced_at.header, TRACKERS_DATA_TABLE.updated_at.header].includes(tracker.header) ? (
        <TableDateFilter
          onFilter={(startDate, endDate) => handleDateFilters(startDate, endDate, key)}
          inputName={key}
        />
      ) : (
        <input
          className="vrs-input trackers-table-headers-input"
          type="search"
          value={filters?.key}
          onChange={(e) => handleFilters(e.target.value, key)}
        />
      )
    ) : null;
  };

  return (
    <tr>
      {Object.entries(tableData).map(([key, tracker]) => {
        const direction = tracker.sortable && (tracker.direction === 'ascending' ? 'up' : 'down');

        return (
          <th key={key} className="trackers-table-headers-item">
            {direction ? (
              <button className="pure-button trackers-table-headers-title" onClick={() => requestSort(key)}>
                <span className="trackers-table-headers-text">{tracker.header}</span>
                {tracker.direction && <Arrow direction={direction} size={8} />}
              </button>
            ) : (
              <div className="trackers-table-headers-title">
                <span className="trackers-table-headers-text">{tracker.header}</span>
              </div>
            )}
            {renderFilter(key, tracker)}
          </th>
        );
      })}
    </tr>
  );
};

TableHeaders.propTypes = {
  requestSort: PropTypes.func,
  tableData: PropTypes.object,
  filters: PropTypes.object,
  setFilters: PropTypes.func,
};

export default TableHeaders;
