import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactSelect from 'react-select';
import cx from 'classnames';
import { withFormsy } from 'formsy-react';
import { isObjectLike } from 'lodash';
import { IoCheckmarkSharp } from 'react-icons/io5';

import Loader from 'common/components/Loader';
import { handleKeyDown, SelectStyles } from 'common/components/Select/utils';

const IndicatorsContainer = (showIcons, successIndicator, isLoading) => (innerProps) => {
  return (
    <>
      {showIcons ? (
        <div className="select-icons">
          <Loader color="#123363" width={2} radius={3} length={3} lines={7} loaded={!isLoading} />
          {successIndicator && <IoCheckmarkSharp title="success" className="inline-icon select-success" />}
        </div>
      ) : (
        innerProps.children
      )}
    </>
  );
};
const MenuList = (innerProps) => {
  return <PerfectScrollbar className="select-menu-list">{innerProps.children}</PerfectScrollbar>;
};

const Select = ({
  options,
  placeholder,
  onChange,
  disabled,
  isLoading,
  isClearable,
  successIndicator,
  className,
  name,
  value,
  setValue,
  errorMessage,
  showRequired,
  defaultValue,
  isPristine,
}) => {
  const selectRef = useRef();
  const changeValue = (option) => {
    const value = isObjectLike(options[0]) ? option : option?.value;
    onChange && onChange({ [name]: option?.value });
    setValue(value);
  };

  const error = errorMessage || (!isPristine && showRequired && 'This field is required');
  const showIcons = successIndicator || isLoading;
  const wrapperClasses = cx(
    'select-container',
    {
      'select-success': successIndicator,
    },
    className
  );

  return (
    <div className={wrapperClasses} data-testid="select-wrapper">
      <ReactSelect
        ref={selectRef}
        styles={SelectStyles}
        name={name}
        options={options}
        isClearable={isClearable}
        defaultValue={defaultValue}
        hideSelectedOptions={true}
        components={{
          MenuList: MenuList,
          IndicatorsContainer: IndicatorsContainer(showIcons, successIndicator, isLoading),
        }}
        value={value ?? ''}
        placeholder={placeholder}
        isDisabled={disabled}
        menuPortalTarget={document.getElementById('portal')}
        onChange={changeValue}
        onKeyDown={(event) => selectRef.current && handleKeyDown(selectRef.current.select, options, event)}
      />
      {error && <p className="vrs-error">{error}</p>}
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.object,
  isClearable: PropTypes.bool,
  successIndicator: PropTypes.bool,
  className: PropTypes.string,
  // Formsy props
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool,
  isPristine: PropTypes.bool,
};

export default withFormsy(Select);
