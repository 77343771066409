import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { resolveUrl } from 'common/utils';
import { apiAction } from 'base/redux/utils';
import { CLEAR } from 'base/redux/consts';
import { accountActions } from 'modules/account/redux/actions';
import { menuActions } from 'modules/menu/redux/actions';
import { appUrls, apiUrls } from 'urls';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const VERIFY_CODE = 'VERIFY_CODE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SETUP_TWO_FACTOR = 'SETUP_TWO_FACTOR';
export const CONFIRM_NEW_EMAIL = 'CONFIRM_NEW_EMAIL';

export const authActions = {
  signIn: (payload) =>
    apiAction(SIGN_IN, 'POST', apiUrls.AUTH.SIGN_IN, payload, {
      afterSagaSuccess: function* (data) {
        yield put(accountActions.setUser(data));
        if (data?.is_two_factor) {
          yield put(accountActions.setSession({ missing2FACode: true }));
          yield put(push(appUrls.AUTH.VERIFY_CODE));
        } else {
          yield put(accountActions.setSession({ missing2FASetup: true }));
          yield put(push(appUrls.AUTH.SETUP_2FA));
        }
      },
    }),
  signOut: () =>
    apiAction(SIGN_OUT, 'DELETE', apiUrls.AUTH.SIGN_OUT, null, {
      afterSagaSuccess: function* () {
        yield put(accountActions.clearSession());
        yield put(menuActions.closeMenu());
        yield put(push(appUrls.AUTH.SIGN_IN));
      },
    }),
  verifyCode: (payload) =>
    apiAction(VERIFY_CODE, 'POST', apiUrls.AUTH.VERIFY_2FA, payload, {
      afterSagaSuccess: function* () {
        yield put(accountActions.setSession({ isAuthenticated: true }));
        yield put(authActions.clearVerifiedCode());
        yield put(push(appUrls.ROOT));
      },
    }),
  clearVerifiedCode: () => ({ type: VERIFY_CODE + CLEAR }),
  resetPassword: (payload) => apiAction(RESET_PASSWORD, 'POST', apiUrls.AUTH.RESET_PASSWORD, payload),
  setPassword: (payload) =>
    apiAction(SET_PASSWORD, 'POST', apiUrls.AUTH.SET_PASSWORD, payload, {
      afterSagaSuccess: function* () {
        yield put(push(appUrls.AUTH.SIGN_IN));
      },
    }),
  clearResetPassword: () => ({ type: RESET_PASSWORD + CLEAR }),
  setUpTwoFactor: () => apiAction(SETUP_TWO_FACTOR, 'GET', apiUrls.AUTH.SETUP_2FA),
  confirmEmailChange: (params) =>
    apiAction(CONFIRM_NEW_EMAIL, 'GET', resolveUrl(apiUrls.AUTH.CONFIRM_NEW_EMAIL, params)),
};
