import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import ChangeTwoFactorForm from 'modules/account/components/ChangeTwoFactorForm';

import { accountActions } from 'modules/account/redux/actions';

const selector = ({ account: { changedTwoFactor } }) => ({
  changedTwoFactor,
});

const ChangeTwoFactorView = ({ isPartner }) => {
  const { changedTwoFactor } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(accountActions.clearChangedTwoFactor());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTwoFactorChange = (values) => {
    dispatch(accountActions.changeTwoFactor(values));
  };

  return (
    <div className="change-two-factor">
      <h1 className="change-two-factor-header">Change 2FA</h1>
      <p className="mb-15">
        You’re about to change your two-factor authentication settings. This action will invalidate your current
        two-factor device. You will be redirected to a 2FA setup page.
      </p>
      <ChangeTwoFactorForm
        onSubmit={handleTwoFactorChange}
        error={changedTwoFactor.error}
        isSubmitting={changedTwoFactor.isFetching}
        isPartner={isPartner}
      />
      <Loader loaded={!changedTwoFactor.isFetching} />
    </div>
  );
};

ChangeTwoFactorView.propTypes = {
  isPartner: PropTypes.bool,
};

export default ChangeTwoFactorView;
