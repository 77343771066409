import React from 'react';
import PropTypes from 'prop-types';

const TableEmpty = ({ colSpan, children }) => (
  <tr className="trackers-table-row" role="rowgroup">
    <td className="trackers-table-empty" colSpan={colSpan}>
      {children}
    </td>
  </tr>
);

TableEmpty.propTypes = {
  colSpan: PropTypes.number,
};

export default TableEmpty;
