import { combineReducers } from 'redux';

import { apiReducer } from 'base/redux/utils';
import {
  SIGN_IN,
  SIGN_OUT,
  RESET_PASSWORD,
  VERIFY_CODE,
  SET_PASSWORD,
  SETUP_TWO_FACTOR,
  CONFIRM_NEW_EMAIL,
} from 'modules/auth/redux/actions';

export const auth = combineReducers({
  signIn: apiReducer(SIGN_IN),
  signOut: apiReducer(SIGN_OUT),
  passwordReset: apiReducer(RESET_PASSWORD),
  setPassword: apiReducer(SET_PASSWORD),
  codeVerification: apiReducer(VERIFY_CODE),
  setupTwoFactor: apiReducer(SETUP_TWO_FACTOR),
  confirmEmailChange: apiReducer(CONFIRM_NEW_EMAIL),
});
