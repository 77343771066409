import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { common } from 'common/redux/reducers';
import { auth } from 'modules/auth/redux/reducers';
import { menu, management } from 'modules/menu/redux/reducers';
import { trackers } from 'modules/trackers/redux/reducers';
import { partners } from 'modules/partners/redux/reducers';
import { account } from 'modules/account/redux/reducers';

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    common,
    auth,
    menu,
    trackers,
    management,
    partners,
    account,
  });
