import moment from 'moment';
import {
  IoAirplane,
  IoBatteryDeadSharp,
  IoBatteryFullSharp,
  IoBatteryHalfSharp,
  IoMoonOutline,
  IoPower,
} from 'react-icons/io5';

import { TRACKER_MODE, BATTERY_LEVEL } from 'modules/trackers/consts';

export const getTrackerModeIcon = (mode, size) => {
  switch (mode) {
    case TRACKER_MODE.POWERED_ON:
    case TRACKER_MODE.IN_TRANSIT:
      return <IoPower size={size} className="inline-icon" />;
    case TRACKER_MODE.IN_FLIGHT:
      return <IoAirplane size={size} className="inline-icon" />;
    case TRACKER_MODE.DEEP_SLEEP:
      return <IoMoonOutline size={size} className="inline-icon" />;
    default:
      return null;
  }
};

export const getBatteryIcon = (level, size, isColorful) => {
  switch (true) {
    case level < BATTERY_LEVEL.LOW:
      return <IoBatteryDeadSharp size={size} color={isColorful && 'hsl(348, 100%, 61%)'} className="inline-icon" />;
    case level > BATTERY_LEVEL.HIGH:
      return <IoBatteryFullSharp size={size} color={isColorful && 'hsl(141, 53%, 53%)'} className="inline-icon" />;
    default:
      return <IoBatteryHalfSharp size={size} color={isColorful && 'hsl(48, 100%, 67%)'} className="inline-icon" />;
  }
};

export const getClosestFlightIndex = (flights) => {
  if (!flights) return -1;
  const index = flights.findIndex((flight) => moment(flight.eta) > moment());
  return index < 0 ? flights.length - 1 : index;
};
