import { apiAction } from 'base/redux/utils';
import { apiUrls } from 'urls';

export const GROUPS_GET = 'GROUPS_GET';

export const commonActions = {
  groups: {
    get: () => apiAction(GROUPS_GET, 'GET', apiUrls.COMMON.GROUPS),
  },
};
