import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const Pagination = (props) => {
  const { pageCount, ...rest } = props;

  if (pageCount <= 1) {
    return null;
  }

  return (
    <ReactPaginate
      pageCount={pageCount}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      previousLabel="<"
      nextLabel=">"
      containerClassName="pagination"
      pageClassName="page-item"
      previousClassName="page-item previous"
      nextClassName="page-item next"
      {...rest}
    />
  );
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  forcePage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
