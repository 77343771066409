import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cx from 'classnames';

const RadioGroup = ({ items, onChange, className, name, value, setValue, errorMessage, showRequired, isPristine }) => {
  const changeValue = (event) => {
    const currentValue = event.target.value;
    onChange && onChange(currentValue);
    setValue(currentValue);
  };

  const error = errorMessage || (!isPristine && showRequired && 'This field is required');
  const wrapperClassName = cx('vrs-radio-group', className);
  return (
    <div className={wrapperClassName}>
      {items.map(({ label, value: v }, i) => {
        const id = name + i;
        return (
          <div key={id} className="vrs-radio-group-item">
            <input id={id} type="radio" name={name} value={v} onChange={changeValue} checked={value === v.toString()} />
            <label htmlFor={id}>{label}</label>
          </div>
        );
      })}
      {error && <p className="vrs-error">{error}</p>}
    </div>
  );
};

RadioGroup.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  onChange: PropTypes.func,
  className: PropTypes.string,
  // Formsy props
  name: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool,
  isPristine: PropTypes.bool,
};

export default withFormsy(RadioGroup);
