import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExportCSV from 'common/components/ExportCSV';
import Loader from 'common/components/Loader';
import ModalAlert from 'common/components/ModalAlert';
import Pagination from 'common/components/Pagination';
import TrackersTable from 'modules/trackers/components/TrackersTable';
import ChangePartnerForm from 'modules/trackers/components/ChangePartnerForm';
import ChangeGroupsForm from 'modules/trackers/components/ChangeGroupsForm';

import { trackersActions } from 'modules/trackers/redux/actions';
import { managementActions } from 'modules/menu/redux/actions';
import { TRACKERS_PAGE_SIZE, TRACKERS_DATA_TABLE } from 'modules/trackers/consts';

const selector = ({
  trackers: {
    trackersTable: { list },
    tracker: { partner, groups: trackerGroups },
  },
  management: {
    partners: {
      list: { data: partnersData },
    },
    partner: {
      groups: {
        list: { data: groupsData, isFetching: isFetchingGroups },
      },
      customers: {
        list: { data: customersData },
      },
    },
  },
}) => ({
  partners: partnersData?.partners?.map((item) => ({ label: item.name, value: item.id })) ?? [],
  groups: groupsData?.groups?.map((item) => ({ label: item.name, value: item.id })) ?? [],
  isFetching: list.isFetching,
  isFetchingGroups,
  partner,
  customers: customersData?.customers?.map((item) => ({ label: item.name, value: item.id })) ?? [],
  trackerGroups,
  error: list.error,
  data: list.data,
});

const TrackersManagementView = () => {
  const { isFetching, isFetchingGroups, partner, customers, error, data, partners, groups, trackerGroups } =
    useSelector(selector);
  const [sortConfig, setSortConfig] = useState(null);
  const [filters, setFilters] = useState(null);
  const [tableData, setTableData] = useState(TRACKERS_DATA_TABLE);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedTrackerId, setSelectedTrackerId] = useState(null);
  const dispatch = useDispatch();

  const trackers = data?.trackers || [];
  const pageCount = data?.total ? Math.ceil(data.total / TRACKERS_PAGE_SIZE) : 0;

  const sortBy = sortConfig
    ? sortConfig.direction === 'ascending'
      ? sortConfig.key
      : `-${sortConfig.key}`
    : 'tracker_id';

  useEffect(() => {
    dispatch(trackersActions.tracker.setSelectedTracker(null));
    dispatch(trackersActions.list.get({ page: currentPage + 1, sort_by: sortBy, ...filters }));
    setCurrentPage(0);
  }, [sortConfig, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(trackersActions.list.cleanGet());
      dispatch(trackersActions.list.cleanExportCsv());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(trackersActions.list.get({ page: currentPage + 1, sort_by: sortBy, ...filters }));
    setCurrentPage(0);
  }, [sortConfig, filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePartnerChange = (data) => {
    const {
      partner: { value: partner_id },
    } = data;
    const payload = {
      partner_id,
      ids: [parseInt(selectedTrackerId)],
    };

    dispatch(
      trackersActions.tracker.partner.set(payload, () => {
        dispatch(managementActions.partner.groups.get({ companyId: payload.partner_id }));
        dispatch(trackersActions.list.get({ page: currentPage + 1, sort_by: sortBy, ...filters }));
      })
    );
  };

  const handleGroupsChange = (values) => {
    const payload = { groups: values.groups.map((g) => g.value) };
    dispatch(
      trackersActions.tracker.groups.update({ trackerId: selectedTrackerId }, payload, () =>
        dispatch(trackersActions.list.get({ page: currentPage + 1, sort_by: sortBy, ...filters }))
      )
    );
  };

  const handleEditTracker = (tracker) => {
    dispatch(
      managementActions.partner.groups.get({ companyId: tracker.partner_id }, () => {
        setSelectedTrackerId(tracker.id);
      })
    );
  };

  const handleCloseEditTracker = () => {
    setSelectedTrackerId(null);
    dispatch(trackersActions.tracker.groups.cleanUpdate());
    dispatch(trackersActions.tracker.partner.cleanSet());
  };

  const changePage = (page) => {
    dispatch(trackersActions.list.get({ page: page.selected + 1, sort_by: sortBy, ...filters }));
    setCurrentPage(page.selected);
  };

  const requestSort = (key) => {
    const direction = tableData[key].direction === 'ascending' ? 'descending' : 'ascending';
    setSortConfig({ key, direction });
    setTableData({ ...tableData, [key]: { ...tableData[key], direction } });
  };

  const selectedTracker = selectedTrackerId && trackers.length && trackers?.find((t) => t.id === selectedTrackerId);

  return (
    <div className="trackers-management">
      {error && <p className="vrs-error">{error.error}</p>}
      <div className="trackers-management-export-csv">
        <ExportCSV filters={filters} sortBy={sortBy} />
      </div>
      <TrackersTable
        requestSort={requestSort}
        filters={filters}
        setFilters={setFilters}
        tableData={tableData}
        trackers={trackers}
        onEditTracker={handleEditTracker}
      />
      <Pagination pageCount={pageCount} forcePage={currentPage} onPageChange={changePage} />
      <ModalAlert
        isOpen={!!selectedTracker}
        onCloseClick={handleCloseEditTracker}
        headerText={selectedTracker?.id?.toString()}
      >
        <ChangePartnerForm
          partners={partners}
          tracker={{
            ...selectedTracker,
            company_name: selectedTracker?.partner_name,
            company_id: selectedTracker?.partner_id,
          }}
          customers={customers}
          onSubmit={handlePartnerChange}
          error={partner?.error}
          isSubmitting={partner?.isFetching}
          button={{ inverse: true }}
        />
        <hr className="divider" />
        <ChangeGroupsForm
          groups={groups}
          tracker={selectedTracker}
          onSubmit={handleGroupsChange}
          error={trackerGroups?.error}
          value={selectedTracker?.groups?.map((t) => ({ value: t.id, label: t.name }))}
          isSubmitting={trackerGroups?.isFetching}
          button={{ inverse: true }}
        />
        <Loader loaded={!isFetching || !isFetchingGroups || !partner?.isFetching || !trackerGroups?.isFetching} />
      </ModalAlert>
    </div>
  );
};

export default TrackersManagementView;
