import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';
import DateTimeInput from 'common/components/DateTimeInput';

const FlightSearchForm = ({ onFlightSearch, errorSearch, isSearching }) => {
  return (
    <div className="tracker-flight-info-view-section">
      <h3 className="tracker-flight-info-view-subtitle">Search for flights</h3>
      <Form onSubmit={onFlightSearch} className="tracker-flight-info-view-section-content">
        <div className="flight-search-form-wrapper">
          <label className="flight-search-form-item">
            Flight number
            <Input type="text" name="flightNumber" required={true} />
          </label>
          <label className="flight-search-form-item">
            Flight date
            <DateTimeInput name="date" value={moment()} timeFormat={false} required={true} />
          </label>
        </div>
        <div className="flight-search-form-wrapper">
          {errorSearch && <p className="vrs-error flight-search-form-error">{errorSearch.error}</p>}
          <Button className="tracker-flight-info-view-section-button" type="submit" disabled={isSearching}>
            Search
          </Button>
        </div>
      </Form>
    </div>
  );
};

FlightSearchForm.propTypes = {
  onFlightSearch: PropTypes.func,
  isSearching: PropTypes.bool,
  errorSearch: PropTypes.object,
};

export default FlightSearchForm;
