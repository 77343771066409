export const toFormData = (object = {}) => {
  const formData = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    if (value instanceof FileList) {
      [...value].forEach((file) => {
        formData.append(key, file);
      });
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};
