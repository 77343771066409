import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'common/components/Button';
import { SettingsForm } from 'common/components/SettingsForm';

import { displayConfirmDialog } from 'modules/menu/utils';
import { managementActions } from 'modules/menu/redux/actions';
import { usePartnerChannelsDelete } from 'api/partner';

const selector = ({ management }) => {
  const { list, saved, savedSensors } = management.partner.settings;
  const globalSettings = management.settings.list;
  const isFetching = list.isFetching || globalSettings.isFetching;
  return {
    settings: list.data,
    globalSettings: globalSettings.data,
    isFetching,
    saved,
    savedSensors,
  };
};

const ManageSettingsView = ({ partner }) => {
  const dispatch = useDispatch();
  const { settings, globalSettings, isFetching, saved, savedSensors } = useSelector(selector);
  const { mutate: deleteChannels } = usePartnerChannelsDelete();

  useEffect(() => {
    return () => {
      dispatch(managementActions.partner.settings.cleanGet());
      dispatch(managementActions.partner.settings.cleanSaved());
      dispatch(managementActions.settings.cleanGet());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChannelsDelete = () => {
    displayConfirmDialog().then(() => {
      deleteChannels({ companyId: partner.id });
    });
  };

  const handleModelChange = (model) => {
    dispatch(managementActions.partner.settings.cleanGet());
    dispatch(managementActions.partner.settings.get({ model, companyId: partner.id }));
  };

  const onSettingsSubmit = ({ model, payload, allValues }) => {
    const params = { model, companyId: partner.id };

    if (payload.sensors_configurations) {
      // Sensors settings
      dispatch(managementActions.partner.settings.saveSensors(params, payload));
    } else {
      // General settings
      if (Object.values(payload)[0] === null) {
        dispatch(managementActions.partner.settings.delete(params, allValues, Object.keys(payload)[0]));
      } else {
        dispatch(managementActions.partner.settings.save(params, payload));
      }
    }
  };

  const onSettingsRestore = (onSuccess, model) => {
    const params = { model, companyId: partner.id };
    dispatch(managementActions.partner.settings.deleteAll(params, onSuccess));
  };

  return (
    <div className="manage-trackers-settings">
      <div className="manage-header is-button-on-right">
        <div>
          <p className="manage-partner-name">{partner.name}</p>
          <p className="manage-partner-description">Trackers settings</p>
        </div>
        <Button onClick={handleChannelsDelete}>Delete channels</Button>
      </div>
      <SettingsForm
        settings={settings}
        defaultSettings={globalSettings}
        onSubmit={onSettingsSubmit}
        onRestore={onSettingsRestore}
        onModelChange={handleModelChange}
        isFetching={isFetching}
        generalSettingStatus={{
          isSubmitting: saved.fetching,
          isSuccess: saved.success,
          error: saved.error,
        }}
        sensorsSettingsStatus={{
          isSubmitting: savedSensors.isFetching,
          error: savedSensors.error,
        }}
      />
    </div>
  );
};

ManageSettingsView.propTypes = {
  partner: PropTypes.object,
};

export default ManageSettingsView;
