import React from 'react';
import PropTypes from 'prop-types';

const TrackerDetailsItem = ({ name, value }) => {
  return (
    <div className="tracker-details-item">
      <p className="tracker-details-name">{name}</p>
      <p className="tracker-details-value">{value === null || value === '' ? '---' : value}</p>
    </div>
  );
};

TrackerDetailsItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default TrackerDetailsItem;
