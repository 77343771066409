import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Upload from 'common/components/Upload';
import Select from 'common/components/Select';
import { deviceModelOptions, simOperatorOptions } from 'common/components/SettingsForm/utils';
import Checkbox from 'common/components/Checkbox';

const ImportDevicesForm = ({ onSubmit, isSubmitting, errors, partnersList }) => {
  const partnerIDs = useMemo(() => {
    return (partnersList?.partners || []).map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [partnersList]);

  return (
    <Form onSubmit={onSubmit} errors={errors} className="import-devices-form">
      <div className="row">
        <Upload name="file" required />
      </div>
      <div className="row">
        <Select name="model" options={deviceModelOptions} placeholder="Device model" required />
        <Select name="partnerID" options={partnerIDs} placeholder="Partner ID" required />
        <Select name="simOperator" options={simOperatorOptions} placeholder="SIM Operator" required />
        <Checkbox name="activateSim" label="Activate SIM" className="create-firmware-form-checkbox" />
      </div>
      <div className="row">
        <Button type="submit" disabled={isSubmitting}>
          Import devices
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = ({ management }) => {
  return {
    partnersList: management.partners.list.data,
  };
};

ImportDevicesForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
  // Redux props
  dispatch: PropTypes.func,
  partnersList: PropTypes.object,
};

export default connect(mapStateToProps)(ImportDevicesForm);
