import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { formatDatetime } from 'common/utils';
import { getClosestFlightIndex } from 'modules/trackers/utils';

import Button from 'common/components/Button';
import Pagination from 'common/components/Pagination';
import Loader from 'common/components/Loader';

export const FLIGHT_INFO_DETAILS = [
  {
    label: 'Scheduled time of departure',
    dateKey: 'std',
    timezoneKey: 'origin_timezone',
  },
  {
    label: 'Estimated time of departure',
    dateKey: 'etd',
    timezoneKey: 'origin_timezone',
  },
  {
    label: 'Actual time of departure',
    dateKey: 'atd',
    timezoneKey: 'origin_timezone',
  },
  {
    label: 'Scheduled time of arrival',
    dateKey: 'sta',
    timezoneKey: 'dest_timezone',
  },
  {
    label: 'Estimated time of arrival',
    dateKey: 'eta',
    timezoneKey: 'dest_timezone',
  },
  {
    label: 'Actual time of arrival',
    dateKey: 'ata',
    timezoneKey: 'dest_timezone',
  },
];

const FlightInfo = ({ flights, isFetching, isRemoving, onRemove }) => {
  const [selected, setSelected] = useState(0);
  const flight = flights[selected] || {};

  useEffect(() => {
    setSelected(getClosestFlightIndex(flights) || 0);
  }, [flights]);

  return (
    <>
      <h3 className="tracker-flight-info-view-subtitle">Tracker flight info</h3>
      <div className="tracker-flight-info-view-section-content">
        <p className="flight-info-item">
          <span className="flight-info-label">Flight number</span>
          <span className="flight-info-value">
            {flight.iata}
            {flight.operated_by && ` operated by ${flight.operated_by}`}
          </span>
        </p>
        {FLIGHT_INFO_DETAILS.map(({ label, dateKey, timezoneKey }) => (
          <p key={dateKey} className="flight-info-item">
            <span className="flight-info-label">{label}</span>
            <span className="flight-info-value">{formatDatetime(flight[dateKey], 'N/A', flight[timezoneKey])}</span>
          </p>
        ))}
        {flight.id && (
          <div className="right-text mt-15">
            <Button type="button" onClick={() => onRemove(flight.id)} disabled={isRemoving}>
              Remove
            </Button>
          </div>
        )}
        <Pagination
          pageCount={flights.length}
          forcePage={selected}
          onPageChange={(value) => setSelected(value.selected)}
        />
      </div>
      <Loader loaded={!isFetching} />
    </>
  );
};

FlightInfo.propTypes = {
  flights: PropTypes.array,
  onRemove: PropTypes.func,
  isFetching: PropTypes.bool,
  isRemoving: PropTypes.bool,
};

export default FlightInfo;
