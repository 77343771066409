import { combineReducers } from 'redux';

import { apiReducer } from 'base/redux/utils';
import { GROUPS_GET } from './actions';

export const common = combineReducers({
  groups: combineReducers({
    list: apiReducer(GROUPS_GET),
  }),
});
