import { combineReducers } from 'redux';
import { apiReducer, createSettingsStateReducer } from 'base/redux/utils';

import {
  MENU_MODAL,
  MENU_PATH_SET,
  MENU_PATH_BACK,
  PARTNERS_GET,
  PARTNERS_CREATE,
  PARTNERS_DELETE,
  SETTINGS_GET,
  SETTINGS_SAVE,
  FIRMWARES_GET,
  FIRMWARES_USED,
  FIRMWARES_CREATE,
  FIRMWARES_DELETE,
  DEVICES_IMPORT,
  PARTNER_CUSTOMERS_GET,
  PARTNER_CUSTOMERS_CREATE,
  PARTNER_CUSTOMERS_DELETE,
  PARTNER_TCC_ADMINS_GET,
  PARTNER_TCC_ADMINS_CREATE,
  PARTNER_TCC_ADMINS_DELETE,
  PARTNER_BLE_FILTERS_GET,
  PARTNER_BLE_FILTERS_CREATE,
  PARTNER_BLE_FILTERS_EDIT,
  PARTNER_BLE_FILTERS_DELETE,
  PARTNER_USERS_GET,
  PARTNER_USERS_CREATE,
  PARTNER_USERS_DELETE,
  PARTNER_SETTINGS_GET,
  PARTNER_SETTINGS_SAVE,
  PARTNER_SETTINGS_DELETE,
  PARTNER_SENSORS_SETTINGS_SAVE,
  PARTNER_GROUPS_GET,
  PARTNER_GROUP_CREATE,
  PARTNER_GROUP_EDIT,
  PARTNER_GROUP_DELETE,
  PARTNER_GROUP_DEVICES_GET,
  PARTNER_GROUP_DEVICE_CREATE,
  PARTNER_GROUP_DEVICE_DELETE,
  PARTNER_KEYS_GET,
  PARTNER_KEYS_CREATE,
  PARTNER_KEYS_DELETE,
  PARTNER_BLE_SCRIPTS_CREATE,
  PARTNER_BLE_SCRIPTS_EDIT,
  PARTNER_BLE_SCRIPTS_DELETE,
} from './actions';
import { REQUEST, SUCCESS, FAILURE } from 'base/redux/consts';
import { matchUrl } from 'common/utils';
import { menuPaths } from 'modules/menu/consts';
import { apiUrls } from 'urls';

const initialState = {
  data: null,
  error: null,
  isFetching: false,
};

const modal = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case MENU_MODAL:
      return {
        ...state,
        isOpen: action.isOpen,
      };
    default:
      return state;
  }
};

const path = (state = { path: menuPaths.HOME }, action) => {
  switch (action.type) {
    case MENU_PATH_SET:
      return {
        ...state,
        path: action.path,
      };
    case MENU_PATH_BACK:
      return {
        ...state,
        path: state.path.slice(0, state.path.lastIndexOf('/')),
      };
    default:
      return state;
  }
};

export const partnersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNERS_GET + REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PARTNERS_GET + SUCCESS:
      return {
        ...state,
        error: initialState.error,
        isFetching: initialState.isFetching,
        data: action.data,
      };
    case PARTNERS_CREATE + SUCCESS: {
      const currentPartners = state.data.partners || [];
      return {
        ...state,
        data: {
          ...state.data,
          partners: [...currentPartners, action.data],
        },
      };
    }
    case PARTNERS_GET + FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: initialState.isFetching,
      };
    default:
      return state;
  }
};

export const partnerKeysReducer = (state = initialState, action) => {
  switch (action.type) {
    case PARTNER_KEYS_GET + REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case PARTNER_KEYS_GET + SUCCESS:
      return {
        ...state,
        error: initialState.error,
        isFetching: initialState.isFetching,
        data: action.data,
      };
    case PARTNER_KEYS_GET + FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: initialState.isFetching,
      };
    case PARTNER_KEYS_CREATE + SUCCESS: {
      const currentKeys = state.data.api_keys || [];
      return {
        ...state,
        data: {
          ...state.data,
          api_keys: [...currentKeys, { api_key: action.data.api_key }],
        },
      };
    }
    case PARTNER_KEYS_CREATE + FAILURE: {
      return {
        ...state,
        error: action.error,
        isFetching: initialState.isFetching,
      };
    }
    case PARTNER_KEYS_DELETE + SUCCESS: {
      const params = matchUrl(apiUrls.PARTNER.KEYS.DELETE, action.requestAction.endpoint);
      const keyId = params && params.keyId;
      return {
        ...state,
        data: {
          ...state.data,
          api_keys: state.data.api_keys.filter((key) => key !== keyId),
        },
      };
    }
    default:
      return state;
  }
};

export const menu = combineReducers({
  modal,
  path,
});

export const management = combineReducers({
  partners: combineReducers({
    list: partnersListReducer,
    created: apiReducer(PARTNERS_CREATE),
    deleted: apiReducer(PARTNERS_DELETE),
  }),
  settings: combineReducers({
    list: apiReducer(SETTINGS_GET),
    saved: createSettingsStateReducer(SETTINGS_SAVE),
  }),
  firmwares: combineReducers({
    list: apiReducer(FIRMWARES_GET),
    used: apiReducer(FIRMWARES_USED),
    created: apiReducer(FIRMWARES_CREATE),
    deleted: apiReducer(FIRMWARES_DELETE),
  }),
  devices: combineReducers({
    import: apiReducer(DEVICES_IMPORT),
  }),
  partner: combineReducers({
    customers: combineReducers({
      list: apiReducer(PARTNER_CUSTOMERS_GET),
      created: apiReducer(PARTNER_CUSTOMERS_CREATE),
      deleted: apiReducer(PARTNER_CUSTOMERS_DELETE),
    }),
    tccAdmins: combineReducers({
      list: apiReducer(PARTNER_TCC_ADMINS_GET),
      created: apiReducer(PARTNER_TCC_ADMINS_CREATE),
      deleted: apiReducer(PARTNER_TCC_ADMINS_DELETE),
    }),
    bleFilters: combineReducers({
      list: apiReducer(PARTNER_BLE_FILTERS_GET),
      created: apiReducer(PARTNER_BLE_FILTERS_CREATE),
      edit: apiReducer(PARTNER_BLE_FILTERS_EDIT),
      deleted: apiReducer(PARTNER_BLE_FILTERS_DELETE),
    }),
    bleScripts: combineReducers({
      create: apiReducer(PARTNER_BLE_SCRIPTS_CREATE),
      edit: apiReducer(PARTNER_BLE_SCRIPTS_EDIT),
      delete: apiReducer(PARTNER_BLE_SCRIPTS_DELETE),
    }),
    users: combineReducers({
      list: apiReducer(PARTNER_USERS_GET),
      created: apiReducer(PARTNER_USERS_CREATE),
      deleted: apiReducer(PARTNER_USERS_DELETE),
    }),
    settings: combineReducers({
      list: apiReducer(PARTNER_SETTINGS_GET),
      saved: createSettingsStateReducer(PARTNER_SETTINGS_SAVE, PARTNER_SETTINGS_DELETE),
      savedSensors: apiReducer(PARTNER_SENSORS_SETTINGS_SAVE),
    }),
    groups: combineReducers({
      list: apiReducer(PARTNER_GROUPS_GET),
      created: apiReducer(PARTNER_GROUP_CREATE),
      edited: apiReducer(PARTNER_GROUP_EDIT),
      deleted: apiReducer(PARTNER_GROUP_DELETE),
      devicesList: apiReducer(PARTNER_GROUP_DEVICES_GET),
      deviceCreated: apiReducer(PARTNER_GROUP_DEVICE_CREATE),
      deviceDeleted: apiReducer(PARTNER_GROUP_DEVICE_DELETE),
    }),
    keys: combineReducers({
      list: partnerKeysReducer,
      created: apiReducer(PARTNER_KEYS_CREATE),
      deleted: apiReducer(PARTNER_KEYS_DELETE),
    }),
  }),
});
