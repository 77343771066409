import React from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from 'common/components/Breadcrumb';
import MenuContainer from 'modules/menu/containers/MenuContainer';

const AppLayout = ({ children }) => {
  return (
    <>
      <Breadcrumb />
      {children}
      <MenuContainer />
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node,
};

export default AppLayout;
