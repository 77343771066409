import axios from 'axios';

/**
 * Method to create API request.
 * @param {string} method - The type of HTTP request method.
 * @param {string} url - The endpoint URL.
 * @param {Object} [payload] - The payload data.
 */
export const request = (method, url, payload) => {
  const isMultipart = payload instanceof FormData;

  const requestConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    method,
    url,
    data: payload,
    withCredentials: true,
  };

  isMultipart && (requestConfig.headers = { 'Content-Type': 'multipart/form-data' });

  return axios(requestConfig);
};
