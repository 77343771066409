import { applyMiddleware, createStore, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import { createRootReducer } from './rootReducer';

const IS_DEVELOPMEMNT = process.env.NODE_ENV === 'development';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = (IS_DEVELOPMEMNT && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const configureStore = (history, initialState) => {
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  if (IS_DEVELOPMEMNT) {
    const { createLogger } = require('redux-logger');
    const loggerMiddleware = createLogger({
      level: 'info',
      collapsed: true,
    });
    middlewares.push(loggerMiddleware);
  }

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(...middlewares.map((m) => applyMiddleware(m)))
  );

  sagaMiddleware.run(rootSaga);

  return store;
};
