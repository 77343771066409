import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import FlightInfo from 'modules/trackers/components/FlightInfo';
import FlightSearchForm from 'modules/trackers/components/FlightSearchForm';
import FlightChooseForm from 'modules/trackers/components/FlightChooseForm';

import { trackersActions } from 'modules/trackers/redux/actions';

const selector = ({ trackers }) => {
  const { list, set, deleted } = trackers.tracker.flights;
  const { search } = trackers.flights;

  return {
    flightsInfo: list.data?.flights || [],
    searchData: search.data?.flights,
    errorSearch: search.error,
    errorSet: set.error,
    isFetching: list.isFetching,
    isSearching: search.isFetching,
    isSetting: set.isFetching,
    isRemoving: deleted.isFetching,
  };
};

const TrackerFlightInfoView = ({ match }) => {
  const dispatch = useDispatch();
  const { flightsInfo, searchData, errorSearch, errorSet, isFetching, isSearching, isSetting, isRemoving } =
    useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(trackersActions.tracker.flights.cleanSet());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const searchFlight = (values) => {
    const trackerId = match.params.trackerId;
    const date = moment.utc(values.date).format();
    dispatch(trackersActions.flights.search(values.flightNumber.replace(/ /g, ''), { date }, trackerId));
  };

  const setFlightInfo = (values) => {
    const trackerId = match.params.trackerId;
    dispatch(trackersActions.tracker.flights.set({ trackerId }, values));
  };

  const removeFlightInfo = (flightId) => {
    const trackerId = parseInt(match.params.trackerId);
    dispatch(trackersActions.tracker.flights.delete({ trackerId, flightId }));
  };

  return (
    <>
      <FlightSearchForm
        onFlightSearch={searchFlight}
        searchData={searchData}
        errorSearch={errorSearch}
        isSearching={isSearching}
      />
      {searchData && (
        <FlightChooseForm
          searchedFlights={searchData}
          onFlightSet={setFlightInfo}
          errorSet={errorSet}
          isSetting={isSetting}
        />
      )}
      <FlightInfo flights={flightsInfo} isFetching={isFetching} isRemoving={isRemoving} onRemove={removeFlightInfo} />
    </>
  );
};

TrackerFlightInfoView.propTypes = {
  match: PropTypes.object,
};

export default TrackerFlightInfoView;
