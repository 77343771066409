import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';

import { configureStore } from 'base/redux/configureStore';
import Root from 'base/containers/Root';

const history = createBrowserHistory();
const store = configureStore(history);

ReactDOM.render(
  <React.StrictMode>
    <Root store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root')
);
