import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import cx from 'classnames';
import { withFormsy } from 'formsy-react';

const Switch = ({ className, name, label, onChange, checked, defaultChecked, invert = false, setValue }) => {
  const isControlled = typeof defaultChecked !== 'undefined';
  const id = useRef(uniqueId(`vrs-switch-${name.toLowerCase().replace(/ /g, '-')}-`));
  const [status, setStatus] = useState(defaultChecked);

  const inputClassName = cx('vrs-switch', className);

  return (
    <div data-testid="switch-wrapper" className={inputClassName}>
      <input
        id={id.current}
        type="checkbox"
        name={name}
        onChange={(event) => {
          if (isControlled) {
            setStatus(event.target.checked);
          }

          if (onChange) {
            onChange(event);
          }

          if (setValue) {
            setValue(event.target.checked);
          }
        }}
        checked={isControlled ? status : checked}
      />
      <label htmlFor={id.current} style={{ color: invert ? 'white' : '' }}>
        {label}
      </label>
    </div>
  );
};

Switch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  getValue: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  invert: PropTypes.bool,
  // Formsy props
  setValue: PropTypes.func,
};

export default Switch;

export const FormSwitch = withFormsy(Switch);
