import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import ExportLogForm from 'modules/trackers/components/ExportLogForm';

import { trackersActions } from 'modules/trackers/redux/actions';

const selector = ({ trackers }) => ({
  isFetching: trackers.tracker.exported.isFetching,
  error: trackers.tracker.exported.error?.error,
});

const TrackerExportView = ({ match }) => {
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(trackersActions.tracker.cleanLogs());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const exportLog = (period, format = 'csv') => {
    const params = { format, trackerId: match.params.trackerId, days: period };
    dispatch(trackersActions.tracker.exportLog(params));
  };

  return (
    <div className="export-log-outer">
      <ExportLogForm onExport={exportLog} error={error} />
      <Loader loaded={!isFetching} />
    </div>
  );
};

TrackerExportView.propTypes = {
  match: PropTypes.object,
};

export default TrackerExportView;
