import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Select from 'common/components/Select';

import { usePrevious } from 'common/effects/usePrevious';
import { managementActions } from 'modules/menu/redux/actions';

const ChangePartnerForm = ({ partners, customers, tracker, onSubmit, error, isSubmitting, button }) => {
  const [partner, setPartner] = useState(null);
  const [customer, setCustomer] = useState(null);
  const prevTracker = usePrevious(tracker);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tracker && tracker.id !== prevTracker?.id) {
      if (tracker.vendor_id) {
        setPartner(partners?.find((p) => p.value === tracker.vendor_id));
      } else {
        setPartner({ label: tracker.company_name, value: tracker.company_id });
      }
      setCustomer(
        tracker.company_id && tracker.vendor_id ? { label: tracker.company_name, value: tracker.company_id } : null
      );
    }
  }, [tracker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (partner) {
      dispatch(managementActions.partner.customers.get({ companyId: partner.value }));
    }
  }, [partner]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePartner = (values) => {
    setPartner(partners?.find((p) => p.value === values.partner));
    setCustomer(null);
  };

  const handleSubmit = (values) => {
    onSubmit({ partner: values.customer?.value ? values.customer : values.partner });
  };

  return (
    <Form onSubmit={handleSubmit} errors={error} className="change-partner-form">
      <h4 className="mb-15">Change partner or customer</h4>
      <div className="change-partner-form-control">
        <p className="change-partner-form-label">Partner</p>
        <Select name="partner" options={partners} value={partner} showRequired={true} onChange={handleChangePartner} />
      </div>
      <div className="change-partner-form-control">
        <p className="change-partner-form-label">Customer*</p>
        <Select name="customer" options={customers} value={customer} isClearable={true} />
      </div>
      <div className="change-partner-form-submit">
        <div className="change-partner-form-info">
          <p>* Choose a customer to change it or leave it empty to change the partner.</p>
          <p>Changing the partner or customer will cause removing all channels of the tracker.</p>
        </div>
        <Button {...button} type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </Form>
  );
};

ChangePartnerForm.propTypes = {
  partners: PropTypes.array,
  tracker: PropTypes.object,
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
  button: PropTypes.object,
};

export default ChangePartnerForm;
