import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';

import Checkbox from 'common/components/Checkbox';
import Form from 'common/components/Form';

const Dropdown = ({ disabled, title, text, icon, name, options, onChange, className, values }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event) => {
      if (isOpen && !dropdownRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [dropdownRef, isOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const buttonClassName = cx('dropdown-button', className);
  const dropdownClassName = cx('dropdown-outer', {
    'dropdown-open': isOpen,
    'dropdown-close': !isOpen,
  });
  const FormItems = options.length ? (
    options.map((option, index) => (
      <Checkbox
        key={`${name}-${index}`}
        name={option.value}
        value={!!(values?.length && values.some((v) => v === option.value))}
        label={option.name}
        className="dropdown-option"
      />
    ))
  ) : (
    <span>No items</span>
  );
  return (
    <div className="dropdown-button-outer">
      <button className={buttonClassName} onClick={() => setIsOpen(true)} disabled={disabled} title={title}>
        <span>{text}</span>
        {icon}
      </button>
      <div className={dropdownClassName} ref={dropdownRef}>
        <PerfectScrollbar>
          <div className="dropdown-container">
            <Form onChange={(currentValues, isChanged) => onChange(currentValues, isChanged, name)}>
              {options ? FormItems : <span>Loading...</span>}
            </Form>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  name: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

export default Dropdown;
