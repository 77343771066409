import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loader from 'common/components/Loader';
import VerifyTwoFactorForm from 'modules/auth/components/VerifyTwoFactorForm';
import { authActions } from 'modules/auth/redux/actions';
import { appUrls } from 'urls';

const selector = ({ account, auth }) => ({
  missing2FACode: account.session.missing2FACode,
  isFetching: auth.codeVerification.isFetching,
  error: auth.codeVerification.error,
});

const VerifyTwoFactorView = () => {
  const dispatch = useDispatch();
  const { missing2FACode, isFetching, error } = useSelector(selector);

  const handleSubmit = (values) => {
    dispatch(authActions.verifyCode(values));
  };

  if (missing2FACode) {
    return (
      <div className="center-text">
        <div className="auth-form">
          <div>
            <h1>Verify yourself</h1>
            <p>You should get a verification code to your phone via two-step verification app.</p>
          </div>
          <VerifyTwoFactorForm onSubmit={handleSubmit} error={error} isSubmitting={isFetching} />
          <Loader loaded={!isFetching} />
        </div>
      </div>
    );
  } else {
    return <Redirect to={appUrls.ROOT} />;
  }
};

export default VerifyTwoFactorView;
