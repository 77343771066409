import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactAutosuggest from 'react-autosuggest';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';

const Autosuggest = ({
  placeholder,
  suggestions,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  renderSuggestion,
  alert,
  className,
}) => {
  const [value, setValue] = useState('');

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const shouldRenderSuggestions = (value) => {
    return value.trim().length > 2;
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
  };

  const renderSuggestionsContainer = ({ containerProps, children }) =>
    children && (
      <div {...containerProps}>
        <PerfectScrollbar>{children}</PerfectScrollbar>
      </div>
    );

  return (
    <div className={cx('autosuggest', className)} data-testid="autosuggest-container">
      <ReactAutosuggest
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        shouldRenderSuggestions={shouldRenderSuggestions}
        getSuggestionValue={() => ''}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderSuggestionsContainer={renderSuggestionsContainer}
      />
      <p className="autosuggest-alert vrs-error">{alert}</p>
    </div>
  );
};

Autosuggest.propTypes = {
  placeholder: PropTypes.string,
  suggestions: PropTypes.array,
  onSuggestionsFetchRequested: PropTypes.func,
  onSuggestionsClearRequested: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  renderSuggestion: PropTypes.func,
  alert: PropTypes.string,
  className: PropTypes.string,
};

export default Autosuggest;
