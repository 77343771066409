import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { resolveUrl } from 'common/utils';
import { menuPaths } from 'modules/menu/consts';
import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';

import Loader from 'common/components/Loader';
import Switch from 'common/components/Switch';

import CreateBLEFilterForm from 'modules/menu/components/CreateBLEFilterForm';
import { toFormData } from 'common/utils/formData';

const selector = ({ management }) => {
  const { list, created } = management.partner.bleFilters;
  const isFetching = list.isFetching || created.isFetching;
  return {
    isFetching,
    isCreating: created.isFetching,
    items: list.data,
    errors: {
      created: created.error,
    },
  };
};

const ManageBLEFiltersView = ({ partner, setMenuPath, setCurrentBleFilter }) => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, items, errors } = useSelector(selector);
  const [initialValues] = useState({ alias: '', scan_duration: 300, data: '' });

  useEffect(() => {
    dispatch(managementActions.partner.bleFilters.get({ companyId: partner.id }, { page: 0, limit: 100 }));
    return () => {
      dispatch(managementActions.partner.bleFilters.cleanGet());
      dispatch(managementActions.partner.bleFilters.cleanCreated());
      dispatch(managementActions.partner.bleFilters.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createFilter = (values) => {
    const data = values;
    data.scan_duration += 's';

    const formData = toFormData(data);

    dispatch(managementActions.partner.bleFilters.create({ companyId: partner.id }, formData));
  };

  const toggleIsActive = (bleFilterId, is_active) => {
    displayConfirmDialog(`Are you sure, you want ${is_active ? 'enable' : 'disable'} filter for scanning ?`).then(
      () => {
        dispatch(managementActions.partner.bleFilters.edit({ companyId: partner.id, bleFilterId }, { is_active }));
      }
    );
  };

  const handleBLEFilterClick = (bleFilter) => {
    setCurrentBleFilter(bleFilter);
    setMenuPath(
      resolveUrl(menuPaths.PARTNER_BLE_FILTERS_DETAILS, { partnerName: partner.id, bleFilterName: bleFilter.alias })
    );
  };

  const renderFiltersList = () => {
    return (
      <div className="manage-list">
        {items?.filters
          ?.sort((a, b) => (a.alias > b.alias ? 1 : -1))
          ?.map((item) => (
            <div key={item.id} className="manage-list-item" data-testid="partner-item">
              <span className="list-item-text pointer" onClick={() => handleBLEFilterClick(item)}>
                {item.alias}
              </span>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '90px' }}>
                <Switch
                  name="is_active"
                  label={item.is_active ? 'Active' : 'Inactive'}
                  onChange={() => toggleIsActive(item.id, !item.is_active)}
                  checked={item.is_active}
                  invert
                />
              </div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <>
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">BLE filters</p>
      </div>
      <CreateBLEFilterForm
        initialValues={initialValues}
        onSubmit={createFilter}
        errors={errors.created}
        isSubmitting={isCreating}
      />
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderFiltersList()}
        <Loader loaded={!isFetching} />
      </div>
    </>
  );
};

const mapStateToProps = ({ account, management }) => {
  const currentUser = account.currentUser.data;

  return {
    management,
    bleFilters: management.partners.list.data,
    currentCompany: currentUser.company_id,
  };
};

export default connect(mapStateToProps)(ManageBLEFiltersView);
