import { all, put } from 'redux-saga/effects';

import { commonSaga } from 'common/redux/sagas';
import { authSaga } from 'modules/auth/redux/sagas';
import { partnerSaga } from 'modules/menu/redux/sagas';
import { trackersSaga } from 'modules/trackers/redux/sagas';
import { partnersSaga } from 'modules/partners/redux/sagas';
import { accountSaga } from 'modules/account/redux/sagas';

import { accountActions } from 'modules/account/redux/actions';

function* initialSaga() {
  yield put(accountActions.getUser(true));
}

export default function* rootSaga() {
  yield all([
    commonSaga(),
    authSaga(),
    partnerSaga(),
    trackersSaga(),
    partnersSaga(),
    accountSaga(),
    // It has to be the last one
    initialSaga(),
  ]);
}
