import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import SetPasswordForm from 'modules/auth/components/SetPasswordForm';
import { authActions } from 'modules/auth/redux/actions';

const selector = ({ auth }) => ({
  isFetching: auth.setPassword.isFetching,
  error: auth.setPassword.error,
});

const SetPasswordView = ({ location }) => {
  const { isFetching, error } = useSelector(selector);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const payload = {
      password: values.password,
      token: location.state.token,
    };
    dispatch(authActions.setPassword(payload));
  };

  return (
    <div className="center-text">
      <div className="auth-form">
        <h1>Change password</h1>
        <p>Now you are going to change password.</p>
        <SetPasswordForm onSubmit={handleSubmit} error={error} isSubmitting={isFetching} />
        <Loader loaded={!isFetching} />
      </div>
    </div>
  );
};

SetPasswordView.propTypes = {
  location: PropTypes.object,
};

export default SetPasswordView;
