import { put } from 'redux-saga/effects';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { managementActions } from 'modules/menu/redux/actions';
import { apiAction } from 'base/redux/utils';
import { resolveUrl } from 'common/utils';
import { CLEAR } from 'base/redux/consts';
import { apiUrls } from 'urls';

import { TRACKERS_DATA_TABLE } from 'modules/trackers/consts';

export const TRACKER_MODAL_OPEN = 'TRACKER_MODAL_OPEN';
export const SEARCH_PARAMS = 'SEARCH_PARAMS';
export const SEARCH_FILTERS = 'SEARCH_FILTERS';

export const TRACKERS_LIST = 'TRACKERS_LIST';
export const TRACKER_SUGGESTIONS = 'TRACKER_SUGGESTIONS';
export const TRACKER_SUGGESTION = 'TRACKER_SUGGESTION';

export const TRACKER_SELECTED = 'TRACKER_SELECTED';
export const TRACKER_DETAILS = 'TRACKER_DETAILS';
export const TRACKER_EXPORT = 'TRACKER_EXPORT';
export const TRACKER_PARTNER = 'TRACKER_PARTNER';
export const TRACKER_GROUPS_UPDATE = 'TRACKER_GROUPS_UPDATE';

export const TRACKER_SETTINGS_GET = 'TRACKER_SETTINGS_GET';
export const TRACKER_SETTINGS_SAVE = 'TRACKER_SETTINGS_SAVE';
export const TRACKER_SETTINGS_LOCATION_SAVE = 'TRACKER_SETTINGS_LOCATION_SAVE';
export const TRACKER_SETTINGS_LOCATION_DELETE = 'TRACKER_SETTINGS_LOCATION_DELETE';

export const TRACKER_SETTINGS_DELETE = 'TRACKER_SETTINGS_DELETE';
export const TRACKER_SETTINGS_DELETE_ALL = 'TRACKER_SETTINGS_DELETE_ALL';
export const TRACKER_SENSORS_SETTINGS_SAVE = 'TRACKER_SENSORS_SETTINGS_SAVE';

export const TRACKER_FLIGHTS_INFO_GET = 'TRACKER_FLIGHTS_INFO_GET';
export const TRACKER_FLIGHTS_INFO_SET = 'TRACKER_FLIGHTS_INFO_SET';
export const TRACKER_FLIGHTS_INFO_DELETE = 'TRACKER_FLIGHTS_INFO_DELETE';

export const TRACKER_BLE_DEVICES_GET = 'TRACKER_BLE_DEVICES_GET';

export const TRACKERS_MIGRATE = 'TRACKERS_MIGRATE';

export const TRACKER_CHATBOT_CHANNELS_GET = 'TRACKER_CHATBOT_CHANNELS_GET';
export const TRACKER_CHATBOT_CHANNELS_DELETE = 'TRACKER_CHATBOT_CHANNELS_DELETE';

export const FLIGHTS_SEARCH = 'FLIGHTS_SEARCH';

export const TRACKERS_TABLE_EXPORT = 'TRACKERS_TABLE_EXPORT';
export const TRACKERS_TABLE_GET = 'TRACKERS_TABLE_GET';

export const trackersActions = {
  modal: {
    open: () => ({
      type: TRACKER_MODAL_OPEN,
      isOpen: true,
    }),
    close: () => ({
      type: TRACKER_MODAL_OPEN,
      isOpen: false,
    }),
  },
  search: {
    setSearchFilters: (filters) => ({ type: SEARCH_FILTERS, filters }),
    setSearchParams: (params) => ({ type: SEARCH_PARAMS, params }),
    getTrackers: (query) => apiAction(TRACKERS_LIST, 'GET', resolveUrl(apiUrls.SEARCH.TRACKERS, null, query)),
    getTrackerSuggestions: (query) =>
      apiAction(
        TRACKER_SUGGESTIONS,
        'GET',
        resolveUrl(apiUrls.SEARCH.TRACKER_SUGGESTIONS, null, {
          query,
          fields: ['tracker_id', 'iccid'],
        })
      ),
    getTrackerSuggestion: (query) =>
      apiAction(
        TRACKER_SUGGESTION,
        'GET',
        resolveUrl(apiUrls.SEARCH.TRACKER_SUGGESTIONS, null, {
          query,
          fields: ['tracker_id'],
        })
      ),
    cleanSuggestions: () => ({ type: TRACKER_SUGGESTIONS + CLEAR }),
  },
  tracker: {
    setSelectedTracker: (tracker, options) => ({ type: TRACKER_SELECTED, tracker, options }),
    getDetails: (params) => apiAction(TRACKER_DETAILS, 'GET', resolveUrl(apiUrls.TRACKER.DETAILS, params)),
    exportLog: ({ trackerId, days, format }) =>
      apiAction(TRACKER_EXPORT, 'GET', resolveUrl(apiUrls.TRACKER.EXPORT, { trackerId }, { days, format }), null, {
        trackerId,
      }),
    cleanLogs: () => ({ type: TRACKER_EXPORT + CLEAR }),
    partner: {
      set: (payload, onSuccess) =>
        apiAction(TRACKER_PARTNER, 'POST', apiUrls.TRACKER.PARTNER, payload, {
          afterSagaSuccess: function* () {
            yield onSuccess?.();
          },
        }),
      cleanSet: () => ({ type: TRACKER_PARTNER + CLEAR }),
    },
    groups: {
      update: (params, payload, onSuccess) =>
        apiAction(TRACKER_GROUPS_UPDATE, 'PATCH', resolveUrl(apiUrls.TRACKER.GROUPS, params), payload, {
          afterSagaSuccess: function* () {
            yield onSuccess?.();
          },
        }),
      cleanUpdate: () => ({ type: TRACKER_GROUPS_UPDATE + CLEAR }),
    },
    bleDevices: {
      get: (params, query) =>
        apiAction(TRACKER_BLE_DEVICES_GET, 'GET', resolveUrl(apiUrls.TRACKER.BLE_DEVICES.NOTICES, params, query)),
      cleanGet: () => ({ type: TRACKER_BLE_DEVICES_GET + CLEAR }),
    },
    settings: {
      get: (params, companyId, model) =>
        apiAction(TRACKER_SETTINGS_GET, 'GET', resolveUrl(apiUrls.TRACKER.SETTINGS.GET, params), null, {
          afterSaga: function* () {
            // Get partner settings to display as placeholders
            yield put(managementActions.partner.settings.get({ companyId, model }));
          },
        }),
      save: (params, payload) =>
        apiAction(TRACKER_SETTINGS_SAVE, 'PATCH', resolveUrl(apiUrls.TRACKER.SETTINGS.SAVE, params), payload),
      delete: (params, payload, changedField) =>
        apiAction(TRACKER_SETTINGS_DELETE, 'DELETE', resolveUrl(apiUrls.TRACKER.SETTINGS.DELETE, params), payload, {
          data: { changedField },
        }),
      deleteAll: (params, onSuccess) =>
        apiAction(TRACKER_SETTINGS_DELETE_ALL, 'DELETE', resolveUrl(apiUrls.TRACKER.SETTINGS.DELETE, params), null, {
          afterSagaSuccess: function* () {
            yield onSuccess?.();
          },
        }),
      saveLocation: (params, payload) =>
        apiAction(TRACKER_SETTINGS_LOCATION_SAVE, 'PATCH', resolveUrl(apiUrls.TRACKER.SETTINGS.SAVE, params), payload),

      deleteLocation: (params, payload, changedField) =>
        apiAction(
          TRACKER_SETTINGS_LOCATION_DELETE,
          'DELETE',
          resolveUrl(apiUrls.TRACKER.SETTINGS.DELETE, params),
          payload,
          {
            data: { changedField },
          }
        ),
      saveSensors: (params, payload) =>
        apiAction(TRACKER_SENSORS_SETTINGS_SAVE, 'PATCH', resolveUrl(apiUrls.TRACKER.SETTINGS.SAVE, params), payload),
      cleanGet: () => ({ type: TRACKER_SETTINGS_GET + CLEAR }),
      cleanSaved: () => ({ type: TRACKER_SETTINGS_SAVE + CLEAR }),
    },
    flights: {
      get: (params, query) =>
        apiAction(TRACKER_FLIGHTS_INFO_GET, 'GET', resolveUrl(apiUrls.TRACKER.FLIGHT.GET, params, query), null, {
          afterSagaSuccess: function* () {
            yield put(trackersActions.flights.cleanSearch());
          },
        }),
      set: (params, payload) =>
        apiAction(TRACKER_FLIGHTS_INFO_SET, 'POST', resolveUrl(apiUrls.TRACKER.FLIGHT.SET, params), payload, {
          afterSagaSuccess: function* () {
            yield put(trackersActions.flights.cleanSearch());
            yield put(trackersActions.tracker.flights.get(params));
          },
        }),
      delete: (params) =>
        apiAction(TRACKER_FLIGHTS_INFO_DELETE, 'DELETE', resolveUrl(apiUrls.TRACKER.FLIGHT.DELETE, params), null, {
          afterSagaSuccess: function* () {
            yield put(trackersActions.tracker.flights.get(params));
          },
        }),
      cleanSet: () => ({ type: TRACKER_FLIGHTS_INFO_SET + CLEAR }),
    },
    channels: {
      get: (trackerId) =>
        apiAction(TRACKER_CHATBOT_CHANNELS_GET, 'GET', resolveUrl(apiUrls.TRACKER.CHANNELS.GET, { trackerId })),
      delete: (params) =>
        apiAction(
          TRACKER_CHATBOT_CHANNELS_DELETE,
          'DELETE',
          resolveUrl(apiUrls.TRACKER.CHANNELS.DELETE, params),
          null,
          {
            afterSaga: function* () {
              yield put(trackersActions.tracker.channels.get(params.trackerId));
            },
          }
        ),
    },
    migrate: (payload) => apiAction(TRACKERS_MIGRATE, 'PATCH', apiUrls.TRACKER.MIGRATE, payload),
  },
  flights: {
    search: (flightNumber, query, trackerId) =>
      apiAction(FLIGHTS_SEARCH, 'GET', resolveUrl(apiUrls.SEARCH.FLIGHTS, { flightNumber }, query), null, {
        trackerId,
        afterSaga: function* () {
          yield put(trackersActions.tracker.flights.cleanSet());
        },
      }),
    cleanSearch: () => ({ type: FLIGHTS_SEARCH + CLEAR }),
  },
  list: {
    exportCsv: (query) =>
      apiAction(TRACKERS_TABLE_EXPORT, 'GET', resolveUrl(apiUrls.TRACKERS_TABLE.GET, null, query), null, {
        afterSagaSuccess: function* (data) {
          const HEADERS = Object.values(TRACKERS_DATA_TABLE).map((item) => item.header);
          const convertedArrays =
            data?.trackers.map((item) =>
              Object.keys(TRACKERS_DATA_TABLE).map((key) => {
                if (TRACKERS_DATA_TABLE[key].data_key) {
                  return item[TRACKERS_DATA_TABLE[key].data_key];
                } else {
                  return item[key];
                }
              })
            ) || [];

          // create & download a .CSV file
          const ws = XLSX.utils.aoa_to_sheet([HEADERS, ...convertedArrays]);
          const csv = XLSX.utils.sheet_to_csv(ws, {
            rawNumbers: true,
          });
          const csvData = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
          FileSaver.saveAs(csvData, 'trackers.csv');

          yield put(trackersActions.list.cleanExportCsv());
        },
      }),
    get: (query) => apiAction(TRACKERS_TABLE_GET, 'GET', resolveUrl(apiUrls.TRACKERS_TABLE.GET, null, query)),
    cleanExportCsv: () => ({ type: TRACKERS_TABLE_EXPORT + CLEAR }),
    cleanGet: () => ({ type: TRACKERS_TABLE_GET + CLEAR }),
  },
};
