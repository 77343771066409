import { all, call, put, take } from 'redux-saga/effects';

import { apiSaga, createSettingsResetSaga } from 'base/redux/utils';
import { request, getError } from 'common/utils';

import { REQUEST, SUCCESS, FAILURE } from 'base/redux/consts';
import { commonActions } from 'common/redux/actions';
import { accountActions } from 'modules/account/redux/actions';

import {
  PARTNERS_GET,
  PARTNERS_CREATE,
  PARTNERS_DELETE,
  SETTINGS_GET,
  SETTINGS_SAVE,
  FIRMWARES_GET,
  FIRMWARES_USED,
  FIRMWARES_CREATE,
  FIRMWARES_DELETE,
  DEVICES_IMPORT,
  PARTNER_CUSTOMERS_GET,
  PARTNER_CUSTOMERS_CREATE,
  PARTNER_CUSTOMERS_DELETE,
  PARTNER_TCC_ADMINS_GET,
  PARTNER_TCC_ADMINS_CREATE,
  PARTNER_TCC_ADMINS_DELETE,
  PARTNER_BLE_FILTERS_GET,
  PARTNER_BLE_FILTERS_CREATE,
  PARTNER_BLE_FILTERS_DELETE,
  PARTNER_BLE_FILTERS_EDIT,
  PARTNER_USERS_GET,
  PARTNER_USERS_CREATE,
  PARTNER_USERS_DELETE,
  PARTNER_SETTINGS_GET,
  PARTNER_SETTINGS_SAVE,
  PARTNER_SETTINGS_DELETE,
  PARTNER_SETTINGS_DELETE_ALL,
  PARTNER_SENSORS_SETTINGS_SAVE,
  PARTNER_GROUPS_GET,
  PARTNER_GROUP_CREATE,
  PARTNER_GROUP_EDIT,
  PARTNER_GROUP_DELETE,
  PARTNER_GROUP_DEVICES_GET,
  PARTNER_GROUP_DEVICE_CREATE,
  PARTNER_GROUP_DEVICE_DELETE,
  PARTNER_KEYS_GET,
  PARTNER_KEYS_CREATE,
  PARTNER_KEYS_DELETE,
  menuActions,
  managementActions,
  PARTNER_BLE_SCRIPTS_EDIT,
  PARTNER_BLE_SCRIPTS_DELETE,
  PARTNER_BLE_SCRIPTS_CREATE,
} from './actions';

export function* createNewPartnerFlow() {
  while (true) {
    const action = yield take(PARTNERS_CREATE + REQUEST);
    try {
      const { data } = yield call(request, action.method, action.endpoint, action.payload);
      yield put({
        type: PARTNERS_CREATE + SUCCESS,
        data,
      });
      yield put(menuActions.goBack());
    } catch (error) {
      yield put({
        type: PARTNERS_CREATE + FAILURE,
        error: getError(error),
      });
      if (error.response?.status === 401) {
        yield put(accountActions.clearSession());
      }
    }
  }
}

export function* deletePartnerFlow() {
  while (true) {
    const action = yield take(PARTNERS_DELETE + REQUEST);
    try {
      const { data } = yield call(request, action.method, action.endpoint, action.payload);
      //Fetch customers when customer is deleted
      const companyId = action.options?.data?.companyId;
      if (companyId) {
        yield put({
          type: PARTNER_CUSTOMERS_DELETE + SUCCESS,
          data,
          requestAction: {
            options: {
              params: { companyId },
            },
          },
        });
      } else {
        yield put({
          type: PARTNERS_DELETE + SUCCESS,
          data,
        });
        yield put(managementActions.partners.get());
      }
    } catch (error) {
      yield put({
        type: PARTNERS_CREATE + FAILURE,
        error: getError(error),
      });
      if (error.response?.status === 401) {
        yield put(accountActions.clearSession());
      }
    }
  }
}

export function* watchCustomersChanges() {
  while (true) {
    const action = yield take([PARTNER_CUSTOMERS_CREATE + SUCCESS, PARTNER_CUSTOMERS_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.customers.get(options.params));
  }
}

export function* watchTCCAdminsChanges() {
  while (true) {
    const action = yield take([PARTNER_TCC_ADMINS_CREATE + SUCCESS, PARTNER_TCC_ADMINS_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.tccAdmins.get(options.params));
  }
}

export function* watchBLEFiltersChanges() {
  while (true) {
    const action = yield take([PARTNER_BLE_FILTERS_CREATE + SUCCESS, PARTNER_BLE_FILTERS_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.bleFilters.get(options.params));
  }
}

export function* watchUsersChanges() {
  while (true) {
    const action = yield take([PARTNER_USERS_CREATE + SUCCESS, PARTNER_USERS_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.users.get(options.params));
  }
}

export function* watchGroupsChanges() {
  while (true) {
    const action = yield take([PARTNER_GROUP_CREATE + SUCCESS, PARTNER_GROUP_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.groups.get(options.params));
    // Update groups in search filters
    yield put(commonActions.groups.get());
  }
}

export function* watchDevicesChanges() {
  while (true) {
    const action = yield take([PARTNER_GROUP_DEVICE_CREATE + SUCCESS, PARTNER_GROUP_DEVICE_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.groups.devices.get(options.params));
  }
}

export function* watchApiKeysChanges() {
  while (true) {
    const action = yield take([PARTNER_KEYS_CREATE + SUCCESS, PARTNER_KEYS_DELETE + SUCCESS]);
    const options = action.requestAction.options;
    yield put(managementActions.partner.keys.get(options));
  }
}

export function* partnerSaga() {
  yield all([
    apiSaga(PARTNERS_GET)(),
    createNewPartnerFlow(),
    deletePartnerFlow(),

    apiSaga(SETTINGS_GET)(),
    apiSaga(SETTINGS_SAVE)(),
    createSettingsResetSaga(SETTINGS_SAVE)(),

    apiSaga(FIRMWARES_GET)(),
    apiSaga(FIRMWARES_USED)(),
    apiSaga(FIRMWARES_CREATE)(),
    apiSaga(FIRMWARES_DELETE)(),

    apiSaga(DEVICES_IMPORT)(),

    apiSaga(PARTNER_CUSTOMERS_GET)(),
    apiSaga(PARTNER_CUSTOMERS_CREATE)(),
    apiSaga(PARTNER_CUSTOMERS_DELETE)(),
    watchCustomersChanges(),

    apiSaga(PARTNER_TCC_ADMINS_GET)(),
    apiSaga(PARTNER_TCC_ADMINS_CREATE)(),
    apiSaga(PARTNER_TCC_ADMINS_DELETE)(),
    watchTCCAdminsChanges(),

    apiSaga(PARTNER_BLE_FILTERS_GET)(),
    apiSaga(PARTNER_BLE_FILTERS_CREATE)(),
    apiSaga(PARTNER_BLE_FILTERS_EDIT)(),
    apiSaga(PARTNER_BLE_FILTERS_DELETE)(),
    apiSaga(PARTNER_BLE_SCRIPTS_CREATE)(),
    apiSaga(PARTNER_BLE_SCRIPTS_EDIT)(),
    apiSaga(PARTNER_BLE_SCRIPTS_DELETE)(),
    watchBLEFiltersChanges(),

    apiSaga(PARTNER_USERS_GET)(),
    apiSaga(PARTNER_USERS_CREATE)(),
    apiSaga(PARTNER_USERS_DELETE)(),
    watchUsersChanges(),

    apiSaga(PARTNER_SETTINGS_GET)(),
    apiSaga(PARTNER_SETTINGS_SAVE)(),
    apiSaga(PARTNER_SETTINGS_DELETE)(),
    apiSaga(PARTNER_SETTINGS_DELETE_ALL)(),
    createSettingsResetSaga(PARTNER_SETTINGS_SAVE)(),
    createSettingsResetSaga(PARTNER_SETTINGS_DELETE)(),
    apiSaga(PARTNER_SENSORS_SETTINGS_SAVE)(),

    apiSaga(PARTNER_GROUPS_GET)(),
    apiSaga(PARTNER_GROUP_CREATE)(),
    apiSaga(PARTNER_GROUP_EDIT)(),
    apiSaga(PARTNER_GROUP_DELETE)(),
    watchGroupsChanges(),

    apiSaga(PARTNER_GROUP_DEVICES_GET)(),
    apiSaga(PARTNER_GROUP_DEVICE_CREATE)(),
    apiSaga(PARTNER_GROUP_DEVICE_DELETE)(),
    watchDevicesChanges(),

    apiSaga(PARTNER_KEYS_GET)(),
    apiSaga(PARTNER_KEYS_CREATE)(),
    apiSaga(PARTNER_KEYS_DELETE)(),
    watchApiKeysChanges(),
  ]);
}
