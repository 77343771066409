import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const CreateGroupForm = ({ onSubmit, errors, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="manage-content-control">
        <div className="row">
          <div className="gr-grow">
            <Input name="name" placeholder="Type group name" required />
          </div>
          <Button type="submit" disabled={isSubmitting}>
            Create group
          </Button>
        </div>
      </div>
    </Form>
  );
};

CreateGroupForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default CreateGroupForm;
