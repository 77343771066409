import React from 'react';
import PropTypes from 'prop-types';

import { menuPaths } from 'modules/menu/consts';

const AccountView = ({ setMenuPath }) => {
  const handleClick = (path) => {
    setMenuPath(path);
  };

  return (
    <div className="manage-view account">
      <h2 className="manage-view-header">Account</h2>
      <p className="account-item" onClick={() => handleClick(menuPaths.ACCOUNT_PASSWORD)}>
        Change password
      </p>
      <p className="account-item" onClick={() => handleClick(menuPaths.ACCOUNT_EMAIL)}>
        Change e-mail
      </p>
      <p className="account-item" onClick={() => handleClick(menuPaths.ACCOUNT_2FA)}>
        Change 2FA
      </p>
    </div>
  );
};

AccountView.propTypes = {
  setMenuPath: PropTypes.func,
};

export default AccountView;
