import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CopyInput from 'common/components/CopyInput';
import ModalAlert from 'common/components/ModalAlert';
import Loader from 'common/components/Loader';
import CreateKeysForm from 'modules/menu/components/CreateKeysForm';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';
import { LOADER_COLOR_BLUE } from 'common/components/Loader/consts';

const selector = ({ management }) => {
  const { keys } = management.partner;
  return {
    keysList: {
      data: keys.list.data,
      isFetching: keys.list.isFetching,
    },
    createdKey: {
      data: keys.created.data,
      isFetching: keys.created.isFetching,
      error: keys.created.error,
    },
    deletedKey: {
      isFetching: keys.deleted.isFetching,
    },
  };
};

const ManageKeysView = ({ partner }) => {
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);
  const dispatch = useDispatch();
  const { keysList, createdKey, deletedKey } = useSelector(selector);

  useEffect(() => {
    dispatch(managementActions.partner.keys.get({ companyId: partner.id }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    createdKey.data && setIsModalAlertOpen(true);
  }, [createdKey.data]);

  const handleModalAlertClose = () => {
    dispatch(managementActions.partner.keys.cleanCreated());
    setIsModalAlertOpen(false);
  };

  const handleApiKeyCreate = (name) => {
    dispatch(managementActions.partner.keys.create({ companyId: partner.id }, name));
  };

  const handleDeleteKey = (key) => {
    displayConfirmDialog().then(() => {
      dispatch(managementActions.partner.keys.delete({ companyId: partner.id, keyId: key }));
    });
  };

  const renderModalAlert = () => {
    const { data, isFetching } = createdKey;

    return (
      <ModalAlert isOpen={isModalAlertOpen} onCloseClick={handleModalAlertClose}>
        <p className="create-keys-info">
          Make sure to copy your new Secret Key now. You won’t be able to see it again!
        </p>
        <div className="manage-keys-item">
          <span className="manage-keys-item-label">API Key</span>
          <CopyInput value={(data && data.api_key) || ''} />
        </div>
        <div className="manage-keys-item">
          <span className="manage-keys-item-label">Secret Key</span>
          <CopyInput value={(data && data.secret_key) || ''} />
        </div>
        <Loader loaded={!isFetching} color={LOADER_COLOR_BLUE} />
      </ModalAlert>
    );
  };

  const keys = keysList.data?.api_keys;

  return (
    <div className="manage-keys">
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">API keys</p>
      </div>
      <CreateKeysForm onSubmit={handleApiKeyCreate} errors={createdKey.error} isSubmitting={createdKey.isFetching} />
      <div className="manage-list-content">
        {createdKey.error ? null : renderModalAlert()}
        {keys?.length ? (
          keys.map((key) => (
            <div key={key.api_key} className="manage-keys-item">
              <span className="manage-keys-item-label">{key.name}</span>
              <div className="manage-keys-item-wrapper">
                <CopyInput value={key.api_key} className="manage-keys-item-input" />
                <div onClick={() => handleDeleteKey(key.api_key)} className="manage-keys-item-delete" />
              </div>
            </div>
          ))
        ) : (
          <div className="manage-keys-item">There are no keys</div>
        )}
        <Loader loaded={!(keysList.isFetching || deletedKey.isFetching)} />
      </div>
    </div>
  );
};

ManageKeysView.propTypes = {
  partner: PropTypes.object,
};

export default ManageKeysView;
