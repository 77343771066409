import React from 'react';
import PropTypes from 'prop-types';

import MenuContainer from 'modules/menu/containers/MenuContainer';

const PartnerLayout = ({ children }) => {
  return (
    <>
      {children}
      <MenuContainer />
    </>
  );
};

PartnerLayout.propTypes = {
  children: PropTypes.node,
};

export default PartnerLayout;
