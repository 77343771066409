import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const SetPasswordForm = ({ onSubmit, error, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={error}>
      <div className="auth-form-inputs">
        <Input type="password" name="password" placeholder="New password" required />
        <Input
          type="password"
          name="confirmed_password"
          placeholder="Confirm new password"
          validations="equalsField:password"
          validationErrors={{ equalsField: 'Passwords must match' }}
          required
        />
      </div>
      <div className="auth-form-control">
        <Button type="submit" disabled={isSubmitting}>
          Save
        </Button>
      </div>
    </Form>
  );
};

SetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default SetPasswordForm;
