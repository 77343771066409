import { combineReducers } from 'redux';

import { apiReducer } from 'base/redux/utils';
import { FETCH_API_KEYS_LIST, CREATE_API_KEY, DELETE_API_KEY } from './actions';

export const partners = combineReducers({
  apiKeys: combineReducers({
    list: apiReducer(FETCH_API_KEYS_LIST),
    created: apiReducer(CREATE_API_KEY),
    deleted: apiReducer(DELETE_API_KEY),
  }),
});
