import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import SignInForm from 'modules/auth/components/SignInForm';
import { authActions } from 'modules/auth/redux/actions';

const selector = ({ auth: { signIn } }) => ({
  isFetching: signIn.isFetching,
  error: signIn.error,
});

const SignInView = () => {
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector(selector);

  const handleSubmit = (values) => {
    dispatch(authActions.signIn(values));
  };

  return (
    <div className="center-text">
      <div className="auth-form">
        <h1>Login</h1>
        <SignInForm onSubmit={handleSubmit} error={error} isSubmitting={isFetching} />
        <Loader loaded={!isFetching} />
      </div>
    </div>
  );
};

export default SignInView;
