import { useMutation } from 'react-query';

import { axios } from './axios';
import { resolveUrl } from 'common/utils';
import { apiUrls } from 'urls';
import { EmptySchema } from './models/EmptySchema';
import { ErrorSchema } from './models/ErrorSchema';

interface PartnerChannelsDeleteParamsSchema {
  companyId: string;
}

const usePartnerChannelsDelete = () => {
  return useMutation<EmptySchema, ErrorSchema, PartnerChannelsDeleteParamsSchema>((values) =>
    axios.delete(resolveUrl(apiUrls.PARTNER.CHANNELS.DELETE, values))
  );
};

export { usePartnerChannelsDelete };
