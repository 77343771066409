import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

const Button = ({ children, url, className, active, inverse, disabled, full, ...rest }) => {
  const buttonClass = cx('vrs-button', className, {
    'vrs-button-active': active,
    'vrs-button-inverse': inverse,
    'vrs-button-disabled': disabled,
    'vrs-button-full': full,
  });
  const Element = url ? Link : 'button';

  return (
    <Element to={url} className={buttonClass} disabled={disabled} {...rest}>
      {children}
    </Element>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  active: PropTypes.bool,
  inverse: PropTypes.bool,
  disabled: PropTypes.bool,
  full: PropTypes.bool,
};

export default Button;
