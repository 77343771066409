export const appUrls = {
  ROOT: '/',
  TRACKERS: {
    BASE: '/:trackerId?',
    EXPORT_LOG: '/:trackerId/export',
    DETAILS: '/:trackerId/details',
    SETTINGS: '/:trackerId/settings',
    BLE_DEVICES: '/:trackerId/ble-devices',
    FLIGHT: '/:trackerId/flight',
    CHANNELS: '/:trackerId/channels',
    MANAGEMENT: '/trackers/management',
    CONFIGURATION: '/:trackerId/configuration',
  },
  AUTH: {
    SIGN_IN: '/sign-in',
    RESET_PASSWORD: '/reset-password',
    SET_PASSWORD_API: '/password_reset/:token',
    SET_PASSWORD: '/set-password',
    SETUP_2FA: '/setup-2fa',
    VERIFY_CODE: '/verify',
    ACTIVATE_ACCOUNT_API: '/create_user/:token',
    ACTIVATE_ACCOUNT: '/activate-account',
    CONFIRM_NEW_EMAIL_API: '/confirm-email-change/:token',
    CONFIRM_NEW_EMAIL: '/confirm-email-change',
  },
};

export const apiUrls = {
  AUTH: {
    SIGN_IN: '/auth/login/',
    SIGN_OUT: '/auth/logout/',
    RESET_PASSWORD: '/auth/password-reset/',
    SET_PASSWORD: '/auth/confirm-account/',
    SETUP_2FA: '/auth/setup-otp/',
    VERIFY_2FA: '/auth/verify-otp/',
    USER: '/auth/user/',
    CONFIRM_NEW_EMAIL: '/auth/confirm-email-change/:token',
  },
  ACCOUNT: {
    USER: '/user/',
    CHANGE_PASSWORD: '/user/change-password/',
    CHANGE_EMAIL: '/user/change-email/',
    CHANGE_2FA: '/user/change-otp/',
  },
  COMMON: {
    GROUPS: '/groups/',
  },
  PARTNERS: {
    GET: '/companies/',
    CREATE: '/companies/',
    DELETE: '/companies/:companyId/',
  },
  SETTINGS: {
    GET: '/configurations/:model/',
    SAVE: '/configurations/:model/',
  },
  FIRMWARES: {
    GET: '/firmwares/',
    CREATE: '/firmwares/',
    DELETE: '/firmwares/:firmwareId/',
  },
  DEVICES: {
    IMPORT: '/devices/import/',
  },
  PARTNER: {
    CUSTOMERS: {
      GET: '/companies/:companyId/customers/',
      CREATE: '/companies/:companyId/customers/',
      DELETE: '/companies/:companyId/customers/:customerId/',
    },
    TCC_ADMINS: {
      GET: '/companies/:companyId/users/tcc/',
      CREATE: '/companies/:companyId/users/tcc/',
      DELETE: '/companies/:companyId/users/tcc/:username/',
    },
    USERS: {
      GET: '/companies/:companyId/users/',
      CREATE: '/companies/:companyId/users/',
      DELETE: '/companies/:companyId/users/:userId/',
    },
    BLE_FILTERS: {
      GET: '/companies/:companyId/ble-filters/',
      CREATE: '/companies/:companyId/ble-filters/',
      DELETE: '/companies/:companyId/ble-filters/:bleFilterId/',
      EDIT: '/companies/:companyId/ble-filters/:bleFilterId/',
    },
    BLE_SCRIPTS: {
      CREATE: '/companies/:companyId/ble-scripts/',
      EDIT: '/companies/:companyId/ble-scripts/:bleScriptId/',
      DELETE: '/companies/:companyId/ble-scripts/:bleScriptId/',
    },
    SETTINGS: {
      GET: '/companies/:companyId/configurations/:model/',
      SAVE: '/companies/:companyId/configurations/:model/',
      DELETE: '/companies/:companyId/configurations/:model/',
    },
    GROUPS: {
      GET: '/companies/:companyId/groups/',
      CREATE: '/companies/:companyId/groups/',
      EDIT: '/companies/:companyId/groups/:groupId/',
      DELETE: '/companies/:companyId/groups/:groupId/',
      GET_DEVICES: '/companies/:companyId/groups/:groupId/trackers/',
      ADD_DEVICE: '/companies/:companyId/groups/:groupId/trackers/',
      DELETE_DEVICE: '/companies/:companyId/groups/:groupId/trackers/:groupDeviceId/',
    },
    KEYS: {
      GET: '/companies/:companyId/apikeys/',
      CREATE: '/companies/:companyId/apikeys/',
      DELETE: '/companies/:companyId/apikeys/:keyId/',
    },
    CHANNELS: {
      DELETE: '/companies/:companyId/channels/',
    },
  },
  SEARCH: {
    TRACKERS: '/search/map/',
    TRACKER_SUGGESTIONS: '/suggestions/trackers/',
    FLIGHTS: '/flights/:flightNumber/',
  },
  TRACKER: {
    DETAILS: '/devices/:trackerId/',
    EXPORT: '/devices/:trackerId/audit/',
    PARTNER: '/devices/change-partner/',
    GROUPS: '/devices/:trackerId/groups/',
    SETTINGS: {
      GET: '/devices/:trackerId/configuration/',
      SAVE: '/devices/:trackerId/configuration/',
      DELETE: '/devices/:trackerId/configuration/',
    },
    FLIGHT: {
      GET: '/devices/:trackerId/flights/',
      SET: '/devices/:trackerId/flights/',
      SAVE: '/devices/:trackerId/flight/',
      DELETE: '/devices/:trackerId/flights/:flightId',
    },
    BLE_DEVICES: {
      NOTICES: '/devices/:trackerId/ble-devices/notices/',
    },
    CHANNELS: {
      GET: '/flowai/channels/device/:trackerId/',
      DELETE: '/flowai/channels/device/:trackerId/:userId/:channel/',
    },
    MIGRATE: '/migrate',
  },
  TRACKERS_TABLE: {
    GET: '/search/trackers',
  },
};
