import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Checkbox from 'common/components/Checkbox';
import Form from 'common/components/Form';
import Upload from 'common/components/Upload';
import Select from 'common/components/Select';
import { deviceModelOptions } from 'common/components/SettingsForm/utils';

const CreateFirmwareForm = ({ onSubmit, errors, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="create-firmware-form">
        <div className="create-firmware-form-controls">
          <Upload name="file" required />
          <div className="create-firmware-form-controls-row">
            <Checkbox name="stable" label="Stable version" className="create-firmware-form-checkbox" />
            <Select
              name="version"
              options={deviceModelOptions}
              placeholder="Device model"
              className="create-firmware-form-selector"
              required
            />
          </div>
        </div>
        <Button type="submit" disabled={isSubmitting}>
          Add firmware
        </Button>
      </div>
    </Form>
  );
};

CreateFirmwareForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default CreateFirmwareForm;
