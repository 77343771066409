import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { find } from 'lodash';

import TrackerDetailsPopup from 'modules/trackers/components/TrackerDetailsPopup';
import TrackersListPopup from 'modules/trackers/components/TrackersListPopup';
import Popup from './Popup';

import { usePrevious } from 'common/effects/usePrevious';
import { trackersActions } from 'modules/trackers/redux/actions';
import { getClosestFlightIndex } from 'modules/trackers/utils';
import { limitLatitude } from './utils';
import { mapOptions } from './consts';
import { appUrls } from 'urls';

import markerIcon from 'assets/icons/map-marker.svg';
import markerGroupIcon from 'assets/icons/map-marker-group.svg';

const TrackersMap = ({
  geohashes,
  trackers,
  isPagination,
  match,
  getTrackers,
  showTrackerDetails,
  dispatch,
  selectedTracker,
  flightInfo,
  missingTrackerDetails,
  isAdmin,
}) => {
  const map = useRef(null);
  const markers = useRef([]);
  const mapListeners = useRef({});
  const detailsPopup = useRef(null);
  const multipleTrackersPopup = useRef(null);
  const maxZoomService = useRef(null);
  const isMapLoaded = useRef(false);

  const mapRef = useRef(null);
  const detailsPopupRef = useRef(null);
  const multipleTrackersPopupRef = useRef(null);

  const prevSelectedTracker = usePrevious(selectedTracker);
  const [multipleTrackersList, setMultipleTrackersList] = useState([]);

  /**
   * Handles the map bounds change.
   * @param {boolean} delay
   * @param {number} trackerId
   */
  const updateMap = useCallback(
    (delay, trackerId) => {
      if (!selectedTracker?.uiOptions.isSingle) {
        // Get bounds of visible part of the map
        const mapBounds = map.current.getBounds();
        const topLeftCorner = { lat: mapBounds.getNorthEast().lat(), lng: mapBounds.getSouthWest().lng() };
        const bottomRightCorner = { lat: mapBounds.getSouthWest().lat(), lng: mapBounds.getNorthEast().lng() };

        // Calculate precision based on distance between the bounds
        const from = new window.google.maps.LatLng(topLeftCorner);
        const to = new window.google.maps.LatLng(bottomRightCorner);
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(from, to);
        const precision = `${Math.round(distance)}m`;

        // Get new trackers to display
        localStorage.setItem('map', JSON.stringify({ zoom: map.current.getZoom(), center: map.current.getCenter() }));
        getTrackers({ topLeftCorner, bottomRightCorner, precision, trackerId }, delay);
      }
    },
    [selectedTracker, getTrackers]
  );

  /**
   * Removes all the markers from the map.
   */
  const cleanMarkers = () => {
    for (let i = 0; i < markers.current.length; i++) {
      markers.current[i].setMap(null);
    }
    markers.current = [];
  };

  /**
   * Draws single marker (location pin) on the map.
   * @param {Object} location
   */
  const drawSingleMarker = (location) => {
    // Remove all the markers
    cleanMarkers();

    // Draw single marker on the map
    const marker = new window.google.maps.Marker({
      position: { lat: limitLatitude(location.lat), lng: location.lon },
      icon: { url: markerIcon, scaledSize: { width: 28, height: 40 } },
      map: map.current,
    });

    markers.current.push(marker);
  };

  /**
   * Draws markers (geohashes) on the map.
   * @param {Array} geohashes
   */
  const drawMarkers = (geohashes) => {
    // Remove all the markers
    cleanMarkers();

    // Draw markers on the map
    geohashes.forEach((geohash) => {
      const isGroupMarker = geohash.count > 1;
      const marker = new window.google.maps.Marker({
        position: { lat: limitLatitude(geohash.lat), lng: geohash.lon },
        label: isGroupMarker ? { text: geohash.count.toString(), color: 'white', fontFamily: 'myriad-pro' } : '',
        icon: isGroupMarker
          ? {
              url: markerGroupIcon,
              scaledSize: { width: 50, height: 50 },
              labelOrigin: { x: 25, y: 18 },
            }
          : { url: markerIcon, scaledSize: { width: 28, height: 40 } },
        map: map.current,
      });

      marker.addListener('click', () => {
        if (isGroupMarker) {
          const markerPosition = marker.getPosition();
          const mapZoom = map.current.getZoom();

          // Show the multiple trackers popup or zoom in the map
          maxZoomService.current.getMaxZoomAtLatLng(markerPosition, (response) => {
            if (response.status === 'OK' && response.zoom > mapZoom) {
              map.current.setCenter(markerPosition);
              map.current.setZoom(mapZoom + 1);
            } else {
              setMultipleTrackersList(geohash.devices);
              multipleTrackersPopup.current.setMap(map.current);
              multipleTrackersPopup.current.setPosition(marker.position);
            }
          });
        } else {
          showTrackerDetails(geohash.top, { isSingle: false });
        }
      });

      markers.current.push(marker);
    });
  };

  /**
   * Handles details popup close.
   */
  const handleDetailsPopupClose = () => {
    dispatch(trackersActions.tracker.setSelectedTracker(null));
    dispatch(push(appUrls.ROOT));
  };

  /**
   * Gets tracker details for selected tracker from the multiple trackers popup.
   * @param {string} trackerId
   */
  const handleMultipleTrackersItemClick = (trackerId) => {
    const tracker = find(trackers, { id: trackerId });

    if (tracker) {
      showTrackerDetails(tracker);
    } else {
      dispatch(trackersActions.search.getTrackerSuggestion(trackerId));
    }
  };

  /**
   * Initializes the map.
   */
  useEffect(() => {
    // Initialize the map and popups
    const gMap = new window.google.maps.Map(mapRef.current, mapOptions);

    detailsPopup.current = new Popup(detailsPopupRef.current, handleDetailsPopupClose);
    multipleTrackersPopup.current = new Popup(multipleTrackersPopupRef.current);

    // Add event listener to the map
    gMap.addListener('projection_changed', () => {
      if (!isMapLoaded.current) {
        const trackerId = match.params.trackerId;
        if (!isNaN(trackerId)) {
          // Get single tracker when page with specific tracker ID is loaded (single tracker mode)
          updateMap(false, trackerId);
        } else {
          updateMap();
        }
      }

      isMapLoaded.current = true;
    });

    // Define maximum zoom imagery service
    maxZoomService.current = new window.google.maps.MaxZoomService();

    // Save the map to the instance variable
    map.current = gMap;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Updates the events listeners for the map due to changing the 'updateMap' function.
   */
  useEffect(() => {
    // Add event listeners to the map
    if (!selectedTracker) {
      mapListeners.current.drag = map.current.addListener('dragend', () => updateMap());
      mapListeners.current.zoom = map.current.addListener('zoom_changed', () => updateMap(true));
    }

    return () => {
      if (!selectedTracker) {
        window.google.maps.event.removeListener(mapListeners.current.drag);
        window.google.maps.event.removeListener(mapListeners.current.zoom);
        mapListeners.current = {};
      }
      // Remove event listeners from the map
    };
  }, [updateMap]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Draws markers on the map only if pagination is off (page parameter isn't pass to trackers request)
   */
  useEffect(() => {
    if (!isPagination) {
      drawMarkers(geohashes);
    }
  }, [geohashes, isPagination]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Manages the selected tracker changes.
   */
  useEffect(() => {
    if (selectedTracker) {
      const {
        location: { lat, lon },
        uiOptions: { isCenter, isSingle },
      } = selectedTracker;
      const position = new window.google.maps.LatLng(limitLatitude(lat), lon);

      dispatch(trackersActions.tracker.flights.get({ trackerId: selectedTracker.id }, { limit: 100 }));
      detailsPopup.current.setMap(map.current);
      detailsPopup.current.setPosition(position);

      if (isCenter) {
        map.current.setCenter(position);
      }
      if (isSingle) {
        map.current.setZoom(12);
        map.current.setCenter(position);
        drawSingleMarker(selectedTracker.location);
      }
    } else {
      // Get trackers after close the popup in the single tracker mode
      if (prevSelectedTracker?.uiOptions.isSingle) {
        updateMap();
      }
    }
  }, [selectedTracker]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Shows details of the selected tracker from the trackers list popup (when an additional request is made for details).
   */
  useEffect(() => {
    if (missingTrackerDetails) {
      showTrackerDetails(missingTrackerDetails);
    }
  }, [missingTrackerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="trackers-map" ref={mapRef} />
      <TrackerDetailsPopup
        trackerInfo={selectedTracker}
        flightInfo={flightInfo}
        isAdmin={isAdmin}
        ref={detailsPopupRef}
      />
      <TrackersListPopup
        trackers={multipleTrackersList}
        onTrackerClick={handleMultipleTrackersItemClick}
        ref={multipleTrackersPopupRef}
      />
    </>
  );
};

TrackersMap.propTypes = {
  geohashes: PropTypes.array,
  trackers: PropTypes.array,
  isPagination: PropTypes.bool,
  match: PropTypes.object,
  getTrackers: PropTypes.func,
  showTrackerDetails: PropTypes.func,
  // Redux props
  dispatch: PropTypes.func,
  selectedTracker: PropTypes.object,
  flightInfo: PropTypes.object,
  missingTrackerDetails: PropTypes.object,
  isAdmin: PropTypes.bool,
};

const mapStateToProps = ({ account, trackers }) => {
  const flightsData = trackers.tracker.flights.list.data;

  return {
    selectedTracker: trackers.tracker.selected,
    flightInfo: flightsData?.flights[getClosestFlightIndex(flightsData.flights)],
    missingTrackerDetails: trackers.search.trackerSuggestion.data,
    isAdmin: account.currentUser.isAdmin,
  };
};

export default connect(mapStateToProps)(TrackersMap);
