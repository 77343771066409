import React from 'react';
import PropTypes from 'prop-types';

import { menuPaths } from 'modules/menu/consts';

const MainMenu = ({ setMenuPath, fetchPartnersList, onSignOut, isAdmin }) => {
  const handlePartnersClick = () => {
    setMenuPath(menuPaths.PARTNERS_LIST);
    fetchPartnersList();
  };

  return (
    <div className="main-menu">
      {isAdmin && (
        <React.Fragment>
          <div className="main-menu-item" onClick={handlePartnersClick}>
            Partners
          </div>
          <div className="main-menu-item" onClick={() => setMenuPath(menuPaths.SETTINGS)}>
            Settings
          </div>
          <div className="main-menu-item" onClick={() => setMenuPath(menuPaths.FIRMWARES)}>
            Firmware
          </div>
          <div className="main-menu-item" onClick={() => setMenuPath(menuPaths.DEVICES)}>
            Devices
          </div>
        </React.Fragment>
      )}
      <div className="main-menu-item" onClick={() => setMenuPath(menuPaths.ACCOUNT)}>
        Account
      </div>
      <div className="main-menu-item" onClick={onSignOut}>
        Logout
      </div>
    </div>
  );
};

MainMenu.propTypes = {
  setMenuPath: PropTypes.func,
  fetchPartnersList: PropTypes.func,
  fetchSettings: PropTypes.func,
  onSignOut: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default MainMenu;
