import { addValidationRule } from 'formsy-react';
import moment from 'moment';

addValidationRule('isNotEmpty', function (values, value) {
  return value !== '';
});

addValidationRule('isLocation', function (values, value, otherField) {
  if (value === '' && values[otherField] === '') {
    return true;
  }
  if (value !== '' && values[otherField] === '') {
    return false;
  }
  return true;
});

addValidationRule('minNumber', function (values, value, number) {
  return value ? parseInt(value) >= parseInt(number) : true;
});

addValidationRule('maxNumber', function (values, value, number) {
  return value ? parseInt(value) <= parseInt(number) : true;
});

addValidationRule('isDateTime', function (values, value) {
  return value ? moment.isMoment(value) && value.isValid() : true;
});

addValidationRule('isGreaterOrEqualThan', function (values, value, otherField) {
  return value ? Number(value) >= Number(values[otherField]) : true;
});

addValidationRule('isLessOrEqualThan', function (values, value, otherField) {
  return value ? Number(value) <= Number(values[otherField]) : true;
});

addValidationRule('isEdited', function (values, value, prevValue) {
  return value === '' && (prevValue || prevValue === 0);
});
