import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IoMenuOutline, IoSearchOutline } from 'react-icons/io5';

import SearchFilters from 'modules/trackers/components/SearchFilters';
import HeaderAutosuggest from './HeaderAutosuggest';

import { menuActions } from 'modules/menu/redux/actions';
import { trackersActions } from 'modules/trackers/redux/actions';
import { appUrls } from 'urls';

import logo from 'assets/images/versa-logo.png';

const selector = ({ account, common, management, menu }) => ({
  isMenuOpen: menu.modal.isOpen,
  isAuthenticated: account.session.isAuthenticated,
  isAdmin: account.currentUser.isAdmin,
});

const Header = () => {
  const dispatch = useDispatch();
  const { isMenuOpen, isAuthenticated, isAdmin, searchFilters } = useSelector(selector);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);
  const transitions = useTransition(isHeaderOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const handleHeaderToggle = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };

  const handleMenuToggle = () => {
    if (isMenuOpen) {
      dispatch(menuActions.closeMenu());
    } else {
      dispatch(menuActions.openMenu());
    }
    dispatch(trackersActions.modal.close());
  };

  const handleFiltersChange = (payload) => {
    dispatch(trackersActions.search.setSearchFilters(payload));
  };

  return (
    <div className="header">
      <div className="header-content">
        {isAuthenticated && (
          <button className="pure-button" onClick={handleMenuToggle}>
            <IoMenuOutline title="Menu icon" size={43} className="inline-icon" />
          </button>
        )}
        <Link to={appUrls.ROOT} className="header-logo">
          <img src={logo} width={120} alt="versa" />
        </Link>
        {isAuthenticated && isAdmin && (
          <>
            <button className="pure-button hide@md" onClick={handleHeaderToggle}>
              <IoSearchOutline title="Search icon" size={32} />
            </button>
            <HeaderAutosuggest className="header-autosuggest-desktop" />
            <SearchFilters
              className="header-filters-desktop"
              filtersOptions={searchFilters}
              onFiltersChange={handleFiltersChange}
            />
          </>
        )}
      </div>
      {transitions(
        (style, item, { key }) =>
          item && (
            <animated.div key={key} style={style} className="header-collapse">
              <HeaderAutosuggest />
              <PerfectScrollbar>
                <SearchFilters
                  filtersOptions={searchFilters}
                  collapseMode={true}
                  onFiltersChange={handleFiltersChange}
                />
              </PerfectScrollbar>
            </animated.div>
          )
      )}
    </div>
  );
};

export default Header;
