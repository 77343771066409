import React from 'react';

import HighlightedText from 'common/components/HighlightedText';

export const renderTrackerSuggestion = (tracker, { query }) => {
  return (
    <div className="suggestion-container">
      <div className="suggestion-labels-block">
        <p>ID</p>
        <p>ICCID</p>
        <p>Partner</p>
      </div>
      <div className="suggestion-values-block">
        <p>
          <HighlightedText text={tracker.id.toString()} highlight={query} omit={tracker.found_by !== 'tracker_id'} />
        </p>
        <p>
          <HighlightedText text={tracker.iccid || 'N/A'} highlight={query} omit={tracker.found_by !== 'iccid'} />
        </p>
        <div className="suggestion-bottom">
          <p>{tracker.company_name}</p>
          {!tracker.location && <p className="suggestion-warning">Waiting for connection</p>}
        </div>
      </div>
    </div>
  );
};
