import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { debounce } from 'lodash';
import cx from 'classnames';

import Autosuggest from 'common/components/Autosuggest';

import { trackersActions } from 'modules/trackers/redux/actions';
import { renderTrackerSuggestion } from 'common/components/Autosuggest/renderTrackerSuggestion';
import { resolveUrl } from 'common/utils';
import { appUrls } from 'urls';

const selector = ({ trackers: { search } }) => ({
  trackerSuggestions: search.trackerSuggestions,
});

const HeaderAutosuggest = ({ className }) => {
  const dispatch = useDispatch();
  const { trackerSuggestions } = useSelector(selector);

  const onTrackersSearch = debounce(({ value }) => {
    const query = value.trim().toLowerCase();
    dispatch(trackersActions.search.getTrackerSuggestions(query));
  }, 300);

  const onTrackerSuggestionClick = (_, { suggestion }) => {
    if (suggestion.location) {
      dispatch(trackersActions.tracker.setSelectedTracker(suggestion, { isSingle: true }));
      dispatch(push(resolveUrl(appUrls.TRACKERS.BASE, { trackerId: suggestion.id })));
    }
  };

  const suggestions = trackerSuggestions.data;
  const error = trackerSuggestions.error;
  const info = suggestions?.length === 0 ? 'No suggestions' : '';
  const cleanSuggestions = () => dispatch(trackersActions.search.cleanSuggestions());

  return (
    <div className={cx('header-autosuggest-wrapper', className)} data-testid="autosuggest">
      <Autosuggest
        placeholder="Type tracker ID or ICCID"
        suggestions={suggestions ?? []}
        onSuggestionsFetchRequested={onTrackersSearch}
        onSuggestionsClearRequested={cleanSuggestions}
        onSuggestionSelected={onTrackerSuggestionClick}
        renderSuggestion={renderTrackerSuggestion}
        alert={error || info}
        className="header-autosuggest"
      />
    </div>
  );
};

HeaderAutosuggest.propTypes = {
  className: PropTypes.string,
};

export default HeaderAutosuggest;
