export const getError = (error) => {
  return error?.response?.data || { error: 'Something went wrong' };
};

export const getUnauthorizedError = (errorMsg) => {
  const formattedError = errorMsg.toLowerCase();
  const unauthorizedError = 'user is not authorized';
  const setupTwoFactorError = 'missing two factor setup';
  const twoFactorCodeError = 'missing two factor code';

  return {
    missingActivity: formattedError === unauthorizedError,
    missing2FASetup: formattedError === setupTwoFactorError,
    missing2FACode: formattedError === twoFactorCodeError,
  };
};
