import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reject } from 'lodash';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import ModalAlert from 'common/components/ModalAlert';
import Loader from 'common/components/Loader';
import Select from 'common/components/Select';

import { managementActions } from 'modules/menu/redux/actions';
import { resolveUrl } from 'common/utils';
import { LOADER_COLOR_BLUE } from 'common/components/Loader/consts';
import { menuPaths } from 'modules/menu/consts';
import { displayConfirmDialog } from 'modules/menu/utils';

const PartnersListView = ({
  setMenuPath,
  setCurrentPartner,
  dispatch,
  partnersList,
  currentCompany,
  deletedCompany,
}) => {
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);
  useEffect(() => {
    if (deletedCompany.data === '') {
      handleModalAlertClose();
    }
  }, [deletedCompany.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalAlertOpen = (partner) => {
    setSelectedPartner(partner);
    setIsModalAlertOpen(true);
  };

  const handleModalAlertClose = () => {
    setSelectedPartner(null);
    setIsModalAlertOpen(false);
    dispatch(managementActions.partners.cleanDeleted());
  };

  const handlePartnerClick = (partner) => {
    setCurrentPartner(partner);
    setMenuPath(resolveUrl(menuPaths.PARTNER_MENU, { partnerName: partner.name }));
  };

  const handlePartnerDeleteClick = (partner) => {
    handleModalAlertOpen(partner);
  };

  const handlePartnerCreateClick = () => {
    setMenuPath(menuPaths.PARTNERS_CREATE);
  };

  const handlePartnerDelete = ({ move_to_company }) => {
    const values = {
      move_trackers_to: move_to_company.value,
    };

    displayConfirmDialog().then(() => {
      dispatch(managementActions.partners.delete({ companyId: selectedPartner.id }, values));
    });
  };

  const renderPartnersList = () => {
    return (
      <div className="partners-list">
        {partnersList?.partners?.map((partner) => (
          <div key={partner.id} className="partners-list-item" data-testid="partner-item">
            <span onClick={() => handlePartnerClick(partner)}>{partner.name}</span>
            {partner.id !== currentCompany && (
              <button
                className="pure-button partners-list-item-remove"
                onClick={() => handlePartnerDeleteClick(partner)}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderModalAlert = () => {
    if (selectedPartner && partnersList) {
      const { partners } = partnersList;
      const options = reject(partners, { id: selectedPartner.id }).map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return (
        <ModalAlert headerText="Delete partner" isOpen={isModalAlertOpen} onCloseClick={handleModalAlertClose}>
          <Form onSubmit={handlePartnerDelete} errors={deletedCompany.error} className="delete-partner-form">
            <p className="delete-partner-form-text">
              Choose a partner you will transfer all devices from deleted {selectedPartner.name}.
            </p>
            <Select name="move_to_company" options={options} value={options[0]} required />
            <Button type="submit" inverse={true}>
              Transfer and delete
            </Button>
          </Form>
          <Loader loaded={!deletedCompany.isFetching} color={LOADER_COLOR_BLUE} />
        </ModalAlert>
      );
    }
  };

  return (
    <div className="partners-list-outer">
      <div className="manage-header is-button-on-right">
        <p className="manage-partner-name">Partners</p>
        <Button onClick={handlePartnerCreateClick}>Create partner</Button>
      </div>
      {renderPartnersList()}
      {renderModalAlert()}
    </div>
  );
};

PartnersListView.propTypes = {
  setMenuPath: PropTypes.func,
  setCurrentPartner: PropTypes.func,
  // Redux props
  dispatch: PropTypes.func,
  partnersList: PropTypes.object,
  currentCompany: PropTypes.string,
  deletedCompany: PropTypes.object,
};

const mapStateToProps = ({ account, management }) => {
  const currentUser = account.currentUser.data;

  return {
    partnersList: management.partners.list.data,
    currentCompany: currentUser.company_id,
    deletedCompany: management.partners.deleted,
  };
};

export default connect(mapStateToProps)(PartnersListView);
