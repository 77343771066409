import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const ChangePasswordForm = ({ onSubmit, error, isSubmitting, isPartner }) => {
  return (
    <Form onSubmit={onSubmit} errors={error}>
      <Input type="password" name="password" placeholder="Current password" required />
      <Input type="password" name="new_password" placeholder="New password" required className="mt-15" />
      <Input
        type="password"
        name="new_password_confirm"
        placeholder="Confirm new password"
        validations="equalsField:new_password"
        validationErrors={{ equalsField: 'Passwords must match' }}
        required
        className="mt-15"
      />
      <Button type="submit" disabled={isSubmitting} inverse={isPartner} full className="mt-15">
        Save
      </Button>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isPartner: PropTypes.bool,
};

export default ChangePasswordForm;
