import React from 'react';

type GeneralSettingsControlProps = { label: string; children: React.ReactNode };

export const SettingsControl = ({ label, children }: GeneralSettingsControlProps) => {
  return (
    <div className="settings-item">
      <div className="settings-item-label">{label}</div>
      <div className="settings-item-controls">{children}</div>
    </div>
  );
};
