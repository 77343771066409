import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Accordion from 'common/components/Accordion';
import Arrow from 'common/components/Arrow';
import Button from 'common/components/Button';
import Checkbox from 'common/components/Checkbox';
import Dropdown from 'common/components/Dropdown';
import Form from 'common/components/Form';

import { useFilters, FILTERS } from './useFilters';
import { useSelector } from 'react-redux';
import { TRACKER_MODE_FILTERS } from 'modules/trackers/consts';

const DROPDOWN_ICON = <Arrow direction="down" size={10} />;

const selector = ({
  common: { groups },
  management: {
    firmwares,
    partners,
    partner: {
      customers: {
        list: { isFetching: isFetchingCustomers },
      },
    },
  },
  trackers: {
    search: { customers },
  },
}) => ({
  firmwares:
    firmwares.used.data?.firmwares?.map(({ version, model }) => ({
      name: `${version} (${model})`,
      value: version,
    })) || [],
  companies: partners.list.data?.partners?.map(({ name, id }) => ({ name, value: id })) || [],
  system_states: TRACKER_MODE_FILTERS,
  groups:
    groups.list.data?.groups?.map(({ name, partner_id }) => ({
      name: name,
      value: name,
      partner_id,
    })) || [],
  customers,
  isFetching: firmwares.used.isFetching || partners.list.isFetching || groups.list.isFetching || isFetchingCustomers,
});

const SearchFilters = ({ collapseMode, onFiltersChange, className }) => {
  const { firmwares, companies, system_states, groups, customers, isFetching } = useSelector(selector);
  const filtersClass = cx('search-filters', className, {
    collapse: collapseMode,
  });
  const { filters, handleFilterChange, getOptions, getFilterLabel, getActiveFilterClass, clearFilters } = useFilters(
    { firmwares, companies, system_states, groups, customers },
    isFetching,
    onFiltersChange
  );

  const renderFilters = () => {
    if (collapseMode) {
      return Object.keys(FILTERS).map((filter) => (
        <Accordion
          disabled={FILTERS[filter].disabled?.(filters)}
          title={FILTERS[filter].title?.(filters[filter])}
          key={filter}
          header={getFilterLabel(filter)}
          headerClassName={getActiveFilterClass(filter)}
        >
          <Form onChange={(currentValues, isChanged) => handleFilterChange(currentValues, isChanged, filter)}>
            {getOptions(filter).length ? (
              getOptions(filter).map((option, index) => (
                <Checkbox key={`${filter}-${index}`} name={option.value} label={option.name} title={option.name} />
              ))
            ) : (
              <p>No items</p>
            )}
          </Form>
        </Accordion>
      ));
    }

    return Object.keys(FILTERS).map((filter) => {
      return (
        <Dropdown
          text={getFilterLabel(filter)}
          name={filter}
          onChange={handleFilterChange}
          key={filter}
          values={filters[filter]}
          className={getActiveFilterClass(filter)}
          options={getOptions(filter)}
          disabled={FILTERS[filter].disabled?.(filters)}
          title={FILTERS[filter].title?.(filters)}
          icon={DROPDOWN_ICON}
        />
      );
    });
  };

  return (
    <div className={filtersClass} data-testid="search">
      <div className="search-filters-clear">
        <Button inverse={true} title="Clear all filters" onClick={clearFilters}>
          Clear
        </Button>
      </div>
      {renderFilters()}
    </div>
  );
};

SearchFilters.propTypes = {
  collapseMode: PropTypes.bool,
  onFiltersChange: PropTypes.func,
  className: PropTypes.string,
};

export default SearchFilters;
