import moment from 'moment';

/**
 * Formats UTC datetime.
 * @param {string} datetime - The UTC datetime.
 * @param {string} [error] - The error message.
 * @param {number} [timezone] - The time offset in hours.
 * @returns {string} - The formatted datetime.
 */
export const formatDatetime = (datetime, error, timezone) => {
  if (datetime) {
    const isTimezone = !isNaN(parseFloat(timezone));
    const momentDate = moment.utc(datetime);

    if (momentDate.isValid()) {
      const utcOffset = isTimezone ? timezone : moment().utcOffset() / 60;
      const formattedOffset = ` (UTC ${utcOffset < 0 ? '' : '+'}${utcOffset})`;
      momentDate.add(utcOffset, 'h');
      return `${momentDate.format('YYYY-MM-DD HH:mm')} ${formattedOffset}`;
    } else {
      return error;
    }
  } else {
    return error;
  }
};

/**
 * Computes UTC time from now.
 * @param {number} datetime - The UTC datetime.
 * @returns {string} - The time from now to given datetime.
 */
export const fromNow = (datetime) => {
  if (!datetime) return 'N/A';

  const fromNow = moment.duration(moment.utc().diff(moment.utc(datetime)));
  const years = fromNow.years();
  const months = fromNow.months();

  if (years) return `over ${years} year${years > 1 ? 's' : ''}`;
  else if (months) return `over ${months} month${months > 1 ? 's' : ''}`;

  const days = fromNow.days();
  const hours = fromNow.hours();
  const minutes = fromNow.minutes();
  const seconds = fromNow.seconds();
  const formattedDays = days ? `${days} day${days > 1 ? 's' : ''} ` : '';
  const formattedHours = hours ? `${hours} hr${hours > 1 ? 's' : ''} ` : '';
  const formattedMinutes = minutes ? `${minutes} min${minutes > 1 ? 's' : ''} ` : '';
  const formattedSeconds = !days && !hours && !minutes ? `${seconds} sec${seconds > 1 ? 's' : ''} ` : '';

  return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}`;
};
