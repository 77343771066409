import React from 'react';
import PropTypes from 'prop-types';

import CopyInput from 'common/components/CopyInput';

const APIKeysList = ({ data, handleRemoveAPIKey }) => (
  <div className="api-keys-list-container">
    {data?.api_keys.length ? (
      data.api_keys.map((key) => (
        <div className="api-keys-list-item" key={key.api_key}>
          <span className="api-keys-list-item-label">{key.name}</span>
          <div className="api-keys-list-item-wrapper">
            <CopyInput value={key.api_key} className="api-keys-list-item-input" />
            <button
              onClick={() => handleRemoveAPIKey(key.api_key)}
              data-testid="remove-api-key"
              className="api-keys-list-item-delete pure-button"
            />
          </div>
        </div>
      ))
    ) : (
      <div>There are no keys</div>
    )}
  </div>
);

APIKeysList.propTypes = {
  data: PropTypes.object,
  handleRemoveAPIKey: PropTypes.func,
};

export default APIKeysList;
