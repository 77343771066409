import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { pickBy } from 'lodash';

import { SettingsForm } from 'common/components/SettingsForm';
import { trackersActions } from 'modules/trackers/redux/actions';
import { managementActions } from 'modules/menu/redux/actions';

const selector = ({ management, trackers }) => {
  const {
    settings: { list, saved, savedSensors, savedLocation },
  } = trackers.tracker;
  const partnerSettings = management.partner.settings.list;
  const globalSettings = management.settings.list;
  const isFetching = list.isFetching || partnerSettings.isFetching || globalSettings.isFetching;

  return {
    settings: list.data,
    partnerSettings: partnerSettings.data,
    globalSettings: globalSettings.data,
    selectedTracker: trackers.tracker.selected,
    isFetching,
    saved,
    savedSensors,
    savedLocation,
  };
};

const TrackerSettingsView = ({ match }) => {
  const dispatch = useDispatch();
  const { selectedTracker, settings, partnerSettings, globalSettings, isFetching, saved, savedSensors, savedLocation } =
    useSelector(selector);

  useEffect(() => {
    if (selectedTracker) {
      dispatch(trackersActions.tracker.settings.get(match.params, selectedTracker.company_id, selectedTracker.model));
    }
    return () => {
      dispatch(trackersActions.tracker.settings.cleanGet());
      dispatch(trackersActions.tracker.settings.cleanSaved());
      dispatch(managementActions.partner.settings.cleanGet());
      dispatch(managementActions.settings.cleanGet());
    };
  }, [selectedTracker]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSettingsSubmit = ({ payload, allValues }) => {
    if (payload.sensors_configurations) {
      // Sensors settings
      dispatch(trackersActions.tracker.settings.saveSensors(match.params, payload));
    } else if (payload.hasOwnProperty('lat')) {
      if (payload.lat === null) {
        dispatch(trackersActions.tracker.settings.deleteLocation(match.params, { ...settings, ...payload }));
      } else {
        dispatch(trackersActions.tracker.settings.saveLocation(match.params, payload));
      }
    } else {
      // General settings
      if (Object.values(payload)[0] === null) {
        dispatch(trackersActions.tracker.settings.delete(match.params, allValues, Object.keys(payload)[0]));
      } else {
        dispatch(trackersActions.tracker.settings.save(match.params, payload));
      }
    }
  };

  const onSettingsRestore = (onSuccess) => {
    dispatch(trackersActions.tracker.settings.deleteAll(match.params, onSuccess));
  };

  const trackerSensors = {
    sensors_configurations: globalSettings?.sensors_configurations
      .filter((sensor) => sensor.device_model === selectedTracker?.model)
      .map((sensor) => {
        const partnerSensor = partnerSettings?.sensors_configurations.find((s) => s.sensor_id === sensor.sensor_id);
        return {
          ...sensor,
          min: sensor.min ?? partnerSensor?.min,
          max: sensor.max ?? partnerSensor?.max,
        };
      }),
  };
  const defaultSettings = {
    ...globalSettings,
    ...pickBy(partnerSettings, (s) => s !== null && !Array.isArray(s)),
    ...trackerSensors,
  };

  return (
    <SettingsForm
      settings={settings}
      defaultSettings={defaultSettings}
      onSubmit={onSettingsSubmit}
      onRestore={onSettingsRestore}
      isFetching={isFetching}
      generalSettingStatus={{
        isSubmitting: saved.fetching,
        isSuccess: saved.success,
        error: saved.error,
      }}
      sensorsSettingsStatus={{
        isSubmitting: savedSensors.isFetching,
        error: savedSensors.error,
      }}
      fixedLocationStatus={{
        isSubmitting: savedLocation.isFetching,
        error: savedLocation.error,
      }}
      trackerModel={selectedTracker?.model}
    />
  );
};

TrackerSettingsView.propTypes = {
  match: PropTypes.object,
};

export default TrackerSettingsView;
