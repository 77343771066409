import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const CreateDeviceForm = ({ onSubmit, errors, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="row">
        <div className="gr-grow">
          <Input
            name="tracker_id"
            placeholder="Type tracker id"
            validations={{
              isNumeric: true,
              isLength: 15,
            }}
            validationErrors={{
              isNumeric: 'Tracker id contains only digits',
              isLength: 'Tracker id length is 15',
            }}
            required
          />
        </div>
        <Button type="submit" disabled={isSubmitting}>
          Add device
        </Button>
      </div>
    </Form>
  );
};

CreateDeviceForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default CreateDeviceForm;
