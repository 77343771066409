import queryString from 'query-string';
import { all, call, put, select, take } from 'redux-saga/effects';

import { accountActions } from 'modules/account/redux/actions';
import { apiSaga, createSettingsResetSaga } from 'base/redux/utils';
import { request, getError } from 'common/utils';

import { REQUEST, SUCCESS, FAILURE } from 'base/redux/consts';
import {
  TRACKERS_LIST,
  TRACKER_SUGGESTIONS,
  TRACKER_SUGGESTION,
  TRACKER_DETAILS,
  TRACKER_EXPORT,
  TRACKER_PARTNER,
  TRACKER_GROUPS_UPDATE,
  TRACKER_SETTINGS_GET,
  TRACKER_SETTINGS_SAVE,
  TRACKER_SETTINGS_DELETE,
  TRACKER_SETTINGS_DELETE_ALL,
  TRACKER_SENSORS_SETTINGS_SAVE,
  TRACKERS_MIGRATE,
  TRACKER_FLIGHTS_INFO_GET,
  TRACKER_FLIGHTS_INFO_SET,
  TRACKER_FLIGHTS_INFO_DELETE,
  TRACKER_BLE_DEVICES_GET,
  TRACKER_CHATBOT_CHANNELS_GET,
  TRACKER_CHATBOT_CHANNELS_DELETE,
  FLIGHTS_SEARCH,
  TRACKERS_TABLE_EXPORT,
  SEARCH_PARAMS,
  SEARCH_FILTERS,
  TRACKER_SELECTED,
  TRACKERS_TABLE_GET,
  trackersActions,
  TRACKER_SETTINGS_LOCATION_SAVE,
  TRACKER_SETTINGS_LOCATION_DELETE,
} from './actions';
import { managementActions } from 'modules/menu/redux/actions';
import { initialFilters } from 'modules/trackers/components/SearchFilters/useFilters';

export function* getSearchResultsFlow() {
  while (true) {
    const action = yield take(TRACKERS_LIST + REQUEST);
    try {
      const { data } = yield call(request, action.method, action.endpoint, action.payload);
      const query = queryString.parse(queryString.extract(action.endpoint));

      // Save search results
      yield put({
        type: TRACKERS_LIST + SUCCESS,
        data,
        isPagination: !!query.page,
      });

      // Save query params from search request without page param
      delete query.page;

      // Save selected tracker when the page with specific tracker ID is loaded (single tracker mode)
      const tracker = data.devices && data.devices[0];
      if (query.tracker_id && tracker) {
        yield put(trackersActions.tracker.setSelectedTracker(tracker, { isSingle: true }));
      }
    } catch (error) {
      yield put({
        type: TRACKERS_LIST + FAILURE,
        error: getError(error),
      });
      if (error.response?.status === 401) {
        yield put(accountActions.clearSession());
      }
    }
  }
}

export function* exportTrackerLogFlow() {
  while (true) {
    const action = yield take(TRACKER_EXPORT + REQUEST);
    try {
      const { data } = yield call(request, action.method, action.endpoint, action.payload);
      yield put({
        type: TRACKER_EXPORT + SUCCESS,
        data,
      });
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${action.options.trackerId}.${queryString.parseUrl(action.endpoint).query.format}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      yield put({
        type: TRACKER_EXPORT + FAILURE,
        error: getError(error),
      });
      if (error.response?.status === 401) {
        yield put(accountActions.clearSession());
      }
    }
  }
}

export function* setSearchParams() {
  while (true) {
    const action = yield take(SEARCH_PARAMS);
    const { top_left, precision, tracker_id, bottom_right } = action.params;
    const filters = JSON.parse(window.localStorage.getItem('searchFilters'));
    const payload = tracker_id
      ? { top_left, precision, tracker_id, bottom_right }
      : { ...filters, top_left, precision, tracker_id, bottom_right };
    const getTrackers = trackersActions.search.getTrackers(payload);
    yield put(getTrackers);

    window.localStorage.setItem('searchParams', JSON.stringify(action.params));
  }
}

export function* setSearchFilters() {
  while (true) {
    const action = yield take(SEARCH_FILTERS);
    const [params, customers, partners, filters, isFetchingFilters] = yield select(
      ({
        trackers: {
          search: { params, customers, filters },
        },
        common: { groups },
        management: {
          firmwares,
          partners,
          partners: {
            list: { data },
          },
          partner: {
            customers: {
              list: { isFetching: isFetchingCustomers },
            },
          },
        },
      }) => [
        params,
        customers,
        data?.partners,
        filters,
        isFetchingCustomers || firmwares.used.isFetching || groups.list.isFetching || partners.list.isFetching,
      ]
    );

    const { top_left, precision, tracker_id, bottom_right } = params;
    const searchFilters = { ...action.filters };

    // Pick old filters from storage
    const oldFiltersStr = window.localStorage.getItem('filters');
    const oldFilters = { ...initialFilters, ...JSON.parse(oldFiltersStr) };

    //Fetch companies customers
    const companiesToFetch = action.filters.companies?.filter((companyId) => {
      return oldFilters.companies.indexOf(companyId) === -1;
    });
    if (companiesToFetch?.length) {
      yield all(
        companiesToFetch
          // Filter already fetched companie's customers
          // Create get companie's customers actions
          .map((companyId) => put(managementActions.partner.customers.get({ companyId })))
      );
    }
    if (!isFetchingFilters && partners && customers) {
      //Map companies ids to names
      const companies = filters.companies.map((id) => {
        const p = partners.find((p) => p.id === id);
        if (p) {
          return p.name;
        }
        return '';
      });
      searchFilters.companies = companies;

      //Map customers ids to names
      searchFilters.customers = filters.customers.map((id) => {
        let name;
        Object.keys(customers).forEach((companyId) => {
          const customer = customers[companyId].find((c) => c.id === id);
          if (customer) {
            name = customer.name;
          }
        });
        return name;
      });

      if (!tracker_id) {
        const payload = tracker_id
          ? { top_left, precision, tracker_id, bottom_right }
          : { ...params, ...searchFilters };
        const getTrackers = trackersActions.search.getTrackers(payload);
        yield put(getTrackers);
      }
      window.localStorage.setItem('searchFilters', JSON.stringify(searchFilters));
      window.localStorage.setItem('filters', JSON.stringify(filters));
    }
  }
}

export function* setSelectedTrackerSearchParam() {
  while (true) {
    const action = yield take(TRACKER_SELECTED);
    const params = yield select(
      ({
        trackers: {
          search: { params },
        },
      }) => params
    );
    window.localStorage.setItem('searchParams', JSON.stringify({ ...params, tracker_id: action.tracker?.id }));
  }
}

export function* trackersSaga() {
  yield all([
    getSearchResultsFlow(),
    setSearchParams(),
    setSearchFilters(),
    setSelectedTrackerSearchParam(),
    apiSaga(TRACKER_SUGGESTIONS)(),
    apiSaga(TRACKER_SUGGESTION)(),

    apiSaga(TRACKER_DETAILS)(),
    exportTrackerLogFlow(),
    apiSaga(TRACKER_PARTNER)(),
    apiSaga(TRACKER_GROUPS_UPDATE)(),

    apiSaga(TRACKER_SETTINGS_GET)(),
    apiSaga(TRACKER_SETTINGS_SAVE)(),
    apiSaga(TRACKER_SETTINGS_LOCATION_SAVE)(),
    apiSaga(TRACKER_SETTINGS_LOCATION_DELETE)(),
    apiSaga(TRACKER_SETTINGS_DELETE)(),
    apiSaga(TRACKER_SETTINGS_DELETE_ALL)(),
    createSettingsResetSaga(TRACKER_SETTINGS_LOCATION_SAVE)(),
    createSettingsResetSaga(TRACKER_SETTINGS_LOCATION_DELETE)(),
    createSettingsResetSaga(TRACKER_SETTINGS_SAVE)(),
    createSettingsResetSaga(TRACKER_SETTINGS_DELETE)(),
    apiSaga(TRACKER_SENSORS_SETTINGS_SAVE)(),

    apiSaga(TRACKERS_MIGRATE)(),

    apiSaga(TRACKER_FLIGHTS_INFO_GET)(),
    apiSaga(TRACKER_FLIGHTS_INFO_SET)(),
    apiSaga(TRACKER_FLIGHTS_INFO_DELETE)(),

    apiSaga(TRACKER_BLE_DEVICES_GET)(),

    apiSaga(TRACKER_CHATBOT_CHANNELS_GET)(),
    apiSaga(TRACKER_CHATBOT_CHANNELS_DELETE)(),

    apiSaga(FLIGHTS_SEARCH)(),

    apiSaga(TRACKERS_TABLE_EXPORT)(),
    apiSaga(TRACKERS_TABLE_GET)(),
  ]);
}
