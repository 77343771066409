import React from 'react';
import PropTypes from 'prop-types';

const ChatbotChannels = ({ channels, onChannelDelete }) => {
  const renderChannelsList = () => {
    if (channels && channels.length) {
      return channels.map((channel, index) => (
        <div key={index} className="manage-list-item">
          <span className="list-item-text">{channel.channel}</span>
          <button
            className="pure-button list-item-remove"
            onClick={() => onChannelDelete(channel.user_id, channel.channel)}
          />
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no channels</div>;
    }
  };

  return <div className="manage-list-content">{renderChannelsList()}</div>;
};

ChatbotChannels.propTypes = {
  channels: PropTypes.array,
  onChannelDelete: PropTypes.func,
};

export default ChatbotChannels;
