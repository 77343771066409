import { REQUEST } from 'base/redux/consts';

/**
 * Action creator generates action for API request.
 * @param {string} type - The type of action.
 * @param {string} method - The type of HTTP request method (GET, POST, etc.).
 * @param {string} endpoint - The endpoint URL.
 * @param {Object} [payload] - The payload data sending to API.
 * @param {Object} [options] - Additional things which may extend action.
 * @param {Object} [options.data] - Additional data which may extend action.
 * @param {Object} [options.afterSaga] - Function called after saga (both success and failure).
 * @param {Object} [options.afterSagaSuccess] - Function called after saga success.
 * @param {Object} [options.afterSagaFailure] - Function called after saga failure.
 */
export const apiAction = (type, method, endpoint, payload, options) => {
  return {
    type: type + REQUEST,
    method,
    endpoint,
    payload,
    options,
  };
};
