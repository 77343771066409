import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { reject } from 'lodash';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import ModalAlert from 'common/components/ModalAlert';
import Select from 'common/components/Select';
import Loader from 'common/components/Loader';
import CreateCustomerForm from 'modules/menu/components/CreateCustomerForm';

import { resolveUrl } from 'common/utils';
import { LOADER_COLOR_BLUE } from 'common/components/Loader/consts';
import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';
import { menuPaths } from 'modules/menu/consts';

const selector = ({ management }) => {
  const { list, created, deleted } = management.partner.customers;
  const isFetching = list.isFetching || created.isFetching || deleted.isFetching;
  return {
    isFetching,
    isCreating: created.isFetching,
    customersList: list.data,
    deletedCustomer: deleted,
    errors: {
      created: created.error,
      deleted: deleted.error,
    },
  };
};

const ManageCustomersView = ({ partner, setCurrentCustomer, setMenuPath }) => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, deletedCustomer, customersList, errors } = useSelector(selector);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [newCustomer, setNewCustomer] = useState(null);
  const [isModalAlertOpen, setIsModalAlertOpen] = useState(false);

  useEffect(() => {
    dispatch(managementActions.partner.customers.get({ companyId: partner.id }));
    return () => {
      dispatch(managementActions.partner.customers.cleanCreated());
      dispatch(managementActions.partner.customers.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deletedCustomer.data === '') {
      handleModalAlertClose();
    }
  }, [deletedCustomer.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalAlertOpen = (customer) => {
    setSelectedCustomer(customer);
    setIsModalAlertOpen(true);
  };

  const handleModalAlertClose = () => {
    setSelectedCustomer(null);
    setIsModalAlertOpen(false);
    dispatch(managementActions.partner.customers.cleanDeleted());
  };

  const handleCustomerDeleteClick = (customer) => {
    handleModalAlertOpen(customer);
  };

  const handleCustomerDelete = ({ move_to_customer }) => {
    const values = {
      move_trackers_to: move_to_customer?.value || partner.id,
    };

    displayConfirmDialog().then(() => {
      dispatch(managementActions.partners.delete({ companyId: selectedCustomer.id }, values, partner.id));
    });
  };

  const createCustomer = (values) => {
    dispatch(managementActions.partner.customers.create({ companyId: partner.id }, { ...values }));
  };

  const handleCustomerClick = (customer) => {
    setCurrentCustomer(customer);
    dispatch(managementActions.partner.keys.get({ companyId: customer.id }));
    setMenuPath(
      resolveUrl(menuPaths.PARTNER_CUSTOMERS_KEYS, { partnerName: partner.name, customerName: customer.name })
    );
  };

  const renderCustomersList = (customers) => {
    if (customers && customers.length) {
      return customers.map((customer) => (
        <div key={customer.id} className="manage-list-item">
          <span className="list-item-text pointer" onClick={() => handleCustomerClick(customer)}>
            {customer.name}
          </span>
          <button className="pure-button list-item-remove" onClick={() => handleCustomerDeleteClick(customer)} />
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no customers</div>;
    }
  };

  const renderModalAlert = () => {
    if (selectedCustomer && customersList) {
      const { customers } = customersList;
      const options = reject(customers, { id: selectedCustomer.id }).map((item) => ({
        label: item.name,
        value: item.id,
      }));

      return (
        <ModalAlert headerText="Delete customer" isOpen={isModalAlertOpen} onCloseClick={handleModalAlertClose}>
          <Form onSubmit={handleCustomerDelete} errors={deletedCustomer.error} className="delete-customer-form">
            <p className="delete-customer-form-text">
              Choose a customer you will transfer all devices from deleted {selectedCustomer.name}
            </p>
            <Select
              name="move_to_customer"
              isClearable={true}
              options={options}
              onChange={({ move_to_customer }) => {
                const customer = customers.find((c) => c.id === move_to_customer);
                setNewCustomer(
                  customer
                    ? {
                        label: customer.name,
                        value: customer.id,
                      }
                    : null
                );
              }}
            />
            <p className="delete-customer-form-subtext">
              {!newCustomer && `or all trackers will be transferred to ${partner.name} partner.`}
            </p>
            <Button type="submit" inverse={true}>
              Transfer and delete
            </Button>
          </Form>
          <Loader loaded={!deletedCustomer.isFetching} color={LOADER_COLOR_BLUE} />
        </ModalAlert>
      );
    }
  };

  return (
    <>
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">Customers</p>
      </div>
      <CreateCustomerForm onSubmit={createCustomer} errors={errors.created} isSubmitting={isCreating} />
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderCustomersList(customersList?.customers)}
        {renderModalAlert()}
        <Loader loaded={!isFetching} />
      </div>
    </>
  );
};

ManageCustomersView.propTypes = {
  partner: PropTypes.object,
  setCurrentCustomer: PropTypes.func,
  setMenuPath: PropTypes.func,
};

export default ManageCustomersView;
