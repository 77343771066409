export const menuPaths = {
  HOME: 'home',
  PARTNERS_LIST: 'home/partners',
  PARTNERS_CREATE: 'home/partners/create',
  PARTNER_MENU: 'home/partners/:partnerName',
  PARTNER_CUSTOMERS: 'home/partners/:partnerName/customers',
  PARTNER_CUSTOMERS_KEYS: 'home/partners/:partnerName/customers/:customerName',
  PARTNER_TCC_ADMINS: 'home/partners/:partnerName/TCC admins',
  PARTNER_BLE_FILTERS: 'home/partners/:partnerName/BLE filters',
  PARTNER_BLE_FILTERS_DETAILS: 'home/partners/:partnerName/BLE filters/:bleFilterName',
  PARTNER_USERS: 'home/partners/:partnerName/users',
  PARTNER_SETTINGS: 'home/partners/:partnerName/settings',
  PARTNER_GROUPS: 'home/partners/:partnerName/groups',
  PARTNER_GROUP_DEVICES: 'home/partners/:partnerName/groups/:groupName',
  PARTNER_KEYS: 'home/partners/:partnerName/keys',
  SETTINGS: 'home/settings',
  FIRMWARES: 'home/firmware',
  DEVICES: 'home/devices',
  ACCOUNT: 'home/account',
  ACCOUNT_PASSWORD: 'home/account/password',
  ACCOUNT_EMAIL: 'home/account/email',
  ACCOUNT_2FA: 'home/account/2fa',
};

export const menuRegex = {
  HOME: /^home$/,
  PARTNERS_LIST: /^home\/partners$/,
  PARTNERS_CREATE: /^home\/partners\/create$/,
  PARTNER_MENU: /^home\/partners\/(.+)$/,
  PARTNER_CUSTOMERS: /^home\/partners\/(.+)\/customers$/,
  PARTNER_CUSTOMERS_KEYS: /^home\/partners\/(.+)\/customers\/(.+)$/,
  PARTNER_TCC_ADMINS: /^home\/partners\/(.+)\/TCC admins$/,
  PARTNER_BLE_FILTERS: /^home\/partners\/(.+)\/BLE filters$/,
  PARTNER_BLE_FILTERS_DETAILS: /^home\/partners\/(.+)\/BLE filters\/(.+)$/,
  PARTNER_USERS: /^home\/partners\/(.+)\/users$/,
  PARTNER_SETTINGS: /^home\/partners\/(.+)\/settings$/,
  PARTNER_GROUPS: /^home\/partners\/(.+)\/groups$/,
  PARTNER_GROUP_DEVICES: /^home\/partners\/(.+)\/groups\/(.+)$/,
  PARTNER_KEYS: /^home\/partners\/(.+)\/keys$/,
  SETTINGS: /^home\/settings$/,
  ACCOUNT: /^home\/account$/,
  FIRMWARES: /^home\/firmware$/,
  DEVICES: /^home\/devices$/,
  ACCOUNT_PASSWORD: /^home\/account\/password$/,
  ACCOUNT_EMAIL: /^home\/account\/email$/,
  ACCOUNT_2FA: /^home\/account\/2fa$/,
  ABOUT: /^home\/about$/,
};
