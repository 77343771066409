import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const ForgotPasswordForm = ({ onSubmit, error, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={error}>
      <div className="auth-form-inputs">
        <Input
          type="text"
          name="email"
          placeholder="Email"
          validations="isEmail"
          validationErrors={{
            isEmail: 'This email is not valid',
          }}
          required
        />
      </div>
      <div className="auth-form-control">
        <Button type="submit" disabled={isSubmitting}>
          Send me email
        </Button>
      </div>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default ForgotPasswordForm;
