import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppLayout from 'common/layouts/AppLayout';
import PartnerLayout from 'common/layouts/PartnerLayout';

import Loader from 'common/components/Loader';
import TrackersMainView from 'modules/trackers/views/TrackersMainView';
import APIKeysView from 'modules/partners/views/APIKeysView';

import { LOADER_COLOR_BLUE } from 'common/components/Loader/consts';

const IndexView = ({ currentUser, ...rest }) => {
  return (
    <>
      {currentUser.data ? (
        currentUser.isAdmin ? (
          <AppLayout>
            <TrackersMainView {...rest} />
          </AppLayout>
        ) : (
          <PartnerLayout>
            <APIKeysView {...rest} />
          </PartnerLayout>
        )
      ) : (
        <Loader loaded={!currentUser.isFetching} color={LOADER_COLOR_BLUE} />
      )}
    </>
  );
};

IndexView.propTypes = {
  currentUser: PropTypes.object,
};

const mapStateToProps = ({ account }) => {
  return {
    currentUser: account.currentUser,
  };
};

export default connect(mapStateToProps)(IndexView);
