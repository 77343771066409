import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';

import { menuPaths } from 'modules/menu/consts';
import { resolveUrl } from 'common/utils';
import Loader from 'common/components/Loader';
import Button from 'common/components/Button';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';
import UpdateBLEFilterForm from 'modules/menu/components/UpdateBLEFilterForm';
import { toFormData } from 'common/utils/formData';

const selector = ({ management }) => {
  const { deleted, edit } = management.partner.bleFilters;
  return {
    isFetching: edit.isFetching || deleted.isFetching,
    editedBLEFilter: edit.data,
    errors: {
      deleted: deleted.error,
      edit: edit.error,
    },
    deletedBLEFilter: deleted,
  };
};

const ManageBLEFilterView = ({ partner, currentBleFilter, setMenuPath, setCurrentBleFilter }) => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, errors, editedBLEFilter } = useSelector(selector);

  useEffect(() => {
    return () => {
      setCurrentBleFilter();
      dispatch(managementActions.partner.bleFilters.cleanEdit());
      dispatch(managementActions.partner.bleFilters.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateBLEFilter = (values) => {
    const data = values;
    data.scan_duration += 's';

    if (values.script) {
      dispatch(
        managementActions.partner.bleScripts.edit(
          { companyId: partner.id, bleScriptId: currentBleFilter.script.id },
          { is_active: values.script.is_active }
        )
      );
    } else if (values.new_script) {
      const formData = toFormData({
        filter_id: currentBleFilter.id,
        script: values.new_script,
      });

      dispatch(managementActions.partner.bleScripts.create({ companyId: partner.id }, formData));
    }

    dispatch(
      managementActions.partner.bleFilters.edit(
        { companyId: partner.id, bleFilterId: currentBleFilter.id },
        { ...data }
      )
    );
  };

  const deleteBLEFilter = () => {
    displayConfirmDialog().then(() => {
      dispatch(
        managementActions.partner.bleFilters.delete(
          {
            companyId: partner.id,
            bleFilterId: currentBleFilter.id,
          },
          () => {
            setMenuPath(resolveUrl(menuPaths.PARTNER_BLE_FILTERS, { partnerName: partner.name }));
          }
        )
      );
    });
  };

  const handleUnlinkScript = (scriptId) => {
    displayConfirmDialog().then(() => {
      dispatch(
        managementActions.partner.bleScripts.delete(
          {
            companyId: partner.id,
            bleScriptId: scriptId,
          },
          () => {
            setMenuPath(resolveUrl(menuPaths.PARTNER_BLE_FILTERS, { partnerName: partner.name }));
          }
        )
      );
    });
  };

  return (
    <>
      <div className="manage-header">
        <div className="row gr-grow" style={{ justifyContent: 'space-between' }}>
          <p className="manage-partner-name">{editedBLEFilter?.alias || currentBleFilter?.alias}</p>
          <Button onClick={() => deleteBLEFilter(currentBleFilter)}>Delete</Button>
        </div>
      </div>
      <div className="manage-content-control" style={{ minHeight: 160 }}>
        <Loader loaded={!isFetching} />
        {!isFetching && (
          <UpdateBLEFilterForm
            initialValues={editedBLEFilter ?? currentBleFilter}
            onSubmit={updateBLEFilter}
            errors={errors.created}
            isSubmitting={isCreating}
            onUnlinkScript={handleUnlinkScript}
          />
        )}
        {errors.edit && <p className="vrs-error">{errors.edit.error}</p>}
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
      </div>
    </>
  );
};

ManageBLEFilterView.propTypes = {
  partner: PropTypes.object,
  filter: PropTypes.object,
};

const mapStateToProps = ({ account, management }) => {
  const currentUser = account.currentUser.data;

  return {
    management,
    bleFilters: management.partners.list.data,
    currentCompany: currentUser.company_id,
    deletedCompany: management.partners.deleted,
  };
};

export default connect(mapStateToProps)(ManageBLEFilterView);
