import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';

const ExportLogForm = ({ onExport, error }) => {
  const [period, setPeriod] = useState(1);

  return (
    <div className="export-log-form">
      <p className="mb-15">
        You can export here the RAW communication logs from the tracker. Choose the range of days and download a CSV or
        KML file.
      </p>
      <div className="row">
        <div className="gr-12@xs gr-6@sm log-period">
          <Button onClick={() => setPeriod(1)} active={period === 1}>
            24 hours
          </Button>
        </div>
        <div className="gr-12@xs gr-6@sm log-period">
          <Button onClick={() => setPeriod(7)} active={period === 7}>
            7 days
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="gr-12@xs gr-6@sm log-period">
          <Button onClick={() => setPeriod(14)} active={period === 14}>
            14 days
          </Button>
        </div>
        <div className="gr-12@xs gr-6@sm log-period">
          <Button onClick={() => setPeriod(30)} active={period === 30}>
            30 days
          </Button>
        </div>
      </div>
      <div className="export-log-form-control">
        <Button onClick={() => onExport(period, 'csv')}>Download CSV</Button>
        <Button onClick={() => onExport(period, 'kml')}>Download KML</Button>
      </div>
      <div className="export-log-form-error">{error && <p className="vrs-error">{error}</p>}</div>
    </div>
  );
};

ExportLogForm.propTypes = {
  onExport: PropTypes.func,
  error: PropTypes.string,
};

export default ExportLogForm;
