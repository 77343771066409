import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Pagination from 'common/components/Pagination';
import { formatDatetime } from 'common/utils';

const TrackerBleDevices = ({ bleDevices, getBleDevices }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const limit = bleDevices?.total ? Math.ceil(bleDevices.total / 10) : 0;

  useEffect(() => {
    getBleDevices({ page: currentPage, limit: 10 });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changePage = (page) => {
    getBleDevices({ page: page.selected, limit: 10 });
    setCurrentPage(page.selected);
  };

  const renderItem = (notice, index) => {
    const { mac, filter_alias, data, rssi, created_at } = notice;
    return (
      <div key={index} className="tracker-ble-devices-item">
        <div className="tracker-ble-devices-item-mac-block">
          <p>
            <strong>{mac}</strong>
          </p>
          <p>{formatDatetime(created_at)}</p>
          <p>Filter: {filter_alias}</p>
          <p>Signal strength (RSSI): {rssi}</p>
        </div>
        <div className="tracker-ble-devices-item-details-block">
          {data ? (
            Object.keys(data).map((key) => (
              <p key={key}>
                <span>{`${key.replace('_', ' ')}: `}</span>
                <span>{`${data[key]}`}</span>
              </p>
            ))
          ) : (
            <p>No details data</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="tracker-ble-devices">
      {bleDevices?.notices?.length ? (
        bleDevices?.notices?.map((notice, index) => {
          return renderItem(notice, index);
        })
      ) : (
        <div className="tracker-ble-devices-item">There are no notices</div>
      )}
      <Pagination pageCount={limit} forcePage={currentPage} onPageChange={changePage} />
    </div>
  );
};

TrackerBleDevices.propTypes = {
  bleDevices: PropTypes.object,
  getBleDevices: PropTypes.func,
};

export default TrackerBleDevices;
