import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const ChangeTwoFactorForm = ({ onSubmit, error, isSubmitting, isPartner }) => {
  return (
    <Form onSubmit={onSubmit} errors={error}>
      <Input type="password" name="password" placeholder="Password" required />
      <Button type="submit" disabled={isSubmitting} inverse={isPartner} full className="mt-15">
        Set up 2FA
      </Button>
    </Form>
  );
};

ChangeTwoFactorForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isPartner: PropTypes.bool,
};

export default ChangeTwoFactorForm;
