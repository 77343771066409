import React from 'react';
import PropTypes from 'prop-types';

import TrackerDetailsItem from './TrackerDetailsItem';

import { formatDatetime } from 'common/utils';

const TrackerDetails = ({ details }) => {
  return (
    <>
      <p className="tracker-details-header">General</p>
      <div className="tracker-details mb-15">
        <TrackerDetailsItem name="Friendly name" value={details?.name} />
        <TrackerDetailsItem name="Model" value={details?.model} />
        <TrackerDetailsItem name="Firmware" value={details?.firmware} />
        <TrackerDetailsItem name="Device state" value={details?.state} />
        <TrackerDetailsItem name="Battery" value={`${details?.battery}%`} />
        <TrackerDetailsItem name="Battery state" value={details?.battery_state} />
        <TrackerDetailsItem name="GSM country code" value={details?.mobile_country_code} />
        <TrackerDetailsItem name="GSM network code" value={details?.mobile_network_code} />
        <TrackerDetailsItem name="GSM network operator" value={details?.carrier} />
        <TrackerDetailsItem name="Latitude" value={details?.lat} />
        <TrackerDetailsItem name="Longitude" value={details?.lon} />
        <TrackerDetailsItem name="Accuracy" value={`${details?.accuracy?.toFixed(2)} m`} />
        <TrackerDetailsItem name="Distance to the airport" value={`${details?.airport_distance?.toFixed(2)} m`} />
        <TrackerDetailsItem name="Updated at" value={formatDatetime(details?.updated_at, '---')} />
        <TrackerDetailsItem name="Located at" value={formatDatetime(details?.located_at, '---')} />
        <TrackerDetailsItem name="Created at" value={formatDatetime(details?.created_at, '---')} />
      </div>
      <p className="tracker-details-header">Sensors</p>
      <div className="tracker-details">
        {details?.sensors.map((sensor) => (
          <TrackerDetailsItem key={sensor.id} name={sensor.description} value={`${sensor.value} ${sensor.unit}`} />
        ))}
      </div>
    </>
  );
};

TrackerDetails.propTypes = {
  details: PropTypes.object,
};

export default TrackerDetails;
