import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import ForgotPasswordForm from 'modules/auth/components/ForgotPasswordForm';
import { authActions } from 'modules/auth/redux/actions';

const selector = ({ auth }) => ({
  isFetching: auth.passwordReset.isFetching,
  data: auth.passwordReset.data,
  error: auth.passwordReset.error,
});

const ForgotPasswordView = () => {
  const dispatch = useDispatch();
  const { isFetching, data, error } = useSelector(selector);

  useEffect(() => {
    return () => {
      dispatch(authActions.clearResetPassword());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values) => {
    dispatch(authActions.resetPassword(values));
  };

  return (
    <div className="center-text">
      <div className="auth-form">
        {data === '' ? (
          <>
            <h1>Check your email</h1>
            <p>We sent you email, where we provide link to reset.</p>
          </>
        ) : (
          <>
            <h1>Forgot password?</h1>
            <p>
              {'Just give us your email and we will send you a message' +
                ' where we provide information on how to change password.'}
            </p>
            <ForgotPasswordForm onSubmit={handleSubmit} error={error} isSubmitting={isFetching} />
          </>
        )}
      </div>
      <Loader loaded={!isFetching} />
    </div>
  );
};

export default ForgotPasswordView;
