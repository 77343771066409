import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from 'base/containers/Route';
import IndexView from 'base/containers/IndexView';
import BaseLayout from 'common/layouts/BaseLayout';
import SignInView from 'modules/auth/views/SignInView';
import ActivateAccountView from 'modules/auth/views/ActivateAccountView';
import ConfirmNewEmailView from 'modules/auth/views/ConfirmNewEmailView';
import ForgotPasswordForm from 'modules/auth/views/ForgotPasswordView';
import SetPasswordView from 'modules/auth/views/SetPasswordView';
import VerifyCodeView from 'modules/auth/views/VerifyTwoFactorView';
import SetupTwoFactorView from 'modules/auth/views/SetupTwoFactorView';

import { matchUrl } from 'common/utils';
import { appUrls } from 'urls';

const Routes = () => {
  return (
    <Switch>
      <Route exact={true} path={appUrls.AUTH.SIGN_IN} component={SignInView} layout={BaseLayout} />
      <Route exact={true} path={appUrls.AUTH.ACTIVATE_ACCOUNT} component={ActivateAccountView} layout={BaseLayout} />
      <Route exact={true} path={appUrls.AUTH.RESET_PASSWORD} component={ForgotPasswordForm} layout={BaseLayout} />
      <Route exact={true} path={appUrls.AUTH.SETUP_2FA} component={SetupTwoFactorView} layout={BaseLayout} />
      <Route exact={true} path={appUrls.AUTH.SET_PASSWORD} component={SetPasswordView} layout={BaseLayout} />
      <Route exact={true} path={appUrls.AUTH.VERIFY_CODE} component={VerifyCodeView} layout={BaseLayout} />
      <Route exact={true} path={appUrls.AUTH.CONFIRM_NEW_EMAIL} component={ConfirmNewEmailView} layout={BaseLayout} />
      <Route
        exact={true}
        path={appUrls.AUTH.ACTIVATE_ACCOUNT_API}
        component={({ location }) => (
          <Redirect
            to={{
              pathname: appUrls.AUTH.ACTIVATE_ACCOUNT,
              state: matchUrl(appUrls.AUTH.ACTIVATE_ACCOUNT_API, location.pathname),
            }}
          />
        )}
      />
      <Route
        exact={true}
        path={appUrls.AUTH.SET_PASSWORD_API}
        component={({ location }) => (
          <Redirect
            to={{
              pathname: appUrls.AUTH.SET_PASSWORD,
              state: matchUrl(appUrls.AUTH.SET_PASSWORD_API, location.pathname),
            }}
          />
        )}
      />
      <Route
        exact={true}
        path={appUrls.AUTH.CONFIRM_NEW_EMAIL_API}
        component={({ location }) => (
          <Redirect
            to={{
              pathname: appUrls.AUTH.CONFIRM_NEW_EMAIL,
              state: matchUrl(appUrls.AUTH.CONFIRM_NEW_EMAIL_API, location.pathname),
            }}
          />
        )}
      />
      <Route path={appUrls.TRACKERS.BASE} component={IndexView} authenticationRequired />
    </Switch>
  );
};

export default Routes;
