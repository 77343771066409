import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { divide } from 'lodash';

import Modal from 'common/components/Modal';
import TrackersList from 'modules/trackers/components/TrackersList';

import { trackersActions } from 'modules/trackers/redux/actions';

import { TRACKERS_PAGE_SIZE } from 'modules/trackers/consts';

const selector = ({ trackers }) => ({
  searchParams: trackers.search.params,
  isListModalOpen: trackers.modal.isOpen,
});

const TrackersListView = ({ trackers, isPagination, showTrackerDetails }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { searchParams, isListModalOpen } = useSelector(selector);

  useEffect(() => {
    if (!isPagination) {
      if (modalRef.current) modalRef.current.scrollTop = 0;
      setCurrentPage(0);
    }
  }, [isPagination]);

  const closeModal = () => {
    dispatch(trackersActions.modal.close());
  };

  const changePage = (page) => {
    dispatch(trackersActions.search.getTrackers({ ...searchParams, page: page.selected }));
    if (modalRef.current) modalRef.current.scrollTop = 0;
    setCurrentPage(page.selected);
  };

  const pageCount = trackers ? Math.ceil(divide(trackers.count, TRACKERS_PAGE_SIZE)) : 0;

  return (
    <Modal isOpen={isListModalOpen} headerContent="Trackers list" onClose={closeModal} ref={modalRef}>
      <TrackersList
        trackers={trackers}
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={changePage}
        onItemClick={(tracker) => showTrackerDetails(tracker, { isCenter: true })}
      />
    </Modal>
  );
};

TrackersListView.propTypes = {
  trackers: PropTypes.object,
  isPagination: PropTypes.bool,
  showTrackerDetails: PropTypes.func,
};

export default TrackersListView;
