import React from 'react';
import PropTypes from 'prop-types';

const HighlightedText = ({ text, highlight, omit }) => {
  const startIndex = text.search(highlight);

  if (omit || startIndex === -1) {
    return text;
  }

  const endIndex = startIndex + highlight.length;
  const firstPart = text.slice(0, startIndex);
  const secondPart = text.slice(endIndex);

  return (
    <span>
      {firstPart}
      <span className="highlight">{highlight}</span>
      {secondPart}
    </span>
  );
};

HighlightedText.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.string,
  omit: PropTypes.bool,
};

export default HighlightedText;
