import React from 'react';
import ReactLoader from 'react-loader';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Loader = ({
  overlay = false,
  color = 'white',
  width = 4,
  radius = 12,
  length,
  lines,
  className,
  loaded,
  ...rest
}) => {
  const loaderClassName = cx('vrs-loader', className, {
    'vrs-loader-fixed': overlay,
    'vrs-loader-absolute': !overlay,
  });

  const options = {
    color,
    width,
    radius,
    length,
    lines,
  };

  return (
    <>
      {!loaded && overlay && <div className="vrs-loader-overlay" data-testid="loader-overlay" />}
      <div className={loaderClassName} data-testid="loader-wrapper">
        <ReactLoader options={options} {...rest} loaded={loaded} />
      </div>
    </>
  );
};

Loader.propTypes = {
  overlay: PropTypes.bool,
  loaded: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  radius: PropTypes.number,
  length: PropTypes.number,
  lines: PropTypes.number,
};

export default Loader;
