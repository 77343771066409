import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'common/components/Loader';
import ChangePasswordForm from 'modules/account/components/ChangePasswordForm';

import { accountActions } from 'modules/account/redux/actions';

const selector = ({ account: { changedPassword } }) => ({
  isFetching: changedPassword.isFetching,
  error: changedPassword.error,
  data: changedPassword.data,
});

const ChangePasswordView = ({ isPartner }) => {
  const { isFetching, error, data } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(accountActions.clearChangedPassword());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (values) => {
    dispatch(accountActions.changePassword(values));
  };

  return (
    <div className="change-password">
      <h1 className="change-password-header">Change password</h1>
      {!data ? (
        <ChangePasswordForm
          onSubmit={handleSubmit}
          error={error}
          data={data}
          isSubmitting={isFetching}
          isPartner={isPartner}
        />
      ) : (
        <p className="mt-15">Your password has been changed successfully.</p>
      )}
      <Loader loaded={!isFetching} />
    </div>
  );
};

ChangePasswordView.propTypes = {
  isPartner: PropTypes.bool,
};

export default ChangePasswordView;
