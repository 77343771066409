import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IoPencil, IoSaveOutline } from 'react-icons/io5';

import Form from 'common/components/Form';
import Input from 'common/components/Input';
import Loader from 'common/components/Loader';
import CreateGroupForm from 'modules/menu/components/CreateGroupForm';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';
import { resolveUrl } from 'common/utils';
import { menuPaths } from 'modules/menu/consts';

const selector = ({ management }) => {
  const { list, created, edited, deleted } = management.partner.groups;
  const isFetching = list.isFetching || created.isFetching || edited.isFetching || deleted.isFetching;
  return {
    isFetching,
    isCreating: created.isFetching,
    groupsList: list.data,
    errors: {
      created: created.error,
      edited: edited.error,
      deleted: deleted.error,
    },
  };
};

const ManageGroupsView = ({ partner, setMenuPath, setCurrentGroup }) => {
  const [editedGroup, setEditedGroup] = useState(null);
  const isGroupClicked = useRef(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const { isFetching, isCreating, groupsList, errors } = useSelector(selector);

  useEffect(() => {
    dispatch(managementActions.partner.groups.get({ companyId: partner.id }));
    return () => {
      !isGroupClicked.current && dispatch(managementActions.partner.groups.cleanGet());
      dispatch(managementActions.partner.groups.cleanCreated());
      dispatch(managementActions.partner.groups.cleanDeleted());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createGroup = (group) => {
    dispatch(managementActions.partner.groups.create({ companyId: partner.id }, group));
  };

  const saveEditedGroup = (values) => {
    dispatch(
      managementActions.partner.groups.edit({ companyId: partner.id, groupId: editedGroup.id }, values, () =>
        setEditedGroup(null)
      )
    );
  };

  const deleteGroup = (groupId) => {
    displayConfirmDialog().then(() => {
      dispatch(managementActions.partner.groups.delete({ companyId: partner.id, groupId }));
    });
  };

  const showGroupDevices = (group) => {
    isGroupClicked.current = true;
    setCurrentGroup(group);
    setMenuPath(resolveUrl(menuPaths.PARTNER_GROUP_DEVICES, { partnerName: partner.name, groupName: group.name }));
    dispatch(managementActions.partner.groups.devices.get({ companyId: partner.id, groupId: group.id }));
  };

  const formatDevicesNumber = (number) => (number >= 1000 ? parseInt(number / 1000) + 'k' : number);

  const renderGroups = (groups) => {
    if (groups?.length) {
      return groups.map((group) => (
        <div key={group.id} className="manage-list-item">
          <>
            {group.id === editedGroup?.id ? (
              <>
                <Form onSubmit={saveEditedGroup} errors={errors.edited} ref={formRef}>
                  <Input name="name" value={group.name} placeholder="Type group name" required autofocus />
                </Form>
                <IoSaveOutline
                  size={17}
                  className="inline-icon list-item-edit"
                  onClick={() => formRef.current?.submit()}
                />
              </>
            ) : (
              <>
                <div>
                  <button className="pure-button list-item-text" onClick={() => showGroupDevices(group)}>
                    <span>{group.name}</span>
                  </button>
                  <span>{` (${formatDevicesNumber(group.devices_count)})`}</span>
                </div>
                <IoPencil size={17} className="inline-icon list-item-edit" onClick={() => setEditedGroup(group)} />
              </>
            )}
            <button className="pure-button list-item-remove" onClick={() => deleteGroup(group.id)} />
          </>
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no groups</div>;
    }
  };

  return (
    <div className="manage-groups">
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
        <p className="manage-partner-description">Groups</p>
      </div>
      <CreateGroupForm onSubmit={createGroup} errors={errors.created} isSubmitting={isCreating} />
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderGroups(groupsList?.groups)}
        <Loader loaded={!isFetching} />
      </div>
    </div>
  );
};

ManageGroupsView.propTypes = {
  partner: PropTypes.object,
  setMenuPath: PropTypes.func,
  setCurrentGroup: PropTypes.func,
};

export default ManageGroupsView;
