import { useState, useRef, useEffect } from 'react';
import { get, isEqual, keys, pickBy } from 'lodash';
import { managementActions } from 'modules/menu/redux/actions';
import { useDispatch } from 'react-redux';

export const FILTERS = {
  firmwares: {
    text: 'Firmw.',
    name: 'firmwares',
  },
  companies: {
    text: 'Partner',
    name: 'companies',
  },
  customers: {
    text: 'Customers',
    name: 'customers',
    disabled: (filters) => filters.companies?.length < 1,
    title: (filters) => (filters.companies?.length ? '' : 'Choose partner'),
    options: (filters, filtersOptions) => {
      if (filters.companies?.length && filtersOptions?.customers) {
        return (
          filters.companies
            // Find choosen companies ids options
            .map((companyId) => {
              return filtersOptions.companies?.find((company) => company.value === companyId);
            })
            // Pick choosen companies customers options
            .reduce((options, company) => {
              if (company && filtersOptions.customers[company.value]) {
                options.push(
                  // Use companies options to create customer option label eg: `customer ( partner )`
                  ...filtersOptions.customers[company.value].map((customer) => ({
                    name: `${customer.name} (${company.name})`,
                    value: customer.id,
                  }))
                );
              }
              return options;
            }, [])
        );
      }
      return [];
    },
  },
  groups: {
    text: 'Group',
    name: 'groups',
    disabled: (filters) => !(filters.companies?.length > 0 && filters.customers?.length < 1),
    title: (filters) => (filters.companies?.length ? '' : 'Choose partner'),
    options: (filters, filtersOptions) => {
      if (filters.companies?.length && filtersOptions.groups?.length) {
        return filtersOptions.groups.filter((group) => filters.companies.indexOf(group.partner_id) !== -1);
      }
      return filtersOptions.groups;
    },
  },
  system_states: {
    text: 'Status',
    name: 'system_states',
  },
};

export const initialFilters = {
  firmwares: [],
  companies: [],
  system_states: [],
  groups: [],
  customers: [],
};

const restoreFilters = () => {
  const fromStorage = JSON.parse(window.localStorage.getItem('filters'));
  const { groups, customers, firmwares, companies, system_states } = fromStorage
    ? { ...initialFilters, ...fromStorage }
    : initialFilters;

  window.localStorage.setItem(
    'filters',
    JSON.stringify({
      firmwares,
      companies,
      system_states,
      groups,
      customers,
    })
  );
  return {
    firmwares,
    companies,
    system_states,
    groups,
    customers,
  };
};

export const useFilters = (filtersOptions = initialFilters, isFetching, onChange) => {
  const [filters, setFilters] = useState(restoreFilters());
  const dispatch = useDispatch();
  // Apply custom options
  const getOptions = (filter) => {
    return FILTERS[filter]?.options ? FILTERS[filter].options(filters, filtersOptions) : filtersOptions?.[filter];
  };

  const getFilterLabel = (filter) => `(${filters[filter]?.length || 0}) ${FILTERS[filter].text}`;
  const getActiveFilterClass = (filter) => (filters[filter]?.length ? 'search-filter-active' : '');
  const firstUpdate = useRef(true);

  useEffect(() => {
    filters.companies.forEach((companyId) => dispatch(managementActions.partner.customers.get({ companyId })));
    setTimeout(onChange(filters), [1000]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      !isFetching &&
      firstUpdate.current &&
      !isEqual(JSON.parse(window.localStorage.getItem('filters') || '{}'), filters)
    ) {
      onChange(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = (values, isChanged, filterName) => {
    if (!isChanged) {
      return;
    }
    const newFilters = {
      ...filters,
    };
    if (filterName === FILTERS.firmwares.name) {
      // Use get() function from lodash because formsy-react uses set() and parses firmware versions to nested arrays
      newFilters.firmwares = [];
      filtersOptions.firmwares.forEach((item) => {
        get(values, item.value) && newFilters.firmwares.push(item.value);
      });
    } else if (filterName === FILTERS.companies.name) {
      newFilters.companies = keys(pickBy(values));
      // Filter, from group filter, all groups, from unchecked partner
      if (filters.companies.length > newFilters.companies.length) {
        newFilters.groups = filtersOptions.groups
          .filter((group) => filters.groups.indexOf(group.name) !== -1)
          .filter((group) => newFilters.companies.indexOf(group.partner_id) !== -1)
          .map((group) => group.name);

        // Filter, from customer filter, all customers, from unchecked partner
        newFilters.customers = filters.customers?.filter((customer) =>
          newFilters.companies?.find((companyId) =>
            filtersOptions.customers?.[companyId]?.find((c) => c.id === customer)
          )
        );
      }
    } else if (filterName === FILTERS.customers.name) {
      newFilters.customers = keys(pickBy(values));
      if (filters.customers.length < newFilters.customers.length) {
        newFilters.groups = [];
      }
    } else {
      newFilters[filterName] = keys(pickBy(values));
    }
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };
  return {
    filters,
    getOptions,
    getFilterLabel,
    getActiveFilterClass,
    handleFilterChange,
    clearFilters,
  };
};
