// @ts-nocheck

import React, { ForwardedRef, forwardRef } from 'react';

import { SettingsControl } from './SettingsControl';
import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';
import Loader from 'common/components/Loader';

import { parseSettingsValue } from './utils';
import { SettingsFormProps } from './';
import { SensorsConfigurationSchema } from 'api/models/ConfigurationSchema';
import { ConfigurationInputSchema } from 'api/models/ConfigurationInputSchema';

type SensorsSettingsFormProps = Omit<
  SettingsFormProps,
  'onRestore' | 'onModelChange' | 'generalSettingStatus' | 'isMainSettingsLevel'
>;

export const SensorsSettingsForm = forwardRef(
  (
    { settings, defaultSettings, onSubmit, isFetching, sensorsSettingsStatus, trackerModel }: SensorsSettingsFormProps,
    ref: ForwardedRef<HTMLFormElement>
  ) => {
    const { isSubmitting, error } = sensorsSettingsStatus;
    const sensorsConfig = settings?.sensors_configurations;
    const defaultSensorsConfig = defaultSettings?.sensors_configurations;

    const handleSubmit = (values: Pick<ConfigurationInputSchema, 'sensors_configurations'>) => {
      const payload: SensorsConfigurationSchema[] = [];
      values?.sensors_configurations.forEach(({ min, max, sensor_id }) => {
        const parsedMin = parseSettingsValue(min);
        const parsedMax = parseSettingsValue(max);
        if (min && max) {
          payload.push({ sensor_id, min: parsedMin, max: parsedMax });
        }
      });
      onSubmit({ payload: { sensors_configurations: payload } });
    };

    return (
      <Form onSubmit={handleSubmit} ref={ref}>
        <Loader loaded={!isFetching} />
        {defaultSensorsConfig?.length ? (
          <>
            {defaultSensorsConfig.map(({ sensor_id, description, device_model, min, max, unit }, index) => (
              <SettingsControl
                key={sensor_id}
                label={`${description} ${!trackerModel ? `(${device_model})` : ''} [${unit}]`}
              >
                <div className="settings-item-controls-wrapper">
                  <p>min</p>
                  <Input
                    type="number"
                    name={`sensors_configurations[${index}][min]`}
                    placeholder={defaultSensorsConfig?.[index]?.min}
                    value={sensorsConfig?.[index]?.min}
                    validations={`isLessOrEqualThan:sensors_configurations[${index}][max]`}
                    validationErrors={{ isLessOrEqualThan: 'It should be less than or equal to the max value' }}
                    disabled={isFetching}
                    required={{ isEdited: sensorsConfig?.[index]?.min }}
                  />
                </div>
                <div className="settings-item-controls-wrapper">
                  <p>max</p>
                  <Input
                    type="number"
                    name={`sensors_configurations[${index}][max]`}
                    placeholder={defaultSensorsConfig?.[index]?.max}
                    value={sensorsConfig?.[index]?.max}
                    validations={`isGreaterOrEqualThan:sensors_configurations[${index}][min]`}
                    validationErrors={{ isGreaterOrEqualThan: 'It should be greater than or equal to the min value' }}
                    disabled={isFetching}
                    required={{ isEdited: sensorsConfig?.[index]?.max }}
                  />
                </div>
                <Input
                  type="hidden"
                  name={`sensors_configurations[${index}][sensor_id]`}
                  value={sensor_id}
                  wrapperClassName="settings-hidden-input"
                />
              </SettingsControl>
            ))}
            <div className="settings-save-btn">
              <Button type="submit" disabled={isSubmitting}>
                Save sensor settings
              </Button>
            </div>
          </>
        ) : (
          <p>No sensors for this device model</p>
        )}
        {error && <p className="vrs-error">{`Something went wrong: ${error.error}`}</p>}
      </Form>
    );
  }
);
