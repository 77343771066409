import Axios, { AxiosError, AxiosResponse } from 'axios';

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axios.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => Promise.reject(error.response?.data)
);
