import React from 'react';
import PropTypes from 'prop-types';

import { resolveUrl } from 'common/utils';
import { menuPaths } from 'modules/menu/consts';

const PartnerMenu = ({ partner, setMenuPath }) => {
  return (
    <div className="partner-menu">
      <div className="manage-header">
        <p className="manage-partner-name">{partner.name}</p>
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_CUSTOMERS, { partnerName: partner.name }))}
      >
        Customers
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_TCC_ADMINS, { partnerName: partner.name }))}
      >
        TCC admins
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_USERS, { partnerName: partner.name }))}
      >
        Users
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_BLE_FILTERS, { partnerName: partner.name }))}
      >
        BLE Filters
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_SETTINGS, { partnerName: partner.name }))}
      >
        Settings
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_GROUPS, { partnerName: partner.name }))}
      >
        Groups
      </div>
      <div
        className="partner-menu-item"
        onClick={() => setMenuPath(resolveUrl(menuPaths.PARTNER_KEYS, { partnerName: partner.name }))}
      >
        API keys
      </div>
    </div>
  );
};

PartnerMenu.propTypes = {
  dispatch: PropTypes.func,
  partner: PropTypes.object,
  setMenuPath: PropTypes.func,
};

export default PartnerMenu;
