import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import cx from 'classnames';
import { IoEarth, IoSettingsOutline } from 'react-icons/io5';

import { menuActions } from 'modules/menu/redux/actions';
import { trackersActions } from 'modules/trackers/redux/actions';
import { appUrls } from 'urls';

const selector = ({ menu, trackers }) => ({
  path: menu.path.path,
  isListModalOpen: trackers.modal.isOpen,
});

const Breadcrumb = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { path, isListModalOpen } = useSelector(selector);
  const onPathLinkClick = (name, path, isLastLink) => {
    if (!isLastLink) {
      const index = path.indexOf(`/${name}/`);
      const newPath = path.slice(0, index + name.length + 1);

      dispatch(menuActions.setPath(newPath));
    }
  };

  const onTrackersManagementClick = () => {
    dispatch(push(appUrls.TRACKERS.MANAGEMENT));
    dispatch(trackersActions.modal.close());
  };

  const onTrackersMapClick = () => {
    dispatch(push(appUrls.ROOT));
    dispatch(trackersActions.modal.close());
  };

  const onTrackersListClick = () => {
    isListModalOpen ? dispatch(trackersActions.modal.close()) : dispatch(trackersActions.modal.open());
    dispatch(menuActions.closeMenu());
  };

  const renderLinks = (path) => {
    const linkNames = path.split('/');

    return linkNames.map((name, index) => {
      const isLastLink = index === linkNames.length - 1;

      return (
        <React.Fragment key={index}>
          <span className="breadcrumb-link" onClick={() => onPathLinkClick(name, linkNames.join('/'), isLastLink)}>
            {name.charAt(0).toUpperCase() + name.slice(1)}
          </span>
          {!isLastLink && <span className="breadcrumb-slash">/</span>}
        </React.Fragment>
      );
    });
  };

  const trackersManagementViewClass = cx('breadcrumb-trackers-view-mode', 'pure-button', {
    active: pathname === appUrls.TRACKERS.MANAGEMENT,
  });
  const trackersMapViewClass = cx('breadcrumb-trackers-view-mode', 'pure-button', {
    active: pathname !== appUrls.TRACKERS.MANAGEMENT,
  });
  const trackersListViewClass = cx('pure-button', 'breadcrumb-trackers-list', {
    active: isListModalOpen,
  });

  return (
    <div className="breadcrumb">
      <div className="breadcrumb-path">{renderLinks(path)}</div>
      <div className="breadcrumb-trackers-view">
        <button
          className={trackersManagementViewClass}
          onClick={onTrackersManagementClick}
          data-testid="trackers-management-list"
        >
          <IoSettingsOutline size={24} className="inline-icon" />
          <span>Management</span>
        </button>
        <span className="breadcrumb-separator" />
        <button className={trackersMapViewClass} onClick={onTrackersMapClick} data-testid="trackers-map">
          <IoEarth size={24} className="inline-icon" />
          <span>Map</span>
        </button>
        {pathname !== appUrls.TRACKERS.MANAGEMENT && (
          <button className={trackersListViewClass} onClick={onTrackersListClick} data-testid="trackers-list">
            <span>List</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Breadcrumb;
