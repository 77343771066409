export const SelectStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: '10000',
  }),
  container: (provided) => ({
    ...provided,
    fontWeight: '400',
    display: 'flex',
    flex: '1 1 100%',
    color: '#123363',
    position: 'relative',
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: 'nowrap',
    color: '#123363',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#123363',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '2px',
    borderRadius: '5px',
  }),
  control: (provided) => ({
    ...provided,
    border: '1px solid #a0adc1',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#a0adc1',
    },
    flex: '1 1 100%',
    minHeight: '34px',
  }),
  option: (provided) => ({
    ...provided,
    color: '#123363',
    backgroundColor: provided.backgroundColor === 'transparent' ? provided.backgroundColor : '#3d9cbb33',
    '&:hover': { backgroundColor: '#3d9cbb33' },
    ':active': { backgroundColor: '#3d9cbb33' },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
  }),
};

export const handleKeyDown = (select, options, event) => {
  if (select) {
    const { instancePrefix, focusedOptionRef } = select;
    if (event.keyCode === 40 && focusedOptionRef) {
      const { nextSibling } = focusedOptionRef;
      if (nextSibling?.id?.match(/option/i)) {
        nextSibling.scrollIntoView();
      } else {
        document.getElementById(instancePrefix + '-option-0')?.scrollIntoView();
      }
    } else if (event.keyCode === 38 && focusedOptionRef) {
      const { previousSibling } = focusedOptionRef;
      if (previousSibling?.id?.match(/option/i)) {
        previousSibling.scrollIntoView();
      } else {
        document.getElementById(instancePrefix + '-option-' + (options.length - 1))?.scrollIntoView();
      }
    }
  }
};
