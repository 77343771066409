import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Badge from 'common/components/Badge';
import Loader from 'common/components/Loader';
import CreateFirmwareForm from 'modules/menu/components/CreateFirmwareForm';

import { managementActions } from 'modules/menu/redux/actions';
import { displayConfirmDialog } from 'modules/menu/utils';

const selector = ({ management }) => {
  const { list, created, deleted } = management.firmwares;
  const isFetching = list.isFetching || created.isFetching || deleted.isFetching;
  return {
    isFetching,
    isCreating: created.isFetching,
    firmwaresList: list.data?.firmwares || [],
    errors: {
      created: created.error,
      deleted: deleted.error,
    },
  };
};

const FirmwaresView = () => {
  const dispatch = useDispatch();
  const { isFetching, isCreating, firmwaresList, errors } = useSelector(selector);

  useEffect(() => {
    dispatch(managementActions.firmwares.get());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createFirmware = (values, resetForm) => {
    const formData = new FormData();
    const { file, stable, version } = values;

    formData.append('firmware', file);
    formData.append('version', version.value);
    formData.append('stable', stable ?? false);
    dispatch(managementActions.firmwares.create(formData, resetForm));
  };

  const deleteFirmware = (firmwareId) => {
    displayConfirmDialog().then(() => {
      dispatch(managementActions.firmwares.delete({ firmwareId }));
    });
  };

  const renderFirmwares = (firmwares) => {
    if (firmwaresList.length) {
      return firmwares.map(({ id, version, model, stable }) => (
        <div key={id} className="manage-list-item">
          <div>
            <span className="list-item-text">{version}</span> <span>{`(${model})`}</span>
            <Badge text={stable && 'stable'} status="success" className="ml-15" />
          </div>
          <button className="pure-button list-item-remove" onClick={() => deleteFirmware(id)} />
        </div>
      ));
    } else {
      return <div className="manage-list-item">There are no users</div>;
    }
  };

  return (
    <div className="manage-view account">
      <h2 className="manage-view-header">Firmware</h2>
      <CreateFirmwareForm onSubmit={createFirmware} errors={errors.created} isSubmitting={isCreating} />
      <div className="manage-list-content">
        {errors.deleted && <p className="vrs-error">{errors.deleted.error}</p>}
        {renderFirmwares(firmwaresList)}
        <Loader loaded={!isFetching} />
      </div>
    </div>
  );
};

export default FirmwaresView;
