export const getPageOnCreate = (list, pageSize) => {
  return list && list.total_items % pageSize === 0 ? list.total_pages : list.total_pages - 1;
};

export const getPageOnDelete = (list, name, currentPage) => {
  return currentPage > 0 && list && list[name] && list[name].length === 1 ? currentPage - 1 : currentPage;
};

export const displayConfirmDialog = (label = 'This action is irreversible.') => {
  return new Promise((resolve) => {
    const confirmed = window.confirm(label);
    return confirmed && resolve(true);
  });
};
