import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IoSettingsOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { formatDatetime } from 'common/utils';
import { resolveUrl } from 'common/utils';
import { appUrls } from 'urls';

const TableRow = ({
  id,
  iccid,
  guid,
  serial_number,
  model,
  partner_name,
  produced_by,
  groups,
  produced_at,
  updated_at,
  onEdit,
}) => {
  const groupsText = useMemo(() => {
    return groups?.map((group) => group.name).join(', ');
  }, [groups]);

  return (
    <tr className="trackers-table-row" role="rowgroup">
      <td className="trackers-table-item" title={id}>
        <Link to={resolveUrl(appUrls.TRACKERS.BASE, { trackerId: id })}>{id}</Link>
      </td>
      <td className="trackers-table-item" title={iccid}>
        {iccid}
      </td>
      <td className="trackers-table-item" title={guid}>
        {guid}
      </td>
      <td className="trackers-table-item">{formatDatetime(produced_at)}</td>
      <td className="trackers-table-item">{formatDatetime(updated_at)}</td>
      <td className="trackers-table-item">{serial_number}</td>
      <td className="trackers-table-item">{produced_by}</td>
      <td className="trackers-table-item">{model}</td>
      <td className="trackers-table-item">{partner_name}</td>
      <td className="trackers-table-item" title={groupsText}>
        {groupsText}
      </td>
      <td className="trackers-table-item">
        <IoSettingsOutline size={22} onClick={onEdit} className="inline-icon pointer" title="Edit tracker" />
      </td>
    </tr>
  );
};

TableRow.propTypes = {
  id: PropTypes.number,
  iccid: PropTypes.string,
  guid: PropTypes.string,
  serial_number: PropTypes.string,
  model: PropTypes.string,
  partner: PropTypes.string,
  produced_by: PropTypes.string,
  groups: PropTypes.array,
  produced_at: PropTypes.string,
  updated_at: PropTypes.string,
  onEdit: PropTypes.func,
};

export default TableRow;
