import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';

import Button from 'common/components/Button';
import Loader from 'common/components/Loader';
import { authActions } from 'modules/auth/redux/actions';
import { appUrls } from 'urls';

const selector = ({ auth: { confirmEmailChange } }) => ({
  isFetching: confirmEmailChange.isFetching,
  data: confirmEmailChange.data,
  error: confirmEmailChange.error,
});

const ConfirmNewEmailView = ({ location }) => {
  const { isFetching, data, error } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    location.state && dispatch(authActions.confirmEmailChange({ token: location.state.token }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!location.state) {
    return <Redirect to={appUrls.ROOT} />;
  }

  const renderEmailConfirmation = () => {
    if (data) {
      return (
        <>
          <h1>E-mail changed successfully</h1>
          <p>Now, you can sign in with your new credentials.</p>
          <Button onClick={() => dispatch(push(appUrls.AUTH.SIGN_IN))}>Go to login page</Button>
        </>
      );
    } else if (error) {
      return (
        <>
          <h1>Something went wrong!</h1>
          <p>{error.error}</p>
        </>
      );
    }
  };

  return (
    <div className="center-text">
      <div className="auth-form">
        {renderEmailConfirmation()}
        <Loader loaded={!isFetching} />
      </div>
    </div>
  );
};

ConfirmNewEmailView.propTypes = {
  location: PropTypes.object,
};

export default ConfirmNewEmailView;
