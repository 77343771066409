import google from './google';

/**
 * A customized popup showed on the map.
 * Based on https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 * @param {!Element} content
 * @param {function} [onClose]
 * @constructor
 * @extends {google.maps.OverlayView}
 */
const Popup = function (content, onClose) {
  content.classList.add('popup-content');

  const close = document.createElement('div');
  close.classList.add('popup-close');
  content.appendChild(close);

  const container = document.createElement('div');
  container.classList.add('popup-container');
  container.appendChild(content);

  this.popup = document.createElement('div');
  this.popup.classList.add('popup-wrapper');
  this.popup.appendChild(container);

  this.onClose = onClose;

  // Field to prevent multiple events calling
  this.isInitialized = false;

  // Stop clicks, etc., from popup up to the map
  google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.popup);
};

/*Temporary fix google maps lib*/
if (process.env.NODE_ENV === 'test') {
  Popup.prototype = Object.create({});
} else {
  /** Inheritance OverlayView prototype. */
  Popup.prototype = Object.create(google.maps.OverlayView.prototype);
}

/** Called when the popup is added to the map. */
Popup.prototype.onAdd = function () {
  if (!this.isInitialized) {
    this.isInitialized = true;
    const closeButton = this.popup.querySelector('.popup-close');

    closeButton.addEventListener('click', () => {
      this.setMap(null);
      this.onClose?.();
    });
  }

  this.getPanes().floatPane.appendChild(this.popup);
};

/** Called when the popup is removed from the map. */
Popup.prototype.onRemove = function () {
  if (this.popup.parentElement) {
    this.popup.parentElement.removeChild(this.popup);
  }
};

/** Called when the popup needs to draw itself. */
Popup.prototype.draw = function () {
  const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
  // Hide the popup when it is far out of view.
  const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

  if (display === 'block') {
    this.popup.style.left = divPosition.x + 'px';
    this.popup.style.top = divPosition.y + 'px';
  }

  if (this.popup.style.display !== display) {
    this.popup.style.display = display;
  }
};

/** Closes the popup. */
Popup.prototype.close = function () {
  const closeButton = this.popup.querySelector('.popup-close');
  closeButton?.click();
};

/** Sets up the position. */
Popup.prototype.setPosition = function (position) {
  const currentPosition = this.position;
  this.position = position;
  currentPosition && this.draw();
};

export default Popup;
