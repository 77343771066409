import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

let portalRoot = document.getElementById('portal');

/* Create a portal div if it isn't in the body yet (useful in tests) */
if (!portalRoot) {
  portalRoot = document.createElement('div');
  portalRoot.setAttribute('id', 'portal');
  document.body.appendChild(portalRoot);
}

const Portal = ({ children }) => {
  const portalRef = useRef(null);

  useEffect(() => {
    portalRoot.appendChild(getPortal());

    return () => {
      portalRoot.removeChild(getPortal());
    };
  }, []);

  const getPortal = () => {
    if (!portalRef.current) {
      portalRef.current = document.createElement('div');
    }
    return portalRef.current;
  };

  return ReactDOM.createPortal(children, getPortal());
};

Portal.propTypes = {
  children: PropTypes.node,
};

export default Portal;
