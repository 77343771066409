import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const CreateTCCAdminForm = ({ onSubmit, errors, isSubmitting }) => {
  return (
    <Form onSubmit={onSubmit} errors={errors}>
      <div className="manage-content-control">
        <div className="row">
          <div className="gr-grow">
            <Input
              type="text"
              name="email"
              placeholder="Type email address"
              validations="isEmail"
              validationErrors={{
                isEmail: 'This email is not valid',
              }}
              required
            />
          </div>
          <Button type="submit" disabled={isSubmitting}>
            Create TCC admin
          </Button>
        </div>
      </div>
    </Form>
  );
};

CreateTCCAdminForm.propTypes = {
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default CreateTCCAdminForm;
