import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const TackersListPopup = forwardRef(({ trackers, onTrackerClick }, ref) => {
  return (
    <div className="trackers-list-popup-wrapper">
      <div className="trackers-list-popup-container" ref={ref}>
        <div className="trackers-list-popup-main">
          <div className="trackers-list-popup-header">Devices list</div>
          <div className="trackers-list-popup-content">
            {trackers.map((trackerId, index) => (
              <div key={index} className="tracker-list-popup-item" onClick={() => onTrackerClick(trackerId)}>
                {trackerId}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

TackersListPopup.propTypes = {
  trackers: PropTypes.array,
  onTrackerClick: PropTypes.func,
};

export default TackersListPopup;
