import React from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import cx from 'classnames';

const Upload = ({ onChange, className, name, value, setValue, errorMessage, showRequired, isPristine }) => {
  const handleChange = (event) => {
    const file = event.target.files[0];
    onChange && onChange(file);
    setValue(file);
  };

  const error = errorMessage || (!isPristine && showRequired && 'This field is required');
  const containerClassName = cx('vrs-upload-container', className);

  return (
    <div className={containerClassName}>
      <div className="vrs-upload-box">
        <input type="file" name={name} onChange={handleChange} />
        <label>{value?.name ?? 'Choose file...'}</label>
      </div>
      {error && <p className="vrs-error">{error}</p>}
    </div>
  );
};

Upload.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  // Formsy props
  name: PropTypes.string,
  value: PropTypes.object,
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool,
  isPristine: PropTypes.bool,
};

export default withFormsy(Upload);
