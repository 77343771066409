import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import RadioGroup from 'common/components/RadioGroup';

import { getDeparturesRadios, getArrivalsRadios, prepareFlights, findArrivalFlights } from './utlis';

const FlightChooseForm = ({ onFlightSet, searchedFlights, errorSet, isSetting }) => {
  const [flights, setFlights] = useState([]);
  const [departureStd, setDepartureStd] = useState(null);
  const formRef = useRef(null);

  useEffect(() => {
    setFlights(prepareFlights(searchedFlights));
  }, [searchedFlights]);

  const handleDepartureChange = (value) => {
    setDepartureStd(value);
    formRef.current.reset({ departureStd: value });
  };

  const handleSubmit = (values) => {
    onFlightSet({
      flight_number: flights[0].iata,
      std: values.departureStd,
      dest_airport_code: values.destCode.match(/(\w+)_/)[1],
    });
  };

  const departuresRadios = getDeparturesRadios(flights);
  const arrivalsRadios = departureStd ? getArrivalsRadios(findArrivalFlights(flights, departureStd)) : [];

  return (
    <div className="tracker-flight-info-view-section">
      <h3 className="tracker-flight-info-view-subtitle">Choose flight</h3>
      <Form onSubmit={handleSubmit} ref={formRef} className="tracker-flight-info-view-section-content">
        <p className="flight-choose-form-step-title">Departure time</p>
        <RadioGroup name="departureStd" items={departuresRadios} onChange={handleDepartureChange} required />
        {arrivalsRadios.length > 0 && (
          <>
            <p className="flight-choose-form-step-title">Arrival time</p>
            <RadioGroup name="destCode" items={arrivalsRadios} required />
          </>
        )}
        <div className="flight-search-form-wrapper">
          {errorSet && <p className="vrs-error flight-choose-form-error">{errorSet.error}</p>}
          <Button type="submit" disabled={isSetting} className="tracker-flight-info-view-section-button">
            Set
          </Button>
        </div>
      </Form>
    </div>
  );
};

FlightChooseForm.propTypes = {
  onFlightSet: PropTypes.func,
  searchedFlights: PropTypes.array,
  isSetting: PropTypes.bool,
  errorSet: PropTypes.object,
};

export default FlightChooseForm;
