import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withFormsy } from 'formsy-react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { handleKeyDown, SelectStyles } from './utils';

export const MultiSelect = ({
  options,
  placeholder,
  onChange,
  disabled,
  name,
  value,
  setValue,
  errorMessage,
  showRequired,
  isPristine,
  ...rest
}) => {
  const handleChange = (value) => {
    onChange && onChange(value);
    setValue(value);
  };

  const error = errorMessage || (!isPristine && showRequired && 'This field is required');

  // We need to handle arrow keys scrolling
  const selectRef = useRef(null);
  const listRef = useRef(null);

  return (
    <>
      <Select
        styles={SelectStyles}
        name={name}
        options={options}
        components={{
          MenuList: (innerProps) => {
            return (
              <PerfectScrollbar className="select-menu-list" ref={listRef}>
                {innerProps.children}
              </PerfectScrollbar>
            );
          },
        }}
        isMulti
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        ref={selectRef}
        onChange={handleChange}
        onKeyDown={(event) => selectRef.current && handleKeyDown(selectRef.current.select, options, event)}
        {...rest}
      />
      {error && <p className="vrs-error">{error}</p>}
    </>
  );
};

Select.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  // Formsy props
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  setValue: PropTypes.func,
  errorMessage: PropTypes.string,
  showRequired: PropTypes.bool,
  isPristine: PropTypes.bool,
};

export default withFormsy(MultiSelect);
