import React from 'react';
import PropTypes from 'prop-types';

import Button from 'common/components/Button';
import Form from 'common/components/Form';
import Input from 'common/components/Input';

const ChangeEmailForm = ({ onSubmit, error, isSubmitting, isPartner }) => {
  return (
    <Form onSubmit={onSubmit} errors={error}>
      <Input
        type="text"
        name="new_email"
        placeholder="New email"
        validations="isEmail"
        validationErrors={{
          isEmail: 'This email is not valid',
        }}
        required
      />
      <Input type="password" name="password" placeholder="Password" required className="mt-15" />
      <Button type="submit" disabled={isSubmitting} inverse={isPartner} full className="mt-15">
        Save
      </Button>
    </Form>
  );
};

ChangeEmailForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.object,
  isSubmitting: PropTypes.bool,
  isPartner: PropTypes.bool,
};

export default ChangeEmailForm;
