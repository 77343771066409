import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalAlert from 'common/components/ModalAlert';
import Loader from 'common/components/Loader';
import APIKeysList from 'modules/partners/components/APIKeysList';
import CreatedAPIKeys from 'modules/partners/components/CreatedAPIKeys';
import CreateKeysForm from 'modules/menu/components/CreateKeysForm';

import { displayConfirmDialog } from 'modules/menu/utils';
import { partnersActions } from 'modules/partners/redux/actions';

const selector = ({ partners: { apiKeys }, account }) => ({
  companyId: account.currentUser.data?.partner_id,
  keysList: {
    data: apiKeys.list.data,
    isFetching: apiKeys.list.isFetching,
  },
  createdKey: {
    data: apiKeys.created.data,
    isFetching: apiKeys.created.isFetching,
    error: apiKeys.created.error,
  },
  deletedKey: {
    isFetching: apiKeys.deleted.isFetching,
  },
});

const APIKeysView = () => {
  const [isAPIModalOpen, setIsAPIModalOpen] = useState(false);
  const { companyId, keysList, createdKey, deletedKey } = useSelector(selector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
      dispatch(partnersActions.apiKeys.getList(companyId));
    }
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    createdKey.data && setIsAPIModalOpen(true);
  }, [createdKey.data]);

  const onAPIModalClose = () => {
    dispatch(partnersActions.apiKeys.cleanCreated());
    setIsAPIModalOpen(false);
  };

  const onKeyCreate = (name) => {
    dispatch(partnersActions.apiKeys.create(companyId, name));
  };

  const onKeyRemove = (key) => {
    displayConfirmDialog().then(() => {
      dispatch(partnersActions.apiKeys.delete(companyId, key));
    });
  };

  return (
    <div className="api-keys-view-container">
      <div className="api-keys-view-box">
        <h1 className="api-keys-view-header">API Keys</h1>
        <CreateKeysForm onSubmit={onKeyCreate} errors={createdKey.error} isSubmitting={createdKey.isFetching} />
        <APIKeysList data={keysList.data} handleRemoveAPIKey={onKeyRemove} />
        <Loader loaded={!(keysList.isFetching || deletedKey.isFetching)} />
        {createdKey.error ? null : (
          <ModalAlert isOpen={isAPIModalOpen} onCloseClick={onAPIModalClose}>
            <CreatedAPIKeys />
          </ModalAlert>
        )}
      </div>
    </div>
  );
};

export default APIKeysView;
